import { PlayCircleIcon } from "@heroicons/react/20/solid"
import { useMemo } from "react"
import { Card } from "~/ui-rtk/components/ui/common"
import logoUrl from "~/ui-rtk-public/assets/img/logo.svg?url"

export type TLearningZoneCard = {
  title: string
  url: string
  contentType: "video" | "article"
  type: string
  id: string
  img?: string
}

type TLearningZoneSectionCardProps = {
  card: TLearningZoneCard
}

export default function LearningZoneSectionCard({
  card,
}: TLearningZoneSectionCardProps) {
  const openArticle = () => {
    window.postMessage({
      target: "FeaturebaseWidget",
      data: {
        action: "changePage",
        payload: "MainView", // MainView, RoadmapView, CreatePost, PostsView, ChangelogView
        openWidget: true,
        articleId: card.id,
      },
    })
  }

  const style = useMemo(
    () => ({
      backgroundImage: `url(${card.img ?? logoUrl})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: card.img ? "cover" : "50%",
      backgroundPosition: "center center",
    }),
    [card],
  )

  return (
    <div className="w-75">
      <Card
        rounded
        style={style}
        onMouseUp={openArticle}
        className="bg-basic-blue/20 border border-basic-blue w-75 h-70 flex items-center justify-center"
      >
        {card.contentType === "video" && (
          <PlayCircleIcon className="w-15 h-15 opacity-75" />
        )}
      </Card>
      <div className="mt-3">{card.title}</div>
    </div>
  )
}
