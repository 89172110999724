import { useState } from "react"
import * as yup from "yup"
import { useCompanyGoalControllerCreateCompanyGoalMutation } from "~/ui-rtk/api/companyGoalApi"

import useMetricOptions from "~/ui-rtk/hooks/metric-options"

export type TGoalForm = {
  title: string
  description?: string
  notes?: string
  metric: string
  goalGrowthType: string
  goalGrowthValue: number
  targetPeriod: Date
  compareTo: string
}

export const GOAL_GROWTH_TYPE = {
  PERCENTAGE: "PERCENTAGE",
  ABSOLUTE: "ABSOLUTE",
}

export const COMPARE_TO = {
  YOY: "YOY",
  LY: "LY",
  YTD: "YTD",
}

export const validationSchema = yup.object().shape({
  title: yup.string().required("Please fill in a title"),
  description: yup.string().optional(),
  notes: yup.string().optional(),
  metric: yup.string().required("Please select a metric"),
  goalGrowthType: yup.string().required("Please select a Goal Growth type"),
  goalGrowthValue: yup
    .number()
    .moreThan(0, "Growth Target should be more than 0")
    .required("Please fill in Goal Growth value"),
  targetPeriod: yup.date().required("Please select target period"),
  compareTo: yup.string().required("Please fill keywords"),
})

const goalGrowthOptions = [
  {
    label: "% Growth Goal",
    value: GOAL_GROWTH_TYPE.PERCENTAGE,
  },
  {
    label: "Absolte Value Growth Goal",
    value: GOAL_GROWTH_TYPE.ABSOLUTE,
  },
]

const compareToOptions = [
  {
    label: "Last Year",
    value: COMPARE_TO.LY,
  },
  {
    label: "Year over Year",
    value: COMPARE_TO.YOY,
  },
  {
    label: "Year to Date",
    value: COMPARE_TO.YTD,
  },
]

export default function useConnect() {
  const { isLoading, metricsOptions } = useMetricOptions()
  const [createCompanyGoal] =
    useCompanyGoalControllerCreateCompanyGoalMutation()

  const [isSaving, setIsSaving] = useState(false)

  const initialValues = {
    title: "",
    description: undefined,
    notes: undefined,
    metric: metricsOptions[0]?.value ?? "",
    goalGrowthType: GOAL_GROWTH_TYPE.PERCENTAGE,
    goalGrowthValue: 0,
    targetPeriod: new Date(),
    compareTo: COMPARE_TO.LY,
  } as TGoalForm

  const handleCreate = async (values: TGoalForm) => {
    setIsSaving(true)
    await createCompanyGoal({
      body: values,
    }).unwrap()
    setIsSaving(false)
  }

  return {
    isLoading,
    isSaving,
    goalGrowthOptions,
    compareToOptions,
    metricsOptions,
    validationSchema,
    initialValues,
    handleCreate,
  }
}
