import { useMemo, useState } from "react"
import useCurrentCompanyMetadata from "./current-company-metadata"

export const DEFAULT_DEP_VAR_METRIC = "ga_revenue_from_organic_sessions"

let amazonEnabled = false

export default function useDepVar() {
  const currentCompanyMetadata = useCurrentCompanyMetadata()

  const [, render] = useState(0)

  const toggleAmazon = () => {
    amazonEnabled = !amazonEnabled
    render(x => x + 1)
  }

  const depVarScaler = useMemo(() => {
    const scaler = []
    if (currentCompanyMetadata?.model_props?.ecom?.dep_var_scaler) {
      scaler.push(currentCompanyMetadata.model_props.ecom.dep_var_scaler)
    }
    if (amazonEnabled) {
      scaler.push("amazon_owned_revenue")
    }
    return scaler
  }, [currentCompanyMetadata, amazonEnabled])

  const depVar =
    currentCompanyMetadata?.model_props?.ecom?.dep_var ?? DEFAULT_DEP_VAR_METRIC

  return {
    toggleAmazon,
    amazonEnabled,
    depVar,
    depVarScaler,
  }
}
