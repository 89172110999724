import useTop10HeroAds from "../../hooks/top-10-hero-ads"
import RecommendedActionsSectionCard from "./RecommendedActionsSectionCard"
import { H3, H5 } from "~/ui-rtk/components/ui/typography"
import useDrawer from "~/ui-rtk/hooks/drawer"
import { DRAWER_TYPE } from "~/ui-rtk/constants/drawer"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import useAdImage from "~/ui-rtk/hooks/ad-image"
import useDemoMode from "~/ui-rtk/hooks/demo-mode"
import { Image } from "~/ui-rtk/components/ui/common/Image"
import { safeDivide, toCoef, toCurrency } from "~/ui-rtk/utils/number-utils"
import useCurrentCompany from "~/ui-rtk/hooks/current-company"
import TabsGroup from "~/ui-rtk/components/ui/controls/TabsGroup/TabsGroup"
import { TABS_OPTIONS } from "../../constants/ads"
import { useEffect, useMemo, useState } from "react"
import { CaptionPreview, Loader } from "~/ui-rtk/components/ui/common"
import { useURLSearchParams } from "~/ui-rtk/hooks/url"

type TTop10HeroAdsSectionProps = {
  className?: string
}

const urlKey = "top-10-hero-ads"

export default function Top10HeroAdsSection({
  className,
}: TTop10HeroAdsSectionProps) {
  const { openDrawer } = useDrawer()
  const { isEnabled: isDemoEnabled } = useDemoMode()
  const [initialSearchEnded, setInitialSearchEnded] = useState(false)

  const { params, setParams } = useURLSearchParams()

  const tabIndex = useMemo(() => {
    if (params[urlKey]) {
      return +params[urlKey]
    }
    return 0
  }, [params])

  const handleSetTabIndex = (idx: number) => {
    setParams({
      [urlKey]: `${idx}`,
    })
  }

  const currentCompany = useCurrentCompany()

  const dateStart = useMemo(
    () => TABS_OPTIONS[tabIndex].value,
    [tabIndex, TABS_OPTIONS],
  )

  const { data, isLoading, isStarted, hide } = useTop10HeroAds(dateStart)

  useEffect(() => {
    const maxIndex = TABS_OPTIONS.length - 1
    if (
      !initialSearchEnded &&
      !isLoading &&
      isStarted &&
      (data ?? []).length < 1 &&
      tabIndex < maxIndex
    ) {
      handleSetTabIndex(tabIndex + 1)
    }
    if (tabIndex === maxIndex || (data ?? []).length > 0) {
      setInitialSearchEnded(true)
    }
  }, [
    initialSearchEnded,
    setInitialSearchEnded,
    data,
    isLoading,
    isStarted,
    tabIndex,
    handleSetTabIndex,
  ])

  const handleClick = (metric: Record<string, string>) => {
    openDrawer({
      clickSourceId: ".recommended-actions-section-card",
      props: {
        metric,
        component: "AdDetails",
      },
      type: DRAWER_TYPE.CampaingAdSetAdDetails,
    })
  }

  const renderButtons = (metric: Record<string, string>) => (
    <div className="w-full flex justify-between items-center">
      <button className="btn btn-block" onClick={() => handleClick(metric)}>
        Open
      </button>
    </div>
  )

  const renderCardContent = (metric: Record<string, string>, idx: number) => {
    const adImage = useAdImage(
      currentCompany?.id,
      metric.media_channel,
      metric.ad_id,
    )
    const adName = metric.ad_name

    const spend = +metric.spend
    const brandValue = +(metric.brand_value ?? metric.brand_value_agg)
    const brandValueOverSpend = +metric.brand_value_over_spend
    const totalValue = +metric.long_term_revenue

    const stats: Record<string, string> = {
      "Brand Value": toCurrency(brandValue),
      "Brand ROAS": toCoef(brandValueOverSpend),
      "Total Value": toCurrency(totalValue),
      "Total ROAS": toCoef(safeDivide(totalValue, spend)),
      Spend: toCurrency(spend),
    }

    return (
      <div className="w-full">
        <div
          className={`grid grid-cols-1 items-start justify-start ${isDemoEnabled ? "blur-md" : ""}`}
        >
          <div>
            <CaptionPreview
              text={adName}
              maxLength={30}
              align={idx ? "right" : "left"}
            />
          </div>
          <Image
            classes={{
              image: `rounded-xl`,
              wrapper: `justify-start mt-2`,
            }}
            width={80}
            src={adImage}
            alt={adName}
            onClick={() => handleClick(metric)}
          />
        </div>
        <div className="grid grid-cols-2 mt-4 text-white/50 text-sm">
          {Object.keys(stats).map(statKey => (
            <div key={statKey} className="grid grid-cols-2 items-center">
              <div className="text-xs">{statKey}:</div>
              <div className="text-white">{stats[statKey]}</div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className={cn("top-10-hero-ads-actions-section h-full", className)}>
      <div>
        <H3>Top 10 Hero Ads (Drive both Brand & Direct-Response)</H3>
        <H5 className="text-white/50">
          Look to duplicate into other campaigns - great candidates to get into
          both Brand and DR campaigns to boost social validation on post
        </H5>
      </div>
      <div
        className={cn(
          className,
          "join max-h-14 border-b border-b-basic-blue w-full",
        )}
      >
        <TabsGroup
          options={TABS_OPTIONS}
          onChange={handleSetTabIndex}
          index={tabIndex}
        />
      </div>
      {isLoading ? (
        <div className="p-5 flex justify-center items-center">
          <Loader size={150} />
        </div>
      ) : (
        <div className="mt-4 p-0 flex gap-3 overflow-x-auto">
          {(data ?? []).length > 0 ? (
            data?.map((metric: Record<string, string>, idx: number) => (
              <RecommendedActionsSectionCard
                key={idx}
                card={{
                  categoryGroup: "BRAND MEDIA",
                  categoryName: "ADS",
                }}
                handleCancel={() => hide(metric)}
                buttons={renderButtons(metric)}
              >
                {renderCardContent(metric, idx)}
              </RecommendedActionsSectionCard>
            ))
          ) : (
            <div className="p-5">No Ads found</div>
          )}
        </div>
      )}
    </div>
  )
}
