import Dashboard from "~/ui-rtk/components/features/dashboards/Dashboard"
import useConnect from "./connect"
import { TDashboardItemsSet } from "~/ui-rtk/constants/dashboard"
import WithLoader from "~/ui-rtk/components/layout/WithLoader/WithLoader"
import useReconnectSourcesBanner from "~/ui-rtk/hooks/reconnect-sources-banner"
import { useEffect } from "react"
import ReconnectBanner from "~/ui-rtk/components/layout/ReconnectBanner/ReconnectBanner"

export default function BrandsValueOverview() {
  const {
    content,
    toggleAmazon,
    amazonEnabled,
    visualizations,
    isLoading,
    requiredSources,
    cubeQueryParams,
  } = useConnect()

  const { connectorsToReconnect, setRequiredSources } =
    useReconnectSourcesBanner()

  useEffect(() => {
    setRequiredSources(requiredSources)
  }, [requiredSources])

  return (
    <WithLoader isLoading={isLoading}>
      <ReconnectBanner connectors={connectorsToReconnect} />
      <Dashboard
        slug={content.slug}
        visualizations={visualizations}
        items={content.content.items as TDashboardItemsSet}
        title={content.content.title}
        toggleAmazon={toggleAmazon}
        amazonEnabled={amazonEnabled}
        props={{
          commonGranularityPicker: true,
        }}
        parent={content.parent}
        lastDateChipProps={content.content.lastDateChipProps}
        periodPicker={content.content.periodPicker}
        cubeQueryParams={cubeQueryParams}
      />
    </WithLoader>
  )
}
