import { CONNECTORS_IDS } from "./sources"

export const SHOPIFY_REVENUE_TYPE = {
  BASELINE: "baseline",
  TRIGGERED: "triggered",
  PAID: "paid",
}

export const SHOPIFY_REVENUE_TYPE_TO_TITLE_MAP = {
  [SHOPIFY_REVENUE_TYPE.BASELINE]: "Baseline",
  [SHOPIFY_REVENUE_TYPE.TRIGGERED]: "Triggered",
  [SHOPIFY_REVENUE_TYPE.PAID]: "Paid",
}

export const SHOPIFY_REVENUE_TYPE_TO_COLOR_MAP = {
  [SHOPIFY_REVENUE_TYPE.BASELINE]: "#318936",
  [SHOPIFY_REVENUE_TYPE.TRIGGERED]: "#EE8434",
  [SHOPIFY_REVENUE_TYPE.PAID]: "#E2323E",
}

export const REVENUE_SOURCES_KEYS = {
  [CONNECTORS_IDS.SHOPIFY]: CONNECTORS_IDS.SHOPIFY,
  [CONNECTORS_IDS.GOOGLE_ANALYTICS_4]: CONNECTORS_IDS.GOOGLE_ANALYTICS_4,
  [CONNECTORS_IDS.AMAZON_SELLING_PARTNER]:
    CONNECTORS_IDS.AMAZON_SELLING_PARTNER,
}

export type TRevenueSourceKeysKey = keyof typeof REVENUE_SOURCES_KEYS
export type TRevenueSourceKeysValue =
  (typeof REVENUE_SOURCES_KEYS)[TRevenueSourceKeysKey]

export type RevenueSourceValue = {
  id: TRevenueSourceKeysValue
  name: string
  icon: {
    src: string
    alt: string
  }
}

export const BASELINE_REVENUE_SHOPIFY = "shopify_baseline_revenue"
export const BASELINE_REVENUE_SHOPIFY_ALT =
  "shopify_baseline_plus_subscription_revenue"
export const BASELINE_REVENUE_GA = "ga_baseline_revenue"
export const BASELINE_REVENUE_GA_ALT = "ga_multi_property_baseline_revenue"

export const DEP_VAR_SOURCES: Record<
  TRevenueSourceKeysValue,
  Record<"MAIN" | "ALT", string>
> = {
  [CONNECTORS_IDS.SHOPIFY]: {
    MAIN: "shopify_baseline_revenue",
    ALT: "shopify_baseline_plus_subscription_revenue",
  },
  [CONNECTORS_IDS.GOOGLE_ANALYTICS_4]: {
    MAIN: "ga_revenue_from_organic_sessions",
    ALT: "ga_multi_property_baseline_revenue",
  },
}

export const DEP_VAR_TO_BASELINE_REVENUE_MAP = {
  [DEP_VAR_SOURCES[CONNECTORS_IDS.SHOPIFY].MAIN]: BASELINE_REVENUE_SHOPIFY,
  [DEP_VAR_SOURCES[CONNECTORS_IDS.SHOPIFY].ALT]: BASELINE_REVENUE_SHOPIFY_ALT,
  [DEP_VAR_SOURCES[CONNECTORS_IDS.GOOGLE_ANALYTICS_4].MAIN]:
    BASELINE_REVENUE_GA,
  [DEP_VAR_SOURCES[CONNECTORS_IDS.GOOGLE_ANALYTICS_4].ALT]:
    BASELINE_REVENUE_GA_ALT,
}
