import { XMarkIcon } from "@heroicons/react/20/solid"
import { PropsWithChildren } from "react"
import { Card } from "~/ui-rtk/components/ui/common"

export type TRecommendedActionsSectionCard = {
  categoryGroup: string
  categoryName: string
}

type TRecommendedActionsSectionCardProps = {
  card: TRecommendedActionsSectionCard
  handleCancel: () => void
  buttons?: React.ReactNode | undefined
}

export default function RecommendedActionsSectionCard({
  card,
  buttons,
  handleCancel,
  children,
}: PropsWithChildren<TRecommendedActionsSectionCardProps>) {
  return (
    <Card
      rounded
      className="recommended-actions-section-card p-4 min-w-75 w-75 text-base font-semibold text-white border bg-basic-blue/20 border-basic-blue flex flex-col gap-4"
    >
      <div className="flex justify-between items-center flex-0-0">
        <div className="grow text-sm">
          <span className="text-white/50">{card.categoryGroup} &gt; </span>
          <span>{card.categoryName}</span>
        </div>
        <div className="grow-0">
          <XMarkIcon
            onClick={handleCancel}
            className="text-white w-6 h-6 cursor-pointer"
          />
        </div>
      </div>
      <div className="flex-auto">{children}</div>
      <div className="flex-0-0">{buttons}</div>
    </Card>
  )
}
