import { Filter, Query } from "@cubejs-client/core"
import { useMemo, useState } from "react"
import { MetricsMapping } from "~/ui-rtk/constants/metrics-mapping"
import { useCubeQuery } from "~/ui-rtk/hooks/cube"
import useCurrentCompany from "~/ui-rtk/hooks/current-company"
import useDate from "~/ui-rtk/hooks/date"
import { DATE_FORMAT } from "~/ui-rtk/hooks/period"
import {
  SessionStorageKeys,
  useSessionStorage,
} from "~/ui-rtk/hooks/session-storage"
import {
  getPivotData,
  polyfillCubeQueryWithTimeDimension,
} from "~/ui-rtk/utils/cube"

const cache: Record<string, any> = {}
const query: Record<string, Query> = {}

const dayjs = useDate()

const today = dayjs().toDate()

export default function useAdsetsToIncreaseBudget(
  dateStart: Date,
  diff: number,
) {
  const { q, postProcess } =
    MetricsMapping.brand_media_optimization_adsets_table
  const { id } = useCurrentCompany() ?? { id: null }
  const [hiddenCardIds, setHiddenCardIds] = useSessionStorage<string[]>(
    SessionStorageKeys.HIDE_TOP_10_HERO_ADS,
    [],
  )
  const [, rerender] = useState(0)

  const formattedDateStart = `${id}-${dayjs(dateStart).format(DATE_FORMAT)}`

  if (!query[formattedDateStart]) {
    query[formattedDateStart] = polyfillCubeQueryWithTimeDimension(
      {
        ...q,
        filters: [
          {
            dimension: "company_media_metrics_adsets.short_term_revenue_roas",
            operator: "gt",
            values: ["1"],
          },
          {
            dimension: "company_media_metrics_adsets.brand_value_over_spend",
            operator: "gt",
            values: ["2"],
          },
          {
            dimension: "company_media_metrics_adsets.spend",
            operator: "gt",
            values: ["300"],
          },
        ] as Filter[],
        order: {
          "company_media_metrics_adsets.long_term_revenue": "desc",
        },
      } as Query,
      {
        dateRange: [dateStart, today],
      },
    )
  }

  const cubeQuery = useCubeQuery(query[formattedDateStart])
  const { resultSet, isLoading } = cubeQuery

  if (resultSet) {
    const pivotData = postProcess?.(
      getPivotData(resultSet, query[formattedDateStart]),
      {},
    )

    if (pivotData?.[0]) {
      cache[formattedDateStart] = pivotData[0]
    }
  }

  const data = useMemo(
    () =>
      ((hiddenCardIds ?? []).length > 0
        ? (cache[formattedDateStart] ?? []).filter(
            (itm: Record<string, string>) =>
              !(hiddenCardIds ?? []).includes(itm.ad_id),
          )
        : (cache[formattedDateStart] ?? [])
      ).filter((metric: Record<string, string>) => {
        const spend = +metric.spend
        const budget = +metric.budget

        return spend >= budget * diff
      }),
    [hiddenCardIds, cache],
  )

  const hide = (metric: Record<string, string>) => {
    setHiddenCardIds(
      Array.from(new Set([...(hiddenCardIds ?? []), metric.ad_id])),
    )
    rerender(i => i + 1)
  }

  return {
    data,
    isLoading,
    isStarted:
      JSON.stringify(cubeQuery.previousQuery) ===
      JSON.stringify(query[formattedDateStart]),
    hide,
  }
}
