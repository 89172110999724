import numeral from "numeral"
import { useEffect, useState } from "react"
import {
  useMediaCategoryControllerGetMediaCategoryQuery,
  useMediaCategoryControllerPostMediaCategoriesMutation,
} from "~/ui-rtk/api/mediaCategoryApi.ts"

import useSources from "~/ui-rtk/hooks/sources"
import { calcDelta } from "~/ui-rtk/utils/chart-utils"

import { DetailsTitle } from "../components/DetailsHeader"
import { Card, Loader } from "~/ui-rtk/components/ui/common"
import { Image } from "~/ui-rtk/components/ui/common/Image"
import { H5 } from "~/ui-rtk/components/ui/typography"
import { ArrowTopRightOnSquare } from "~/ui-rtk/components/ui/svg/arrow/ArrowTopRightOnSquare"

import { usePostQubeQuery } from "./hooks/cube-query"

import {
  POST_MEDIA_CATEGORIES_OPTIONS,
  TCampaignAdSetAdDetailsProps,
  TPostMediaOption,
} from "../constants"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

import useDate from "~/ui-rtk/hooks/date"
import ListBox from "~/ui-rtk/components/ui/common/ListBox/ListBox"
import useCurrentCompany from "~/ui-rtk/hooks/current-company"
import useDemoMode from "~/ui-rtk/hooks/demo-mode"
const dayjs = useDate()

const prefixKey = "company_media_metrics_organic_posts"

export default function PostDetails(props: TCampaignAdSetAdDetailsProps) {
  const { sources } = useSources()
  const currentCompany = useCurrentCompany()
  const { metric, widget, prefix } = props
  const prefixToUse = prefix ?? prefixKey
  const postCaption = metric[`${prefixToUse}.post_caption`]
  const postId = metric[`${prefixToUse}.post_id`]
  const postLink = metric[`${prefixToUse}.post_link`]
  const postDate = metric[`${prefixToUse}.md_date`]
  const sourceName = metric[`${prefixToUse}.media_channel`]?.toUpperCase()

  const isMentions = prefix === "company_media_metrics_mentions"

  const reach = metric[`${prefixToUse}.reach`]
  const follows = isMentions
    ? metric[`${prefixToUse}.followers`]
    : metric[`${prefixToUse}.follows`]
  const shares = metric[`${prefixToUse}.shares`]
  const saves = metric[`${prefixToUse}.saves`]
  const comments = metric[`${prefixToUse}.comments`]
  const likes = metric[`${prefixToUse}.likes`]

  const brandValue =
    metric[`${prefixToUse}.brand_value`] ??
    metric[`${prefixToUse}.brand_value_agg`]
  const brandValuePerReach = reach > 0 ? brandValue / reach : 0

  const previewLink = metric[`${prefixToUse}.post_preview`]
  const cachedImage = isMentions
    ? metric[`${prefixToUse}.post_link`]
    : `https://marathon-prod-platform-media.s3.us-east-1.amazonaws.com/prod/${currentCompany?.id}/${metric[`${prefixToUse}.media_channel`]}/${postId}`

  const source = sources.get(sourceName)

  const { allPostsDetails } = usePostQubeQuery(postId, widget, prefixToUse)

  const averageBrandValue =
    (allPostsDetails?.total ?? 0) > 0
      ? allPostsDetails?.brandValue / allPostsDetails?.total
      : 0

  const delta = calcDelta(brandValue, averageBrandValue)

  const [captionExpanded, setExpanded] = useState(false)

  const [category, setCategory] = useState<string | null | undefined>(undefined)

  const { isEnabled: isDemoEnabled } = useDemoMode()

  const [createMediaCategory] =
    useMediaCategoryControllerPostMediaCategoriesMutation()

  const {
    data: mediaCategory,
    isLoading: mediaCategoryLoading,
    isFetching: mediaCategoryFetching,
    refetch,
  } = useMediaCategoryControllerGetMediaCategoryQuery(
    {
      id: postId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  useEffect(() => {
    if (mediaCategory?.category) {
      setCategory(mediaCategory.category)
    } else {
      setCategory(null)
    }
  }, [mediaCategory])

  const categoryUpdate = async (value: TPostMediaOption) => {
    await createMediaCategory({
      body: {
        mediaId: postId,
        type: "ad",
        channel: metric[`${prefixToUse}.media_channel`],
        category: value,
      },
    })
    setCategory(value)
    await refetch()
  }

  return (
    <div className="post-details w-145">
      <DetailsTitle
        id={postId}
        title={postCaption ?? ""}
        classes={{ container: "mb-4" }}
        demoMode={isDemoEnabled}
      />
      {!isMentions && (
        <div className="col-span-7">
          {mediaCategoryLoading || mediaCategoryFetching ? (
            <Loader />
          ) : (
            <ListBox
              options={POST_MEDIA_CATEGORIES_OPTIONS}
              onSelect={categoryUpdate}
              value={category}
              label="Category"
              placeholder="Please select a Category"
            />
          )}
        </div>
      )}
      <div
        className={cn(
          {
            "grid grid-cols-2 gap-4": !isMentions,
          },
          "mt-4",
        )}
      >
        <Card
          rounded
          className="bg-background-dark border border-basic-grey-inactive p-5"
        >
          <div className="aspect-square overflow-hidden">
            <Image
              classes={{
                image: isDemoEnabled ? "blur-md" : "w-full h-full",
                wrapper: isDemoEnabled
                  ? "blur-md"
                  : "aspect-square overflow-hidden",
              }}
              src={cachedImage}
              fallback={previewLink}
              alt={postCaption}
            />
          </div>
          <div>
            <div className={cn(isDemoEnabled && "blur-sm")}>
              {!captionExpanded && `${postCaption?.substring(0, 60)}...`}
              {captionExpanded && postCaption}
            </div>
            <div className="grid grid-cols-2 justify-between">
              <p
                className="text-white font-bold py-2 px-4 rounded cursor-pointer"
                onClick={() => setExpanded(!captionExpanded)}
              >
                {!captionExpanded && "See More"}
                {captionExpanded && "Collapse"}
              </p>
            </div>
            {}
          </div>
        </Card>
        {!isMentions && (
          <div>
            <H5 className="font-semibold">Brand Value</H5>
            <div className="rounded-xl border border-cyan bg-cyan/15 p-5 grid grid-cols-3 gap-2 text-sm text-cyan mt-2">
              <div>All Time:</div>
              <div className="col-span-2 grid grid-cols-2 gap-2">
                <div className="font-semibold">
                  ${numeral(brandValuePerReach).format("0.00a")}
                </div>
                <div>/Reach</div>
                <div className="font-semibold">
                  ${numeral(brandValue).format("0.00a")}
                </div>
                <div>Total</div>
                <div className="font-semibold">
                  {delta && delta > 0 ? "+" : ""}
                  {delta?.toFixed(0)}%
                </div>
                <div>Average Post</div>
              </div>
            </div>
          </div>
        )}
      </div>
      {!isMentions && postLink && (
        <div className="mt-3">
          <a
            href={postLink}
            target="_blank"
            className="gap-2 justify-center cursor-pointer items-center p-4 w-full blue bg-basic-dark-blue border border-basic-blue hover:bg-basic-blue flex rounded-lg text-4 line-height-4 font-semibold leading-5 transition-all active:scale-95"
          >
            <ArrowTopRightOnSquare fill="white" size={20} /> View Post
          </a>
        </div>
      )}
      <Card
        rounded
        className="bg-background-dark border border-basic-grey-inactive mt-4 p-5"
      >
        <H5 className="font-semibold">Details</H5>
        <div className="grid grid-cols-2 mt-2">
          {/* <div>
           <div className="text-sm">Content Type</div>
           <div className="font-semibold">Single Image</div>
           </div> */}
          <div>
            <div className="text-sm">Active Dates</div>
            <div className="font-semibold">
              {dayjs(postDate).format("MM/DD/YY")} - Present
            </div>
          </div>
          <div>
            <div className="text-sm">Channel</div>
            <div className="capitalize font-semibold">{source?.name}</div>
          </div>
          {/* <div>
           <div className="text-sm">Placement</div>
           <div className="font-semibold">Feed</div>
           </div> */}
        </div>
      </Card>
      <Card
        rounded
        className="bg-background-dark border border-basic-grey-inactive mt-4 p-5"
      >
        <H5 className="font-semibold">Engagements</H5>
        <div
          className={cn(
            {
              "grid-cols-6": !isMentions,
              "grid-cols-2": isMentions,
            },
            "grid mt-2",
          )}
        >
          {!isMentions && (
            <>
              <div>
                <div className="text-sm">Reach</div>
                <div className="font-semibold">
                  {numeral(reach).format("0.00a")}
                </div>
              </div>
              <div>
                <div className="text-sm">Follows</div>
                <div className="font-semibold">
                  {numeral(follows).format("0.00a")}
                </div>
              </div>
              <div>
                <div className="text-sm">Shares</div>
                <div className="font-semibold">
                  {numeral(shares).format("0.00a")}
                </div>
              </div>
              <div>
                <div className="text-sm">Saves</div>
                <div className="font-semibold">
                  {numeral(saves).format("0.00a")}
                </div>
              </div>
            </>
          )}
          <div>
            <div className="text-sm">Comments</div>
            <div className="font-semibold">
              {numeral(comments).format("0.00a")}
            </div>
          </div>
          <div>
            <div className="text-sm">Likes</div>
            <div className="font-semibold">
              {numeral(likes).format("0.00a")}
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}
