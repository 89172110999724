import { emptySplitApi as api } from "./emptyApi"
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    companyGoalControllerCreateCompanyGoal: build.mutation<
      CompanyGoalControllerCreateCompanyGoalApiResponse,
      CompanyGoalControllerCreateCompanyGoalApiArg
    >({
      query: (arg: CompanyGoalControllerCreateCompanyGoalApiArg) => ({
        url: `/company-goal`,
        method: "PUT",
        body: arg.body,
      }),
    }),
    companyGoalControllerGetAllCompanyGoals: build.query<
      CompanyGoalControllerGetAllCompanyGoalsApiResponse,
      CompanyGoalControllerGetAllCompanyGoalsApiArg
    >({
      query: () => ({ url: `/company-goal` }),
    }),
    companyGoalControllerGetCompanyGoalById: build.query<
      CompanyGoalControllerGetCompanyGoalByIdApiResponse,
      CompanyGoalControllerGetCompanyGoalByIdApiArg
    >({
      query: queryArg => ({ url: `/company-goal/${queryArg.id}` }),
    }),
    companyGoalControllerUpdateCompanyGoal: build.mutation<
      CompanyGoalControllerUpdateCompanyGoalApiResponse,
      CompanyGoalControllerUpdateCompanyGoalApiArg
    >({
      query: queryArg => ({
        url: `/company-goal/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    companyGoalControllerRemovCompanyGoal: build.mutation<
      CompanyGoalControllerRemovCompanyGoalApiResponse,
      CompanyGoalControllerRemovCompanyGoalApiArg
    >({
      query: queryArg => ({
        url: `/company-goal/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as generatedApi }

export type CompanyGoalDto = {
  id: string
  companyId: string
  title: string
  description?: string
  metric: string
  goalGrowthType: string
  goalGrowthValue: number
  targetPeriod: Date
  compareTo: string
  notes?: string
}
export type CompanyGoalControllerCreateCompanyGoalApiResponse =
  /** status 200  */ CompanyGoalDto[]
export type CompanyGoalControllerCreateCompanyGoalApiArg = {
  body: Omit<CompanyGoalDto, "id" | "companyId">
}
export type CompanyGoalControllerGetAllCompanyGoalsApiResponse =
  /** status 200  */ CompanyGoalDto[]
export type CompanyGoalControllerGetAllCompanyGoalsApiArg = void
export type CompanyGoalControllerGetCompanyGoalByIdApiResponse =
  /** status 200  */ CompanyGoalDto
export type CompanyGoalControllerGetCompanyGoalByIdApiArg = {
  id: string
}
export type CompanyGoalControllerUpdateCompanyGoalApiResponse =
  /** status 200  */ CompanyGoalDto
export type CompanyGoalControllerUpdateCompanyGoalApiArg = {
  id: string
  body: Omit<CompanyGoalDto, "companyId">
}
export type CompanyGoalControllerRemovCompanyGoalApiResponse =
  /** status 200  */ CompanyGoalDto
export type CompanyGoalControllerRemovCompanyGoalApiArg = {
  id: string
}
export const {
  useCompanyGoalControllerCreateCompanyGoalMutation,
  useCompanyGoalControllerGetAllCompanyGoalsQuery,
  useCompanyGoalControllerGetCompanyGoalByIdQuery,
  useCompanyGoalControllerUpdateCompanyGoalMutation,
  useCompanyGoalControllerRemovCompanyGoalMutation,
} = injectedRtkApi
