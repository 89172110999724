import RecommendedActionsSectionCard from "./RecommendedActionsSectionCard"
import { H3, H5 } from "~/ui-rtk/components/ui/typography"
import useDrawer from "~/ui-rtk/hooks/drawer"
import { DRAWER_TYPE } from "~/ui-rtk/constants/drawer"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import useDemoMode from "~/ui-rtk/hooks/demo-mode"
import { safeDivide, toCoef, toCurrency } from "~/ui-rtk/utils/number-utils"
import useAdsetsToIncreaseBudget from "../../hooks/adsets-to-increase-budget"
import { CaptionPreview, Loader } from "~/ui-rtk/components/ui/common"
import { useMemo } from "react"
import { useURLSearchParams } from "~/ui-rtk/hooks/url"
import TabsGroup from "~/ui-rtk/components/ui/controls/TabsGroup/TabsGroup"
import { TABS_OPTIONS } from "../../constants/adsets"

type TBrandValueIncreaseAdsetBudgetSectionProps = {
  className?: string
}

const urlKey = "ad-set-budget-to-increase"

export default function BrandValueIncreaseAdsetBudgetSection({
  className,
}: TBrandValueIncreaseAdsetBudgetSectionProps) {
  const { openDrawer } = useDrawer()
  const { isEnabled: isDemoEnabled } = useDemoMode()
  const { params, setParams } = useURLSearchParams()

  const tabIndex = useMemo(() => {
    if (params[urlKey]) {
      return +params[urlKey]
    }
    return 0
  }, [params])

  const handleSetTabIndex = (idx: number) => {
    setParams({
      [urlKey]: `${idx}`,
    })
  }

  const tab = useMemo(() => TABS_OPTIONS[tabIndex], [tabIndex, TABS_OPTIONS])

  const { data, isLoading, hide } = useAdsetsToIncreaseBudget(
    tab.value,
    tab.diff,
  )

  const handleClick = (metric: Record<string, string>) => {
    openDrawer({
      clickSourceId: ".recommended-actions-section-card",
      props: {
        metric,
        component: "AdSetDetails",
      },
      type: DRAWER_TYPE.CampaingAdSetAdDetails,
    })
  }

  const renderButtons = (metric: Record<string, string>) => (
    <div className="w-full flex justify-between items-center">
      <button className="btn btn-block" onClick={() => handleClick(metric)}>
        Open
      </button>
    </div>
  )

  const renderCardContent = (metric: Record<string, string>, idx: number) => {
    const adsetName = metric.adset_name

    const spend = +metric.spend
    const brandValue = +(metric.brand_value ?? metric.brand_value_agg)
    const brandValueOverSpend = +metric.brand_value_over_spend
    const totalValue = +metric.long_term_revenue
    const budget = +metric.budget

    const stats: Record<string, string> = {
      "Total Spend": toCurrency(spend),
      "Daily Avg. Spend": toCurrency(safeDivide(spend, tab.diff)),
      "Brand ROAS": toCoef(brandValueOverSpend),
      "Brand Value": toCurrency(brandValue),
      "Total ROAS": toCoef(safeDivide(totalValue, spend)),
      "Total Value": toCurrency(totalValue),
      Budget: toCurrency(budget),
    }

    return (
      <div className="w-full">
        <div
          className={`grid grid-cols-1 items-start justify-start ${isDemoEnabled ? "blur-md" : ""}`}
        >
          <CaptionPreview
            text={adsetName}
            maxLength={35}
            align={idx ? "right" : "left"}
          />
        </div>
        <div className="grid grid-cols-2 mt-4 text-white/50 text-sm">
          {Object.keys(stats).map(statKey => (
            <div key={statKey} className="grid grid-cols-2 items-center">
              <div className="text-xs">{statKey}:</div>
              <div className="text-white">{stats[statKey]}</div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className={cn("top-10-hero-ads-actions-section h-full", className)}>
      <div>
        <H3>Daily Actions: Increase Ad Set Budget to Drive Brand Value</H3>
        <H5 className="text-white/50">
          These ad sets are showing they can drive Brand Value profitably and
          efficiently - consider expanding their budget to maximize brand value.
        </H5>
      </div>
      <div
        className={cn(
          className,
          "join max-h-14 border-b border-b-basic-blue w-full",
        )}
      >
        <TabsGroup
          options={TABS_OPTIONS}
          onChange={handleSetTabIndex}
          index={tabIndex}
        />
      </div>
      {isLoading ? (
        <div className="p-5 flex justify-center items-center">
          <Loader size={150} />
        </div>
      ) : (
        <div className="mt-4 p-0 flex gap-3 overflow-x-auto">
          {(data ?? []).length > 0 ? (
            data?.map((metric: Record<string, string>, idx: number) => (
              <RecommendedActionsSectionCard
                key={idx}
                card={{
                  categoryGroup: "BRAND MEDIA",
                  categoryName: "ADS",
                }}
                handleCancel={() => hide(metric)}
                buttons={renderButtons(metric)}
              >
                {renderCardContent(metric, idx)}
              </RecommendedActionsSectionCard>
            ))
          ) : (
            <div className="p-5">No Adsets found</div>
          )}
        </div>
      )}
    </div>
  )
}
