import { SubscriptionDto } from "./subscription.types"

export enum CompanySetupStatus {
  PENDING_PAYMENT = "PENDING_PAYMENT",
  INITIAL_SETUP = "INITIAL_SETUP",
  COMPLETE = "COMPLETE",
}

export type CompanyMetadataDto = {
  airByte?: {
    workspaceId: string
    destinationId: string
  }
  fivetranGroupId?: string
  s3Folder?: string
  stripeCustomerId?: string
  keywords?: {
    google?: {
      searchAlias?: string
      brands: string[]
      competitors: string[]
      categories: string[]
      hash: string | null
    }
    instagram?: {
      competitors: string[]
      hash: string
    }
  }
  mentions?: {
    brandKeywords?: string[]
    facebookUsernames?: string[]
    igUsernames?: string[]
    tiktokUsernames?: string[]
  }
  industry?: string
  isPipelineEnabled?: boolean
  freeAccessEndsAt?: string | null
  hasCustomPlan?: boolean
  isBillingEnabled?: boolean
  skippedServices?: string[]
  revenueSource?: string
  skipOnboardingFlow?: boolean
  homePage?: {
    setupGuideSection?: {
      hiddenCards?: string[]
    }
    learningZoneSection?: {
      isHidden?: boolean
    }
  }
  settings?: {
    flags?: {
      ga_multi_property?: boolean
    }
  }
  model_props?: {
    ecom: {
      dep_var: string
      dep_var_scaler: string
    }
  }
}

export type CompanyDto = {
  id: string
  name: string
  metadata: CompanyMetadataDto
  domain?: string
  setupStatus: CompanySetupStatus
  createdAt: string
  updatedAt: string
  subscription: SubscriptionDto | null
}

export type CompanySubscriptionMetadataDto = {
  daysOfTrial?: number
  stripeCustomerId?: string
  subscriptionId?: string
  daysUntilDue?: number
  trialEnd?: Date
  customerId?: string
  priceId?: string
}
