import { useMemo } from "react"
import {
  type Query as TCubeQuery,
  ResultSet,
  type Filter,
} from "@cubejs-client/core"

import { CUBE_QUERY_KEYS_MAP, getCubeQueryBase } from "../../constants"
import useCampaignAdSetAdDetails, {
  prepareAllItemsDetails,
  useAllCampaignsAdSetsAdsDetails,
} from "~/ui-rtk/hooks/campaign-adset-ad-details"
import {
  getPivotData,
  polyfillCubeQueryWithTimeDimension,
} from "~/ui-rtk/utils/cube"
import useCurrentCompany from "~/ui-rtk/hooks/current-company"

import { MetricsMapping } from "~/ui-rtk/constants/metrics-mapping"
import { useYearDateRange } from "~/ui-rtk/hooks/date-range"

const defaultPrefixKey = "company_media_metrics_ads"

const requests: Record<string, TCubeQuery> = {}
const results: Record<string, any> = {}

const ALL = "ALL"

export const useAllAdsCubeQuery = () => {
  const { postProcess } = MetricsMapping.brand_media_optimization_ads_table
  const prefixKey = defaultPrefixKey
  const currentCompany = useCurrentCompany()
  const currentCompanyId = currentCompany?.id ?? "-company-not-set-"
  const campaignId = ALL
  const adSetId = ALL
  const cacheKey = `${prefixKey}.${currentCompanyId}.${campaignId}.${adSetId}`
  const queryAllCampaignKey = `${cacheKey}.all`
  const campaignIdKey = `${prefixKey}.campaign_id`
  const purchaseKey = `${prefixKey}.purchases`
  const dateRange = useYearDateRange()

  const campaignQuery = getCubeQueryBase(prefixKey)

  const allAdsQuery = useMemo(() => {
    if (!requests[queryAllCampaignKey]) {
      requests[queryAllCampaignKey] = {
        ...campaignQuery,
        measures: [...campaignQuery.measures, purchaseKey],
        dimensions: [campaignIdKey],
      }
    }
    return polyfillCubeQueryWithTimeDimension(requests[queryAllCampaignKey], {
      dateRange,
    })
  }, [
    queryAllCampaignKey,
    purchaseKey,
    campaignIdKey,
    campaignQuery,
    dateRange,
  ])

  const { [CUBE_QUERY_KEYS_MAP.ALL]: allAdsSet, isLoading } =
    useAllCampaignsAdSetsAdsDetails(allAdsQuery)

  if (!results[cacheKey]) {
    results[cacheKey] = {}
  }

  if (allAdsSet) {
    const pivotData = getPivotData(allAdsSet as ResultSet<any>, allAdsQuery)
    const processedPivotData = postProcess
      ? postProcess(pivotData, {})[0]
      : pivotData
    results[cacheKey].allAdsDetails = prepareAllItemsDetails(processedPivotData)
  }

  const { allAdsDetails } = results[cacheKey]

  return { allAdsDetails, isLoading }
}

export const useAdsCubeQuery = (adSetId = ALL, campaignId = ALL) => {
  const { postProcess } = MetricsMapping.brand_media_optimization_ads_table
  const prefixKey = defaultPrefixKey
  const currentCompany = useCurrentCompany()
  const currentCompanyId = currentCompany?.id ?? "-company-not-set-"
  const cacheKey = `${prefixKey}.${currentCompanyId}.${campaignId}.${adSetId}}`
  const queryAllAdsKey = `${cacheKey}.all`
  const dateRange = useYearDateRange()

  const campaignIdKey = `${prefixKey}.campaign_id`
  const adSetIdKey = `${prefixKey}.adset_id`
  const adIdKey = `${prefixKey}.ad_id`

  const campaignQuery = getCubeQueryBase(prefixKey)

  const allAdsFilters = useMemo<Filter[]>(() => {
    const filters = [] as Filter[]
    if (adSetId !== ALL) {
      filters.push({
        dimension: adSetIdKey,
        operator: "equals",
        values: [adSetId],
      })
    } else if (campaignId !== ALL) {
      filters.push({
        dimension: campaignIdKey,
        operator: "equals",
        values: [campaignId],
      })
    }
    return filters
  }, [prefixKey, campaignId, adSetId])

  const allAdsQuery = useMemo(() => {
    if (!requests[queryAllAdsKey]) {
      requests[queryAllAdsKey] = {
        ...campaignQuery,
        dimensions: [adIdKey],
      }
      if (allAdsFilters.length > 0) {
        requests[queryAllAdsKey].filters = [
          {
            and: [...allAdsFilters],
          },
        ]
      }
    }
    return polyfillCubeQueryWithTimeDimension(requests[queryAllAdsKey], {
      dateRange,
    })
  }, [queryAllAdsKey, adIdKey, allAdsFilters, campaignQuery, dateRange])

  const { [CUBE_QUERY_KEYS_MAP.ALL]: allAdsSet, isLoading } =
    useCampaignAdSetAdDetails({
      [CUBE_QUERY_KEYS_MAP.ALL]: allAdsQuery,
    })

  if (!results[cacheKey]) {
    results[cacheKey] = {}
  }

  if (allAdsSet) {
    const pivotData = getPivotData(allAdsSet as ResultSet<any>, allAdsQuery)
    const processedPivotData = postProcess
      ? postProcess(pivotData, {})[0]
      : pivotData
    results[cacheKey].allAdsDetails = prepareAllItemsDetails(processedPivotData)
  }

  const { allAdsDetails } = results[cacheKey]

  return { allAdsDetails, isLoading }
}
