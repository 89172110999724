import WithLoader from "~/ui-rtk/components/layout/WithLoader/WithLoader"
import useConnect, { type TGoalForm } from "./connect"
import WithSources from "~/ui-rtk/components/layout/WithSources/WithSources"
import { H1, H5, H6 } from "~/ui-rtk/components/ui/typography"
import Breadcrumbs from "~/ui-rtk/components/layout/Breadcrumbs"
import { brandRoutes } from "~/ui-rtk/constants/routes"
import { Field, Form, Formik, FormikProps } from "formik"
import useLoadingState from "~/ui-rtk/hooks/loading-state"
import { useMemo, useRef } from "react"
import { Button, Input, Select } from "~/ui-rtk/components/ui/controls"
import { ErrorBlock } from "~/ui-rtk/utils/form"
import { Card } from "~/ui-rtk/components/ui/common"
import { CalendarDaysIcon } from "@heroicons/react/20/solid"
import MonthPicker from "~/ui-rtk/components/ui/controls/MonthPicker/MonthPicker"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"

export default function NewGoalPage() {
  const {
    isLoading: isSourceLoading,
    initialValues,
    validationSchema,
    goalGrowthOptions,
    compareToOptions,
    metricsOptions,
    handleCreate,
  } = useConnect()
  const { isLoading: isApiLoading, setIsLoading: setIsApiLoading } =
    useLoadingState()
  const formRef = useRef<FormikProps<TGoalForm> | null>(null)
  const navigate = useStableNavigate()

  const isLoading = useMemo(
    () => isSourceLoading || isApiLoading,
    [isSourceLoading, isApiLoading],
  )

  const handleAddGoal = async (values: TGoalForm) => {
    setIsApiLoading(true)
    await handleCreate(values)
    navigate("/home")
    setIsApiLoading(false)
  }

  const handleCancel = () => {
    navigate("/home")
  }

  return (
    <div className="h-full p-6 bg-basic-black text-basic-light">
      <WithLoader isLoading={isLoading}>
        <WithSources>
          <div className="text-start">
            <H1 className="flex">
              <Breadcrumbs
                items={[
                  {
                    name: "Welcome",
                  },
                  {
                    slug: brandRoutes.HOME.ROOT,
                    name: "Home",
                  },
                  {
                    slug: brandRoutes.HOME.NEW_GOAL,
                    name: "Add New Goal",
                  },
                ]}
              />
            </H1>
          </div>
          <div className="space-y-8 mt-4 pr-8 text-start">
            <Formik
              initialValues={initialValues}
              onSubmit={handleAddGoal}
              validationSchema={validationSchema}
              enableReinitialize
              innerRef={formRef}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({
                errors,
                handleChange,
                setFieldValue,
                values /* ,  touched */,
              }) => (
                <Form className="py-6">
                  <div>
                    <Field
                      id="title"
                      name="title"
                      as={Input}
                      type="text"
                      fullWidth
                      classes={{
                        input: "text-4xl",
                      }}
                      className="border-0 bg-transparent"
                      placeholder="I want to achieve..."
                      onChange={(ev: React.ChangeEvent<string>) => {
                        handleChange(ev)
                      }}
                    />
                    <ErrorBlock error={errors.title} />
                  </div>
                  <div className="min-h-32 border-b border-b-basic-blue">
                    <Field
                      id="description"
                      name="description"
                      as={Input}
                      type="text"
                      fullWidth
                      classes={{
                        input: "text-xl",
                      }}
                      className="border-0 bg-transparent"
                      placeholder="Add description"
                      onChange={(ev: React.ChangeEvent<string>) => {
                        handleChange(ev)
                      }}
                    />
                    <ErrorBlock error={errors.description} />
                  </div>
                  <div className="mt-3 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3">
                    <Card
                      rounded
                      className="border border-basic-blue bg-basic-dark-blue p-4 col-span-1 text-basic-neutral"
                    >
                      <H5>DEFINE THE TARGET</H5>
                      <div className="mt-3 flex flex-col space-y-4">
                        <label>
                          <H6 className="mb-1">Metric</H6>
                          <Field
                            id="metric"
                            name="metric"
                            as={Select}
                            options={metricsOptions}
                            className="border-0 bg-basic-blue"
                            onChange={(ev: React.ChangeEvent<string>) => {
                              handleChange(ev)
                            }}
                          />
                          <ErrorBlock error={errors.metric} />
                        </label>
                        <label>
                          <H6 className="mb-1">Goal Type</H6>
                          <Field
                            id="goalGrowthType"
                            name="goalGrowthType"
                            as={Select}
                            options={goalGrowthOptions}
                            className="border-0 bg-basic-blue"
                            onChange={(ev: React.ChangeEvent<string>) => {
                              handleChange(ev)
                            }}
                          />
                          <ErrorBlock error={errors.goalGrowthType} />
                        </label>
                        <label>
                          <H6 className="mb-1">Goal Type</H6>
                          <Field
                            id="targetPeriod"
                            name="targetPeriod"
                            as={MonthPicker}
                            startOfMonth={values.targetPeriod}
                            icon={<CalendarDaysIcon className="w-6 h-6" />}
                            classes={{
                              input:
                                "!w-full border-0 bg-basic-blue !text-sm !font-normal",
                            }}
                            onChange={(val: Date) => {
                              void setFieldValue("targetPeriod", val)
                            }}
                          />
                          <ErrorBlock error={errors.targetPeriod as string} />
                        </label>
                        <label>
                          <H6 className="mb-1">Growth Target</H6>
                          <Field
                            id="goalGrowthValue"
                            name="goalGrowthValue"
                            as={Input}
                            type="number"
                            fullWidth
                            className="border-0 bg-basic-blue"
                            onChange={(ev: React.ChangeEvent<string>) => {
                              handleChange(ev)
                            }}
                          />
                          <ErrorBlock error={errors.goalGrowthValue} />
                        </label>
                        <label>
                          <H6 className="mb-1">Growth Target</H6>
                          <Field
                            id="compareTo"
                            name="compareTo"
                            as={Select}
                            options={compareToOptions}
                            className="border-0 bg-basic-blue"
                            onChange={(ev: React.ChangeEvent<string>) => {
                              handleChange(ev)
                            }}
                          />
                          <ErrorBlock error={errors.compareTo} />
                        </label>
                      </div>
                      <div className="divider"></div>
                      <H5>DEFINE THE TARGET</H5>
                      <div className="mt-4 w-full">
                        <textarea
                          className="border-0 bg-basic-blue rounded-sm resize-none w-full text-sm"
                          value={values.notes}
                          name="notes"
                          id="notes"
                          placeholder="Why do you expect to meet this goal?"
                          onChange={handleChange}
                        ></textarea>
                        <ErrorBlock error={errors.notes} />
                      </div>
                    </Card>
                    <div className="col-auto">To be added</div>
                  </div>
                  <div className="mt-3 flex items-center justify-end gap-3">
                    <Button
                      variant={{ variant: "solid", color: "white" }}
                      size="sm"
                      type="button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant={{ variant: "solid", color: "pink" }}
                      size="sm"
                      type="submit"
                      isLoading={isLoading}
                    >
                      Update
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </WithSources>
      </WithLoader>
    </div>
  )
}
