import { useMemo, useState } from "react"
import { useCompanyConnectorControllerGetCompanyConnectorsQuery as useGetCompanyConnectorsQuery } from "~/ui-rtk/api/companyConnectorApi"
import { CONNECTORS_IDS } from "~/ui-rtk/constants/sources"
import { TRadioToggleOption } from "~/ui-rtk/components/ui/controls/RadioToggle/RadioToggle"
import { toast } from "react-toastify"
import { hasErrorMessage } from "~/ui-rtk/utils/http-utils"
import { ConnectionStatus } from "~/ui-rtk/api/types"
import { useCompanyMetadataUpdater } from "~/ui-rtk/hooks/company-updater"
import useCurrentCompany from "~/ui-rtk/hooks/current-company"
import { DEFAULT_REVENUE_SOURCE } from "~/ui-rtk/constants/revenue-source"
import {
  DEP_VAR_SOURCES,
  type RevenueSourceValue,
  type TRevenueSourceKeysValue,
} from "~/ui-rtk/constants/revenue"

export type TRevenueSourceFormValues = {
  depVarSource: string
  depVarSourceAlt: boolean
}

const REVENUE_SOURCES_INFO: {
  [key in TRevenueSourceKeysValue]: RevenueSourceValue
} = {
  [CONNECTORS_IDS.SHOPIFY]: {
    id: CONNECTORS_IDS.SHOPIFY,
    name: "Shopify",
    icon: {
      src: "/assets/img/connectors/shopify.png",
      alt: "Shopify icon",
    },
  },
  [CONNECTORS_IDS.GOOGLE_ANALYTICS_4]: {
    id: CONNECTORS_IDS.GOOGLE_ANALYTICS_4,
    name: "Google Analytics",
    icon: {
      src: "/assets/img/connectors/ga.png",
      alt: "Google Analytics Icon",
    },
  },
}

const getEcomDepVar = (depVar: TRevenueSourceKeysValue, depVarAlt: boolean) => {
  const depVarScaler =
    depVar === CONNECTORS_IDS.GOOGLE_ANALYTICS_4
      ? "ga_baseline_revenue"
      : "shopify_baseline_revenue"
  const depVarSourceGroup = DEP_VAR_SOURCES[depVar]
  return {
    dep_var_scaler: depVarScaler,
    dep_var: depVarAlt ? depVarSourceGroup.ALT : depVarSourceGroup.MAIN,
  }
}

export const useConnect = () => {
  const {
    data: companyConnectors,
    isLoading: isCompanyConnectorsLoading,
    isError: isCompanyConnectorsError,
  } = useGetCompanyConnectorsQuery()
  const { updateCompanyMetadata } = useCompanyMetadataUpdater()

  const sourceInfo: {
    [key in TRevenueSourceKeysValue]: RevenueSourceValue
  } = {
    ...REVENUE_SOURCES_INFO,
  }

  const currentCompany = useCurrentCompany()
  const { model_props: modelProps } = currentCompany?.metadata ?? {}

  const depVarValue = modelProps?.ecom?.dep_var

  const depVarSource: TRevenueSourceKeysValue = depVarValue
    ? (Object.keys(DEP_VAR_SOURCES).find(key =>
        Object.values(DEP_VAR_SOURCES[key]).includes(depVarValue),
      ) ?? DEFAULT_REVENUE_SOURCE)
    : DEFAULT_REVENUE_SOURCE

  const depVarSourceAlt = depVarValue
    ? depVarValue === DEP_VAR_SOURCES[depVarSource].ALT
    : false

  const initialValues: TRevenueSourceFormValues = {
    depVarSource,
    depVarSourceAlt,
  }

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSaveSelectedSource = async (val: TRevenueSourceFormValues) => {
    const { depVarSource, depVarSourceAlt } = val
    const metadata = {
      ...(currentCompany.metadata ?? {}),
      model_props: {
        ecom: getEcomDepVar(depVarSource, depVarSourceAlt),
      },
    }

    void setIsSubmitting(true)

    try {
      await updateCompanyMetadata(metadata)
      toast.success("Revenue source was updated successfully", {
        toastId: "revenue-source-update-success",
      })
    } catch (error) {
      if (hasErrorMessage(error)) {
        toast.error(error.data.message, {
          toastId: "revenue-source-update-error",
        })
      }
    } finally {
      void setIsSubmitting(false)
    }
  }

  const isError = isCompanyConnectorsError
  const isLoading = isCompanyConnectorsLoading

  const { sources } = useMemo(
    () => ({
      sources: Object.keys(sourceInfo).map((key: TRevenueSourceKeysValue) => {
        const revenueSourceInfo = sourceInfo[key]
        const connector = companyConnectors?.find(c => c.serviceName === key)
        return {
          label: revenueSourceInfo.name,
          value: revenueSourceInfo.id,
          icon: revenueSourceInfo.icon,
          disabled: connector?.status !== ConnectionStatus.CONNECTED,
        } as TRadioToggleOption
      }),
    }),
    [companyConnectors],
  )

  return {
    sources,
    initialValues,
    isError,
    isLoading,
    isSubmitting,
    handleSaveSelectedSource,
  }
}
