import { ReactElement, useEffect, useMemo } from "react"
import { CubeProvider } from "@cubejs-client/react"
import { useIdleTimer } from "react-idle-timer"
import { useIntercom } from "react-use-intercom"

import Guest from "../Guest"
import Main from "../Main"
import { Onboarding } from "../Onboarding"
import StaticSpinner from "~/ui-rtk/components/layout/StaticSpinner"

import { useAppSession } from "./useCubeApiToken"
import useAccess from "~/ui-rtk/hooks/access"
import useMobileView from "~/ui-rtk/hooks/mobile-view"
import {
  useAllConnectorsTouched,
  useConnectionFlowIgnored,
  useOnboardingComplete,
  useOnboardingEnabled,
} from "~/ui-rtk/hooks/onboarding-flow"
import { AccountDetails, CheckInbox } from "../Onboarding/BrandInfo"
import ConfirmEmail from "../ConfirmEmail"
import { useURLSearchParams } from "~/ui-rtk/hooks/url"
import { useCompanyConnectorControllerGetCompanyConnectorsQuery } from "~/ui-rtk/api/companyConnectorApi"

const MARATHONDATA_CO_NAME = "MARATHONDATA_CO"

const AuthPage = () => {
  let content = null
  const { session, cubeApi, isLoading, invalidateTokenIfNeeded } =
    useAppSession()
  const { isSuperAdmin, isAccessGranted } = useAccess()
  const isMobile = useMobileView()
  const { params } = useURLSearchParams()
  const { shutdown } = useIntercom()

  // pre-fetch for correct url state on brand mentions
  const { isLoading: isConnectorsQueryLoading } =
    useCompanyConnectorControllerGetCompanyConnectorsQuery()

  const { user, currentCompany } = session || {}
  const isSetupStatusComplete = useOnboardingComplete()
  const isOnboardingEnabled = useOnboardingEnabled()
  const isConnectionFlowIgnored = useConnectionFlowIgnored()
  const { isEveryConnectorTouched, isLoading: isConnectorTouchedLoading } =
    useAllConnectorsTouched(!!currentCompany?.id)

  const onActive = () => {
    invalidateTokenIfNeeded()?.catch(() => null)
  }

  const isUserActive = useMemo(
    () => user?.isConfirmed || user?.isActive,
    [user],
  )

  useIdleTimer({
    onActive,
    timeout: 30_000, // 30 seconds
    throttle: 500,
    crossTab: true,
    syncTimers: 200,
    name: MARATHONDATA_CO_NAME,
  })

  useEffect(() => {
    if (isMobile) {
      shutdown()
    }
  }, [shutdown, isMobile])

  const renderMain: () => ReactElement = () =>
    isConnectorsQueryLoading ? (
      <StaticSpinner />
    ) : (
      <CubeProvider cubeApi={cubeApi}>
        <Main />
      </CubeProvider>
    )

  const renderAuthSessionContent = () => {
    if (!isUserActive && params["nonce"]) {
      return (
        <div className="w-full h-full flex items-center justify-center">
          <ConfirmEmail />
        </div>
      )
    }
    if (!isUserActive) {
      return (
        <div className="w-full h-full flex items-center justify-center">
          <CheckInbox />
        </div>
      )
    }
    if (!currentCompany?.id) {
      return (
        <div className="w-full h-full flex items-center justify-center">
          <AccountDetails />
        </div>
      )
    }
    if (!isOnboardingEnabled) {
      return renderMain()
    }
    if (isSetupStatusComplete) {
      return renderMain()
    }
    if (isEveryConnectorTouched) {
      return renderMain()
    }
    if (isConnectionFlowIgnored) {
      return renderMain()
    }
    if (isAccessGranted || isSuperAdmin) {
      return renderMain()
    }

    return <Onboarding />
  }

  if (session) {
    content = renderAuthSessionContent()
  } else if (!session && !isLoading && !isConnectorTouchedLoading) {
    content = <Guest />
  }

  return (
    <>
      <StaticSpinner />
      {content}
    </>
  )
}

export default AuthPage
