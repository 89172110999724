import { toPercentage } from "./../utils/number-utils"
import { useMemo, useState } from "react"
import { brandRoutes } from "../constants/routes"
import { useConnect as useConnectorsConnect } from "~/ui-rtk/components/features/connectors/ConnectorsTable/connect"
import usePostHogFeatureFlags from "./posthog-feaures"
import { POSTHOG_FEATURES } from "../constants/feature-flags"
import { useCompanyMetadataUpdater } from "./company-updater"
import useLoadingState from "./loading-state"
import useCurrentCompany from "./current-company"
import { safeDivide } from "../utils/number-utils"

// These values should not be modified as we store this in company metadata
const SETUP_GUIDE_CARDS = {
  ESSENTIAL_CONNECTORS: "essential_connectors",
  KEYWORDS_CATEGORIES: "keywords_categories",
  ATTRIBUTION_SETTINGS: "attribution_settings",
  KEYWORDS_COMPETITORS: "keywords_competitors",
}

export type TSetupGuideCard = {
  id: string
  label: string
  description: string
  linkTo: string
  isCompleted: boolean
  inProgress?: boolean
  isActionNeeded?: boolean
  isHidden: boolean
  statusDetails?: Record<string, any>
}

export default function useSetupGuide() {
  const {
    allEssentialConnectorsEnabled,
    totalVisibleConnectorsAmount,
    totalConnectedConnectorsAmount,
    essentialConnectorsConnectedAmount,
    essentialConnectorsToReconnectAmount,
    totalEssentialConnectorsAmount,
  } = useConnectorsConnect()
  const currentCompany = useCurrentCompany()
  const metadata = currentCompany?.metadata ?? {}
  const { keywords, homePage } = currentCompany?.metadata ?? {}
  const isRevenueSourcePageFeatureEnabled = usePostHogFeatureFlags(
    POSTHOG_FEATURES.REVENUE_DEP_VAR_SETUP_PAGE,
  )
  const [, rerender] = useState(0)
  const { updateCompanyMetadata } = useCompanyMetadataUpdater()
  const { setIsLoading } = useLoadingState()

  const hiddenCards = homePage?.setupGuideSection?.hiddenCards ?? []

  const hideCard = async (card: TSetupGuideCard) => {
    if ((hiddenCards ?? []).includes(card.id)) {
      return
    }

    const update = {
      ...metadata,
      homePage: {
        ...(metadata?.homePage ?? {}),
        setupGuideSection: {
          ...(metadata?.homePage?.setupGuideSection ?? {}),
          hiddenCards: [...(hiddenCards ?? []), card.id],
        },
      },
    }

    setIsLoading(true)
    await updateCompanyMetadata(update).catch(() => null)
    setIsLoading(false)
    rerender((idx: number) => idx + 1)
  }

  const connectorsCardDetails = useMemo(() => {
    const totalPercentage = toPercentage(
      safeDivide(totalConnectedConnectorsAmount, totalVisibleConnectorsAmount),
    )

    if (allEssentialConnectorsEnabled) {
      return {
        essentialPercentage: 100,
        totalPercentage,
      }
    }
    if (essentialConnectorsToReconnectAmount > 0) {
      return {
        reconnectNeeded: essentialConnectorsToReconnectAmount,
      }
    }
    if (essentialConnectorsConnectedAmount > 0) {
      return {
        essentialPercentage: toPercentage(
          safeDivide(
            essentialConnectorsConnectedAmount,
            totalEssentialConnectorsAmount,
          ),
        ),
        totalPercentage,
      }
    }
    return {
      essentialPercentage: 0,
      totalPercentage,
    }
  }, [
    totalConnectedConnectorsAmount,
    totalVisibleConnectorsAmount,
    allEssentialConnectorsEnabled,
    essentialConnectorsToReconnectAmount,
    essentialConnectorsConnectedAmount,
    totalEssentialConnectorsAmount,
  ])

  const cardConfig: TSetupGuideCard[] = useMemo(
    () =>
      [
        {
          id: SETUP_GUIDE_CARDS.ESSENTIAL_CONNECTORS,
          label: "Set up Connectors",
          description: "It's an essential action to collect information.",
          linkTo: brandRoutes.SETUP.CONNECTORS,
          isCompleted: allEssentialConnectorsEnabled,
          inProgress:
            essentialConnectorsConnectedAmount > 0 &&
            !allEssentialConnectorsEnabled,
          isActionNeeded: essentialConnectorsToReconnectAmount > 0,
          isHidden:
            false &&
            hiddenCards.includes(SETUP_GUIDE_CARDS.ESSENTIAL_CONNECTORS),
          statusDetails: connectorsCardDetails,
        },
        {
          id: SETUP_GUIDE_CARDS.KEYWORDS_CATEGORIES,
          label: "Set Your Keywords",
          description: "Required to dive into Mind Share",
          linkTo: brandRoutes.SETUP.KEYWORDS,
          isCompleted: !!keywords?.google?.categories,
          isHidden: hiddenCards.includes(SETUP_GUIDE_CARDS.KEYWORDS_CATEGORIES),
        },
        {
          id: SETUP_GUIDE_CARDS.KEYWORDS_COMPETITORS,
          label: "Set Your Competitors",
          description: "if you want to compare your brand to others",
          linkTo: brandRoutes.SETUP.KEYWORDS,
          isCompleted: !!keywords?.google?.competitors,
          isHidden: hiddenCards.includes(
            SETUP_GUIDE_CARDS.KEYWORDS_COMPETITORS,
          ),
        },
      ].filter(card => !card.isHidden),
    [
      currentCompany,
      allEssentialConnectorsEnabled,
      isRevenueSourcePageFeatureEnabled,
      totalVisibleConnectorsAmount,
      totalConnectedConnectorsAmount,
      essentialConnectorsConnectedAmount,
      essentialConnectorsToReconnectAmount,
      totalEssentialConnectorsAmount,
    ],
  )

  return {
    cardConfig,
    hideCard,
  }
}
