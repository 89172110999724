import { useCubeMeta } from "@cubejs-client/react"
import useSources from "./sources"
import { useMemo } from "react"
import { SOURCE_IDS } from "../constants/sources"
import { TSelectOption } from "../components/ui/controls/Select"

const CUBE = {
  METRICS: "metrics",
}

const SOURCE_ID_TO_METRIC_PREFIX_MAP = {
  [SOURCE_IDS.AMAZON]: ["amazon_"],
  [SOURCE_IDS.ATTENTIVE]: ["attentive_"],
  [SOURCE_IDS.FACEBOOK_ADS]: ["facebook_ads_", "total_"],
  [SOURCE_IDS.INSTAGRAM_ADS]: ["instagram_ads_", "total_"],
  [SOURCE_IDS.FACEBOOK]: ["facebook_pages_"],
  [SOURCE_IDS.GOOGLE_ADS]: ["google_ads_", "total_"],
  [SOURCE_IDS.GOOGLE_ANALYTICS]: ["ga_"],
  [SOURCE_IDS.GOOGLE_KEYWORDS]: ["google_keywords_"],
  [SOURCE_IDS.GOOGLE_SEARCH]: ["google_search_"],
  [SOURCE_IDS.INSTAGRAM]: ["instagram_business_", "instagram_insights_"],
  [SOURCE_IDS.KLAVIYO]: ["klaviyo_"],
  [SOURCE_IDS.SHOPIFY]: ["shopify_"],
  [SOURCE_IDS.TIKTOK]: ["tiktok_profile_", "tiktok_videos_"],
  [SOURCE_IDS.TIKTOK_ADS]: ["tiktok_ads_"],
  [SOURCE_IDS.YOTPO]: [""],
  [SOURCE_IDS.YOUTUBE]: ["youtube_"],
  DEFAULT: ["total_"],
}

export default function useMetricOptions() {
  const { isLoading: isSourcesLoading, sources } = useSources()
  const { isLoading, response } = useCubeMeta()

  const metricsOptions = useMemo(() => {
    if (!sources) {
      return []
    }
    if (!response) {
      return []
    }
    const cubeMeta = response.cubesMap[CUBE.METRICS]
    if (!cubeMeta) {
      return []
    }
    const metrics = Object.keys(cubeMeta.measures)
    return metrics.reduce((metricsReduced, metric) => {
      const metricName = metric.replace(`${CUBE.METRICS}.`, "")
      const isMetricIncluded = [...sources.values()].some(source =>
        SOURCE_ID_TO_METRIC_PREFIX_MAP[source.id]?.some(possiblePrefix =>
          metricName.startsWith(possiblePrefix),
        ),
      )
      const isMetricDefault = SOURCE_ID_TO_METRIC_PREFIX_MAP.DEFAULT.some(
        possiblePrefix => metricName.startsWith(possiblePrefix),
      )
      if (!isMetricDefault && !isMetricIncluded) {
        return metricsReduced
      }
      return [
        ...metricsReduced,
        {
          label: cubeMeta.measures[metric].title,
          value: cubeMeta.measures[metric].name,
        },
      ]
    }, [] as TSelectOption[])
  }, [response, sources])

  return {
    isLoading: isLoading || isSourcesLoading,
    metricsOptions,
  }
}
