import { type Query as TCubeQuery } from "@cubejs-client/core"
import { UseCubeQueryOptions } from "@cubejs-client/react"
import { useCubeQuery } from "~/ui-rtk/hooks/cube"
import {
  GranularityMapping,
  MetricsMapping,
  generalPostProcess,
} from "~/ui-rtk/constants/metrics-mapping"
import { AggregationType } from "~/ui-rtk/constants/charts"
import { getCubeCacheKey } from "~/ui-rtk/hooks/metrics-cache"
import { useMemo } from "react"
import { SortingState } from "@tanstack/react-table"
import {
  addSorting,
  addFilters,
  addPagination,
  getPivotData,
  polyfillCubeQueryWithTimeDimension,
} from "~/ui-rtk/utils/cube"
import { TCubeFilterOptions } from "~/ui-rtk/shared/types/charts"
import useCurrentCompany from "~/ui-rtk/hooks/current-company"

const requests: Record<string, TCubeQuery | TCubeQuery[]> = {}

type TConnectOptions = {
  chartId: string
  granularity: AggregationType
  dateRange: [Date, Date]
  page: number
  itemsPerPage: number
  enablePagination: boolean
  disableGranularity?: boolean
  prefixEnabled?: boolean
  cubeFilters?: TCubeFilterOptions
  sorting?: SortingState
  cubeQueryParams?: Record<string, string>
}

export default function useConnect({
  chartId,
  granularity,
  dateRange,
  page,
  itemsPerPage,
  cubeFilters,
  sorting,
  enablePagination,
  disableGranularity = false,
  prefixEnabled = false,
  cubeQueryParams = {},
}: TConnectOptions) {
  const $granularity: AggregationType | undefined = !disableGranularity
    ? granularity
    : undefined

  const currentCompany = useCurrentCompany()
  if (!currentCompany) {
    throw new Error("No current company")
  }

  const { id: companyId } = currentCompany

  const key = getCubeCacheKey({
    companyId,
    chartId,
    dateRange,
    page,
    itemsPerPage,
    cubeFilters,
    sorting,
    granularity: $granularity,
    cubeQueryParams,
  })

  const granularityValue = !disableGranularity
    ? GranularityMapping[granularity]
    : undefined
  const { q, getCubePrefix } = MetricsMapping[chartId]
  const cubeQuery = typeof q === "function" ? q(cubeQueryParams) : q

  const polyfillTimeDimensionPayload = {
    granularity: granularityValue,
    dateRange,
  }

  let cubePrefix: string | undefined
  if (prefixEnabled && getCubePrefix) {
    cubePrefix = getCubePrefix({ granularity: GranularityMapping[granularity] })
  }

  const query = useMemo(() => {
    let query: TCubeQuery | TCubeQuery[]
    if (requests[key]) {
      query = requests[key]
    } else {
      const offset = page * itemsPerPage

      if (Array.isArray(cubeQuery)) {
        query = cubeQuery
          .map(cq =>
            polyfillCubeQueryWithTimeDimension(
              cq,
              polyfillTimeDimensionPayload,
            ),
          )
          .map(cq => addSorting(cq, sorting))
        if (enablePagination) {
          query = query.map(cq => addPagination(cq, itemsPerPage, offset))
        }
        if (cubeFilters) {
          query = query.map(cq => addFilters(cq, cubeFilters, cubePrefix))
        }
      } else {
        query = addSorting(
          polyfillCubeQueryWithTimeDimension(
            cubeQuery,
            polyfillTimeDimensionPayload,
          ),
          sorting,
          cubePrefix,
        )
        if (enablePagination) {
          query = addPagination(query, itemsPerPage, offset)
        }

        if (cubeFilters) {
          query = addFilters(query, cubeFilters, cubePrefix)
        }
      }
    }

    requests[key] = query

    return query
  }, [key, chartId, enablePagination, itemsPerPage, page, cubeFilters])

  const queryOptions: UseCubeQueryOptions = useMemo(
    () => ({
      skip: MetricsMapping[chartId].disabled,
    }),
    [chartId],
  )

  const { resultSet, progress, isLoading } = useCubeQuery(query, queryOptions)

  let dataSource: any
  let columns: any

  if (resultSet) {
    columns = resultSet.tableColumns()
    const { postProcess } = MetricsMapping[chartId]
    const pivotData = getPivotData(resultSet, query)

    if (postProcess) {
      const processed = postProcess(pivotData, cubeQueryParams)
      dataSource = processed[0]
      if (processed[1]) {
        columns = processed[1]
      }
    } else {
      dataSource = generalPostProcess(pivotData, columns)
    }
    delete requests[key]
  }

  let totalPages = 1
  if (resultSet && itemsPerPage) {
    totalPages = Math.ceil((resultSet.totalRows() ?? 0) / itemsPerPage)
  }

  const dataSourceMemo = useMemo(
    () => (MetricsMapping[chartId].disabled ? [] : dataSource),
    [chartId, query, queryOptions, resultSet],
  )

  const columnsMemo = useMemo(() => columns, [chartId, resultSet])

  return {
    dataSource: dataSourceMemo,
    columns: columnsMemo,
    progress,
    totalPages,
    isLoading: MetricsMapping[chartId].disabled ? false : isLoading,
    granularity: granularityValue,
  }
}
