import { useMemo, useState } from "react"
import {
  SORTED_ITEMS_ENABLED_FOR,
  TYPE_TO_CARD_MAP,
  TYPE_TO_LABEL_MAP,
  VISUALIZATION_TABLE,
} from "../../constants"
import SortedItemsLoader from "~/ui-rtk/components/ui/charts/SortedItems/SortedItemsLoader"
import { TBrandMediaOptimizationTable } from "~/ui-rtk/pages/BrandMedia/Optimization/connect"
import { Button } from "~/ui-rtk/components/ui/controls"
import { VISUALIZATIONS_MAP, AggregationType } from "~/ui-rtk/constants/charts"
import { TCubeFilterOptions } from "~/ui-rtk/shared/types/charts"
import { DRAWER_TYPE, TDrawerConfig } from "~/ui-rtk/constants/drawer"
import useDrawer from "~/ui-rtk/hooks/drawer"

type TBrandMediaOptimizationSortedSectionProps = {
  visibleWidgets: TBrandMediaOptimizationTable[]
  dateRange: [Date, Date]
}

type TWidgetButton = {
  label: string
  slug: string
}

const WIDGET_BUTTON_SUFFIX = {
  TOP: "TOP",
  WORST: "WORST",
}

export default function BrandMediaOptimizationSortedSection({
  visibleWidgets,
  dateRange,
}: TBrandMediaOptimizationSortedSectionProps) {
  const visibleWidgetsMap = useMemo(() => {
    const vWMap: Record<string, TBrandMediaOptimizationTable> = {}

    visibleWidgets.forEach((widget: TBrandMediaOptimizationTable) => {
      vWMap[widget.widget] = widget
    })
    return vWMap
  }, [visibleWidgets])

  const { openDrawer } = useDrawer()

  const [topSortedWidget, setTopSortedWidget] = useState(
    visibleWidgets[0]?.widget,
  )
  const [topSortedDescSorting, setTopSortedDescSorting] = useState(true)

  const activeBtnSlug = useMemo(
    () =>
      `${topSortedWidget}__${topSortedDescSorting ? WIDGET_BUTTON_SUFFIX.TOP : WIDGET_BUTTON_SUFFIX.WORST}`,
    [topSortedWidget, topSortedDescSorting],
  )

  const activeWidget = useMemo(
    () => visibleWidgetsMap[topSortedWidget],
    [visibleWidgetsMap, topSortedWidget],
  )

  const chartProps = useMemo(
    () => VISUALIZATIONS_MAP[activeWidget?.widget]?.props,
    [activeWidget],
  )

  const queryPrefix = chartProps?.queryPrefix

  const sortedItemsOptions = useMemo(() => {
    const options: TWidgetButton[] = []
    Object.values(VISUALIZATION_TABLE).forEach((widgetId: string) => {
      if (SORTED_ITEMS_ENABLED_FOR.includes(widgetId)) {
        options.push({
          label: `Top ${TYPE_TO_LABEL_MAP[widgetId]}s`,
          slug: `${widgetId}__${WIDGET_BUTTON_SUFFIX.TOP}`,
        })
        options.push({
          label: `Worst ${TYPE_TO_LABEL_MAP[widgetId]}s`,
          slug: `${widgetId}__${WIDGET_BUTTON_SUFFIX.WORST}`,
        })
      }
    })
    return options
  }, [])

  const handleSetTopWorstWidget = (payload: string) => {
    const [widget, isDesc] = payload.split("__")
    setTopSortedWidget(widget)
    setTopSortedDescSorting(isDesc === WIDGET_BUTTON_SUFFIX.TOP)
  }

  const cubeFilters = useMemo(() => {
    const filters = [] as TCubeFilterOptions
    if (!filters.extraFilters) {
      filters.extraFilters = []
    }
    filters.extraFilters?.push({
      member: `${queryPrefix}.spend`,
      operator: "gte",
      values: ["100"],
    })
    return filters
  }, [queryPrefix, dateRange])

  const handleOpenDrawer = (config: Partial<TDrawerConfig>) => {
    let drawerType: DRAWER_TYPE | null = null
    let component: string

    if (Object.values(VISUALIZATION_TABLE).includes(activeWidget.widget)) {
      drawerType = DRAWER_TYPE.CampaingAdSetAdDetails
    }

    if (activeWidget.widget === VISUALIZATION_TABLE.CAMPAIGN) {
      component = "CampaignDetails"
    } else if (activeWidget.widget === VISUALIZATION_TABLE.AD_SET) {
      component = "AdSetDetails"
    } else if (activeWidget.widget === VISUALIZATION_TABLE.AD) {
      component = "AdDetails"
    } else {
      component = ""
    }

    if (drawerType) {
      openDrawer({
        ...config,
        props: {
          ...config.props,
          component,
        },
        type: drawerType,
      } as TDrawerConfig)
    }
  }

  return (
    <div className="sorted-section">
      <div className="join max-h-14">
        {sortedItemsOptions.map((btn, idx) => (
          <Button
            variant={{ variant: "solid", color: "blue" }}
            key={idx}
            title={btn.label}
            className={`join-item border text-3.5 px-4 py-3 rounded-md ${btn.slug === activeBtnSlug ? "bg-basic-blue" : "bg-basic-dark-blue"}`}
            onClick={() => handleSetTopWorstWidget(btn.slug)}
          >
            {btn.label}
          </Button>
        ))}
      </div>

      {SORTED_ITEMS_ENABLED_FOR.includes(activeWidget?.widget) && (
        <div className="mt-3 overflow-x-hidden">
          <SortedItemsLoader
            widget={activeWidget}
            cubeFilters={cubeFilters}
            isDesc={topSortedDescSorting}
            dateRange={dateRange}
            granularity={AggregationType.DAILY}
            sortByMetric={`${queryPrefix}.brand_value_over_spend`}
            itemComponent={TYPE_TO_CARD_MAP[activeWidget.widget]}
            onOpenDrawer={handleOpenDrawer}
          />
        </div>
      )}
    </div>
  )
}
