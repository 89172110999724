import { safeJSONParse } from "../utils/safeJsonParse"

export enum SessionStorageKeys {
  HIDE_TOP_10_HERO_ADS = "hide_top_10_hero_ads",
  HIDE_TOP_10_BRAND_ADS = "hide_top_10_brand_ads",
}

export function setSessionStorageValue<T>(
  key: SessionStorageKeys,
  value: T,
): void {
  if (typeof window !== "undefined") {
    window.sessionStorage.setItem(key, JSON.stringify(value))
  } else {
    console.warn("localStorage is not available")
  }
}

export function unsetSessionStorageValue(key: SessionStorageKeys): void {
  if (typeof window !== "undefined") {
    window.sessionStorage.removeItem(key)
  } else {
    console.warn("localStorage is not available")
  }
}

export function getSessionStorageValue<T>(key: SessionStorageKeys): T | null {
  if (typeof window !== "undefined") {
    const value = window.sessionStorage.getItem(key)
    if (value === null) {
      return null
    }
    return safeJSONParse<T>(value)
  }
  return null
}

export function useSessionStorage<T>(
  key: SessionStorageKeys,
  defaultValue: T,
): [T, React.Dispatch<React.SetStateAction<T>>]
export function useSessionStorage<T>(
  key: SessionStorageKeys,
  defaultValue?: null,
): [T | null, React.Dispatch<React.SetStateAction<T | null>>]
export function useSessionStorage<T>(
  key: SessionStorageKeys,
  defaultValue: T | null = null,
): [T | null, (value: T) => void] {
  return [
    getSessionStorageValue<T>(key) ?? defaultValue,
    (val: T) => setSessionStorageValue(key, val),
  ]
}
