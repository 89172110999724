import { ElementType, useEffect, useMemo, useState } from "react"

import { H2 } from "~/ui-rtk/components/ui/typography"
import { PeriodPicker } from "~/ui-rtk/components/ui/controls"
import {
  AggregationType,
  VISUALIZATION_TYPE,
  VISUALIZATIONS_MAP,
} from "~/ui-rtk/constants/charts"
import Breadcrumbs from "~/ui-rtk/components/layout/Breadcrumbs"

import "./mind-share.scss"
import RichTooltip from "../../../ui/common/RichTooltip"
import Card from "../../../ui/common/Card"
import { InfoSvg } from "~/ui-rtk/components/ui/svg/essentials"
import Chart from "~/ui-rtk/components/ui/charts/Chart"
import { useMinMaxDateRange } from "~/ui-rtk/hooks/date-range"
import {
  TMindShareChart,
  URL_WIDGET_PARAM_KEY,
} from "~/ui-rtk/pages/BrandHealth/MindShare/connect"
import { ComposedChart } from "../../../ui/charts"
import { MultiChartItem } from "../../../ui/charts/MultiChartItem/MultiChartItem"
import { startCase } from "lodash"
import Total from "./components/Total"
import { usePeriod, DATE_FORMAT } from "~/ui-rtk/hooks/period"
import useDate from "~/ui-rtk/hooks/date"
import { useURLSearchParams } from "~/ui-rtk/hooks/url"
import { LastUpdatedChip } from "~/ui-rtk/components/ui/common/LastUpdatedChip/LastUpdatedChip"
import { useLastUpdateDate } from "~/ui-rtk/hooks/cube"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import TabsGroup from "~/ui-rtk/components/ui/controls/TabsGroup/TabsGroup"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import useCurrentCompany from "~/ui-rtk/hooks/current-company"
import { GranularityMapping } from "~/ui-rtk/constants/metrics-mapping"
import useDemoMode from "~/ui-rtk/hooks/demo-mode"

type TDashboardCharts = Record<string, TMindShareChart>

export interface TBrandsHealthMindShareDashboardProps {
  title: string
  slug: string
  periodPicker?: { visible: boolean }
  charts: TDashboardCharts
  parent: {
    slug: string
    title: string
  }
}

type TBrandHealthMindShareWidget = TMindShareChart & {
  component: ElementType
  groupValue?: string
  label?: string
  slug: string
}

const dayjs = useDate()

export default function BrandsHealthMindShareDashboard({
  title,
  charts,
  periodPicker,
  slug,
  parent,
}: TBrandsHealthMindShareDashboardProps) {
  const { params, setParams } = useURLSearchParams()
  const isPeriodPickerVisible = periodPicker?.visible ?? false
  const [index, setIndex] = useState(0)

  const currentCompany = useCurrentCompany()
  const { keywords } = (currentCompany?.metadata ?? {}) as { keywords: any }
  const categories = [...(keywords?.google?.categories ?? [])]

  const visibleTabs = useMemo(
    () =>
      Object.keys(charts).reduce(
        (
          plainCharts: Record<string, TBrandHealthMindShareWidget>,
          key: string,
        ) => {
          const chart = charts[key]
          if (chart.multi) {
            categories.forEach(category => {
              plainCharts[`${key}_${category}`.toUpperCase()] = {
                ...chart,
                slug: `${key}_${category}`,
                component: MultiChartItem,
                groupValue: category,
                label: chart.widgetLabelPrefix
                  ? `${chart.widgetLabelPrefix} ${startCase(category)}`
                  : startCase(category),
              }
            })
            return plainCharts
          }
          plainCharts[key] = {
            ...chart,
            slug: chart.id,
            component: ComposedChart,
          }
          return plainCharts
        },
        {} as Record<string, TBrandHealthMindShareWidget>,
      ),
    [charts],
  )

  const visibleTabsOptions = useMemo(
    () => Object.values(visibleTabs),
    [visibleTabs],
  )

  const defaultVisibleTab = useMemo(
    () => visibleTabsOptions[0],
    [visibleTabsOptions],
  )

  const widgetURLSearchParam =
    decodeURI(params[URL_WIDGET_PARAM_KEY]) ?? defaultVisibleTab.id

  const activeWidget = useMemo(() => {
    const widgetId = widgetURLSearchParam?.toUpperCase()

    if (widgetId && visibleTabs[widgetId]) {
      return visibleTabs[widgetId]
    }
    return defaultVisibleTab
  }, [widgetURLSearchParam, visibleTabs])

  const { dateMetric, filterMetrics } = activeWidget.lastDateChipProps

  const { lastDate, isLoading: isLastUpdateDateLoading } = useLastUpdateDate({
    dateMetric,
    filterMetrics,
  })

  const { dateRange, compareRange } = useMinMaxDateRange(
    undefined,
    new Date(lastDate ?? ""),
  )
  const { granularity, setPeriod, setComparePeriod, setGranularity } =
    usePeriod()

  const chartProps = useMemo(
    () => VISUALIZATIONS_MAP[activeWidget.widget]?.props,
    [activeWidget],
  )

  const cubeQueryParams = useMemo(
    () => ({
      [activeWidget.widget]: {
        granularity: GranularityMapping.MONTHLY,
      },
    }),
    [activeWidget],
  )

  const handleChangePeriods = (
    dateRange: [Date, Date],
    compareDateRange: [Date, Date] | null,
  ) => {
    setPeriod(
      dayjs(dateRange[0]).format(DATE_FORMAT),
      dayjs(dateRange[1]).format(DATE_FORMAT),
    )

    if (compareDateRange && compareDateRange.length > 0) {
      setComparePeriod(
        dayjs(compareDateRange[0]).format(DATE_FORMAT),
        dayjs(compareDateRange[1]).format(DATE_FORMAT),
      )
    } else {
      setComparePeriod()
    }
  }

  const handleSetDataAggType = (aggType: AggregationType) => {
    setGranularity(aggType)
  }

  const handleSetWidget = (idx: number) => {
    setParams({
      [URL_WIDGET_PARAM_KEY]: encodeURI(
        visibleTabsOptions[idx].slug.toLocaleLowerCase(),
      ),
    })
  }

  const { isEnabled: isDemoEnabled } = useDemoMode()

  const findActiveIndex = () =>
    visibleTabsOptions.findIndex(({ slug }) => activeWidget?.slug === slug)

  useEffect(() => {
    setIndex(findActiveIndex())
  }, [activeWidget])

  return (
    <>
      <div className="flex justify-between">
        <div className="text-start">
          <H2>
            <Breadcrumbs
              items={[
                {
                  slug: parent.slug,
                  name: parent.title,
                },
                {
                  slug,
                  name: title,
                },
              ]}
            />
          </H2>
        </div>
        <div className="flex justify-end align-center gap-2">
          <div className="flex align-center">
            <LastUpdatedChip
              lastDate={lastDate}
              isLoading={isLastUpdateDateLoading}
            >
              Max date available in Page:
            </LastUpdatedChip>
          </div>
          <PeriodPicker
            dateRange={dateRange}
            compareRange={compareRange}
            opacity={isPeriodPickerVisible ? 1 : 0.5}
            onChange={handleChangePeriods}
            comparisonEnabled={
              VISUALIZATIONS_MAP[activeWidget.widget].props.comparisonEnabled ??
              false
            }
          />
        </div>
      </div>
      <div>
        <p className="flex items-center justify-start gap-2 text-base text-white/75 mt-6 mb-4 border border-basic-blue bg-basic-dark-blue rounded-lg p-2">
          <InformationCircleIcon className="size-4 in" />
          <span>
            Google Keywords data updates on our platform occur monthly. It may
            take 4-6 weeks for new data to be reflected, based on Google's
            release schedule.
          </span>
        </p>
      </div>
      <div className="ui-rtk-overview-dashboard border-b border-basic-blue">
        <TabsGroup
          options={visibleTabsOptions}
          onChange={handleSetWidget}
          index={index}
        >
          {(tab, idx) => (
            <div
              className={cn("flex items-center gap-2", {
                "blur-md":
                  isDemoEnabled &&
                  !["TOTAL", "BRAND_SHARE"].includes(
                    visibleTabsOptions[idx].slug,
                  ),
              })}
            >
              <span>{tab.label}</span>
              {idx === index &&
                VISUALIZATIONS_MAP[activeWidget.widget].description && (
                  <RichTooltip
                    content={
                      <Card
                        rounded
                        className="min-w-128 max-w-180 whitespace-normal text-start leading-5"
                      >
                        {VISUALIZATIONS_MAP[activeWidget.widget].description}
                      </Card>
                    }
                  >
                    <InfoSvg size={16} fill="white" />
                  </RichTooltip>
                )}
            </div>
          )}
        </TabsGroup>
      </div>
      <div className="mt-3">
        <Chart
          chartId={activeWidget.widget}
          className="h-[calc(100vh-300px)]"
          widgetType={VISUALIZATION_TYPE.ComposedChart}
          component={activeWidget.component}
          dateRange={dateRange}
          groupValue={activeWidget.groupValue}
          compareRange={compareRange ?? undefined}
          granularity={granularity}
          hideAggSelector={true}
          setDataAggType={handleSetDataAggType}
          totalComponent={Total}
          hideTooltipTitle={true}
          cubeQueryParams={cubeQueryParams}
          {...chartProps}
        />
      </div>
    </>
  )
}
