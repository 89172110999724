import { AggregationType, VISUALIZATION_TYPE } from "~/ui-rtk/constants/charts"
import ChartSkeleton from "../ChartSkeleton"
import { useEffect } from "react"
import useConnect from "./connect"
import { TableSkeleton } from "../TableSkeleton"
import { SortingState } from "@tanstack/react-table"
import { TCubeFilterOptions } from "~/ui-rtk/shared/types/charts"

type TChartDataLoaderProps = {
  chartId: string
  dateRange: [Date, Date]
  granularity: AggregationType
  className?: string
  widgetType: VISUALIZATION_TYPE
  setCacheItem: (
    chartId: string,
    dateRange: [Date, Date],
    granularity: AggregationType,
    data: {
      dataSource: Record<string, any>[] | Record<string, any>[][]
      columns: Record<string, any>
      totalPages?: number | null
    },
    page: number,
    itemsPerPage: number,
    cubeFilters?: TCubeFilterOptions,
    sorting?: SortingState,
    cubeQueryParams?: Record<string, string>,
  ) => void
  id?: string
  page: number
  itemsPerPage: number
  enablePagination?: boolean
  cubeFilters?: TCubeFilterOptions
  sorting?: SortingState
  disableGranularity?: boolean
  prefixEnabled?: boolean
  cubeQueryParams?: Record<string, string>
}

export default function ChartDataLoader({
  chartId,
  dateRange,
  className,
  granularity,
  setCacheItem,
  widgetType,
  page,
  itemsPerPage,
  cubeFilters,
  sorting,
  enablePagination = false,
  disableGranularity = false,
  prefixEnabled = false,
  cubeQueryParams,
}: TChartDataLoaderProps) {
  const { dataSource, columns, totalPages } = useConnect({
    chartId,
    granularity,
    dateRange,
    page,
    itemsPerPage,
    cubeFilters,
    sorting,
    enablePagination,
    disableGranularity,
    cubeQueryParams,
    prefixEnabled,
  })

  useEffect(() => {
    if (dataSource && columns) {
      setCacheItem(
        chartId,
        dateRange,
        granularity,
        {
          dataSource,
          columns,
          totalPages,
        },
        page,
        itemsPerPage,
        cubeFilters,
        sorting,
        cubeQueryParams,
      )
    }
  }, [
    dataSource,
    columns,
    chartId,
    dateRange,
    granularity,
    setCacheItem,
    page,
    totalPages,
    itemsPerPage,
    cubeFilters,
    sorting,
    cubeQueryParams,
  ])

  return widgetType === VISUALIZATION_TYPE.TableVisualization ? (
    <TableSkeleton />
  ) : (
    <ChartSkeleton className={className} />
  )
}
