import { XMarkIcon } from "@heroicons/react/20/solid"
import {
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline"
import { MouseEventHandler, MouseEvent } from "react"
import { Link } from "react-router-dom"
import { Card } from "~/ui-rtk/components/ui/common"
import { type TSetupGuideCard } from "~/ui-rtk/hooks/setup-guide"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"

type TSetupGuideSectionCardProps = {
  card: TSetupGuideCard
  onCancel: (card: TSetupGuideCard) => void
}

export default function SetupGuideSectionCard({
  card,
  onCancel,
}: TSetupGuideSectionCardProps) {
  const navigate = useStableNavigate()
  const handleCancel: MouseEventHandler<SVGElement> = (e: MouseEvent) => {
    e.stopPropagation()
    onCancel(card)
  }

  const handleOpen = () => {
    navigate(card.linkTo)
  }

  const renderCardDetails = () => {
    if (card.isCompleted) {
      return (
        <div className="text-success flex gap-1 items-start">
          <CheckCircleIcon className="w-4 h-4" />
          <span>Complete</span>
        </div>
      )
    }
    if (card.isActionNeeded) {
      return (
        <div className="flex flex-col gap-1 items-start">
          <div>Reconnect needed for: {card.statusDetails?.reconnectNeeded}</div>
          <div className="text-error">Action Needed</div>
        </div>
      )
    }
    if (card.inProgress) {
      return (
        <div className="flex flex-col gap-1 items-start">
          <div>
            Essential: {card.statusDetails?.essentialPercentage}% / Total: $
            {card.statusDetails?.totalPercentage}
          </div>
          <div className="text-warning">In Progress</div>
        </div>
      )
    }

    return <div className="text-white/50">Not Started</div>
  }

  return (
    <div className="setup-guide-section-card h-full">
      <Card
        rounded
        onClick={handleOpen}
        className="p-4 w-75 text-base font-semibold text-white border bg-basic-blue/20 border-basic-blue h-full flex flex-col justify-between"
      >
        <div>
          <div className="flex justify-between items-center">
            <div className="grow">{card.label}</div>
            <div className="grow-0">
              <XMarkIcon
                onClick={handleCancel}
                className="text-white w-6 h-6 cursor-pointer"
              />
            </div>
          </div>
          <div className="mt-3 text-white/50">{card.description}</div>
        </div>
        <div className="mt-6 flex justify-between items-center">
          <div className="flex gap-1">{renderCardDetails()}</div>
          <div>
            <Link to={card.linkTo}>
              <ArrowTopRightOnSquareIcon className="text-white w-4 h-4 cursor-pointer" />
            </Link>
          </div>
        </div>
      </Card>
    </div>
  )
}
