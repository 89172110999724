import * as yup from "yup"
import { useCallback } from "react"
import {
  useCompanyConnectorControllerAuthenticateCompanyConnectorMutation as useCompanyConnectorAuthMutation,
  useGuestCompanyConnectorControllerAuthenticateMutation as useGuestCompanyConnectorAuthMutation,
} from "~/ui-rtk/api/companyConnectorApi"
import { GuestTokenParam } from "../types"

export const validationSchema = yup.object().shape({
  igHandles: yup
    .array()
    .of(yup.string().min(3, "Must be at least 3 characters"))
    .required("Instagram Handles are required!"),
  igHashTags: yup
    .array()
    .of(yup.string().min(3, "Must be at least 3 characters"))
    .required("Instagram Hashtags are required!"),
  tiktokHandles: yup
    .array()
    .of(yup.string().min(3, "Must be at least 3 characters"))
    .required("TikTok Handles are required!"),
  tiktokHashTags: yup
    .array()
    .of(yup.string().min(3, "Must be at least 3 characters"))
    .required("TikTok Hashtags are required!"),
})

export type FormValues = yup.InferType<typeof validationSchema>

const initialValues: FormValues = {
  igHandles: [],
  igHashTags: [],
  tiktokHandles: [],
  tiktokHashTags: [],
}

type Params = {
  authCode: string
  companyConnectorId: string
  archive: {
    igHandles: string[]
    igHashtags: string[]
    tiktokHandles: string[]
    tiktokHashtags: string[]
  }
} & GuestTokenParam

export const useConnect = () => {
  const [authAsync, { isLoading: isAuthLoading }] =
    useCompanyConnectorAuthMutation()
  const [guestAuthAsync, { isLoading: isGuestAuthLoading }] =
    useGuestCompanyConnectorAuthMutation()

  const authenticate = useCallback(async (params: Params) => {
    const { isGuest, companyConnectorId, authCode, archive } = params
    if (isGuest) {
      await guestAuthAsync({
        id: companyConnectorId,
        token: params.token,
        authenticateCompanyConnectorDto: {
          authCode,
          extraMetadata: { archive },
        },
      }).unwrap()
    } else {
      await authAsync({
        id: companyConnectorId,
        authenticateCompanyConnectorDto: {
          authCode,
          extraMetadata: { archive },
        },
      }).unwrap()
    }
  }, [])

  const isLoading = isAuthLoading || isGuestAuthLoading

  return {
    authenticate,
    initialValues,
    validationSchema,
    isLoading,
  }
}
