import { useCallback } from "react"
import { useCompanyConnectorControllerGetCompanyConnectorsQuery } from "~/ui-rtk/api/companyConnectorApi"
import { ConnectionStatus } from "../api/types"

export default function useConnectedServices() {
  const {
    data: connectedServices,
    refetch: refetchConnectors,
    isError: isGetCompanyConnectorsError,
    isLoading: isGetCompanyConnectorsLoading,
  } = useCompanyConnectorControllerGetCompanyConnectorsQuery()

  const getCompanyConnectorByService = useCallback(
    (service: string) => {
      const companyConnector = connectedServices?.find(
        s => s.serviceName === service,
      )
      return companyConnector
    },
    [connectedServices],
  )

  return {
    connectedServices,
    refetchConnectors,
    isGetCompanyConnectorsError,
    isGetCompanyConnectorsLoading,
    getCompanyConnectorByService,
  }
}

export function useCheckServiceIsConnected(service: string) {
  const { getCompanyConnectorByService } = useConnectedServices()

  const connector = getCompanyConnectorByService(service)

  if (!connector) {
    return false
  }

  return connector.status === ConnectionStatus.CONNECTED
}

export function useCheckServiceWasConnected(service: string) {
  const { getCompanyConnectorByService } = useConnectedServices()

  const connector = getCompanyConnectorByService(service)

  if (!connector) {
    return false
  }

  return [
    ConnectionStatus.CONNECTED,
    ConnectionStatus.PENDING_RECONNECT,
    ConnectionStatus.PENDING_SCHEMA_UPDATE,
  ].includes(connector.status)
}
