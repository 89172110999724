import {
  DEP_VAR_TO_BASELINE_REVENUE_MAP,
  BASELINE_REVENUE_SHOPIFY,
  BASELINE_REVENUE_SHOPIFY_ALT,
  BASELINE_REVENUE_GA,
  BASELINE_REVENUE_GA_ALT,
} from "../constants/revenue"
import useDepVar from "./dep-var.ts"

export const DEFAULT_BASELINE_REVENUE_METRIC = "ga_baseline_revenue"

export const GA_AMAZON_REVENUE = "ga_amazon_baseline_revenue"
export const GA_MULTI_AMAZON_REVENUE =
  "ga_multi_property_amazon_baseline_revenue"
export const SHOPIFY_AMAZON_REVENUE = "shopify_amazon_baseline_revenue"
export const SHOPIFY_SUBSCRIPTION_AMAZON_REVENUE =
  "shopify_plus_subscription_amazon_baseline_revenue"

export default function useBaselineRevenueMetric() {
  const { amazonEnabled, depVar } = useDepVar()

  let baselineRevenue = DEFAULT_BASELINE_REVENUE_METRIC

  if (depVar) {
    if (DEP_VAR_TO_BASELINE_REVENUE_MAP[depVar]) {
      baselineRevenue = DEP_VAR_TO_BASELINE_REVENUE_MAP[depVar]
    }
  }

  if (amazonEnabled) {
    switch (baselineRevenue) {
      case BASELINE_REVENUE_GA:
        baselineRevenue = GA_AMAZON_REVENUE
        break
      case BASELINE_REVENUE_GA_ALT:
        baselineRevenue = GA_MULTI_AMAZON_REVENUE
        break
      case BASELINE_REVENUE_SHOPIFY:
        baselineRevenue = SHOPIFY_AMAZON_REVENUE
        break
      case BASELINE_REVENUE_SHOPIFY_ALT:
        baselineRevenue = SHOPIFY_SUBSCRIPTION_AMAZON_REVENUE
        break
    }
  }

  return baselineRevenue
}
