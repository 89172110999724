import useDate from "~/ui-rtk/hooks/date"

const dayjs = useDate()

const today = dayjs()
const threeDaysAgo = today.subtract(3, "day").toDate()
const weekAgo = today.subtract(7, "day").toDate()
const twoWeeksAgo = today.subtract(14, "day").toDate()

export const TABS_OPTIONS = [
  {
    label: "3 Days",
    value: threeDaysAgo,
    diff: 3,
  },
  {
    label: "7 Days",
    value: weekAgo,
    diff: 7,
  },
  {
    label: "14 Days",
    value: twoWeeksAgo,
    diff: 14,
  },
]
