import { useMemo, useState } from "react"
import { H3 } from "~/ui-rtk/components/ui/typography"
import useLearningZone, {
  LEARNING_ZONE_ARTICLE_TYPES,
} from "~/ui-rtk/hooks/learning-zone"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import LearningZoneSectionCard, {
  TLearningZoneCard,
} from "./LearningZoneSectionCard"
import TabsGroup from "~/ui-rtk/components/ui/controls/TabsGroup/TabsGroup"

type TLearningZoneSectionProps = {
  className?: string
}

const ALL_CONTENT_ID = "all-content-id"

const TABS_OPTIONS = [
  {
    label: "All Content",
    value: ALL_CONTENT_ID,
  },
  {
    label: "Product",
    value: LEARNING_ZONE_ARTICLE_TYPES.PRODUCT,
  },
  {
    label: "Brand",
    value: LEARNING_ZONE_ARTICLE_TYPES.BRAND,
  },
]

export default function LearningZoneSection({
  className,
}: TLearningZoneSectionProps) {
  const { cards, isHidden } = useLearningZone()
  const [tabIndex, handleSetTabIndex] = useState(0)

  const activeTabCards = useMemo(() => {
    const tabId = TABS_OPTIONS[tabIndex]?.value
    if (tabId === ALL_CONTENT_ID) {
      return cards
    }
    return cards.filter(card => card.type === tabId)
  }, [tabIndex])

  return isHidden ? null : (
    <div>
      <div className="flex justify-start items-center gap-3">
        <H3>Learning Zone</H3>
      </div>
      <div
        className={cn(
          className,
          "join max-h-14 border-b border-b-basic-blue w-full",
        )}
      >
        <TabsGroup
          options={TABS_OPTIONS}
          onChange={handleSetTabIndex}
          index={tabIndex}
        />
      </div>
      <div className="mt-4 p-0 flex gap-3 overflow-x-auto">
        {activeTabCards.map((card: TLearningZoneCard, idx: number) => (
          <LearningZoneSectionCard key={idx} card={card} />
        ))}
      </div>
    </div>
  )
}
