import useCurrentCompany from "./current-company"
import i6672876 from "~/ui-rtk-public/assets/img/lz/6672876.jpg?url"
import i1455856 from "~/ui-rtk-public/assets/img/lz/1455856.jpg?url"
import i9211885 from "~/ui-rtk-public/assets/img/lz/9211885.jpg?url"
import i1833195 from "~/ui-rtk-public/assets/img/lz/1833195.jpg?url"
import i7026422 from "~/ui-rtk-public/assets/img/lz/7026422.jpg?url"

export const LEARNING_ZONE_ARTICLE_TYPES = {
  PRODUCT: "product",
  BRAND: "brand",
}

type TLearningZoneArtcileContentType = "video" | "article"

const learningZoneArticles = [
  {
    title: "How to use our Paid Media Optimization Dashboard",
    type: LEARNING_ZONE_ARTICLE_TYPES.PRODUCT,
    contentType: "video" as TLearningZoneArtcileContentType,
    url: "https://feedback.marathondataco.com/dashboard/articles/6672876",
    id: "6672876",
    img: i6672876,
  },
  {
    title: "How Marathon Works: A Deep Dive Into Our Brand-Mix-Model",
    type: LEARNING_ZONE_ARTICLE_TYPES.PRODUCT,
    contentType: "video" as TLearningZoneArtcileContentType,
    url: "https://feedback.marathondataco.com/dashboard/articles/1455856",
    id: "1455856",
    img: i1455856,
  },
  {
    title: "What does 180 day brand value actually mean??",
    type: LEARNING_ZONE_ARTICLE_TYPES.PRODUCT,
    contentType: "video" as TLearningZoneArtcileContentType,
    url: "https://feedback.marathondataco.com/dashboard/articles/9211885",
    id: "9211885",
    img: i9211885,
  },
  {
    title:
      "How to Measure the Long Term Brand Revenue Impact from TV Advertising for Modern Brands in Marathon",
    type: LEARNING_ZONE_ARTICLE_TYPES.PRODUCT,
    contentType: "video" as TLearningZoneArtcileContentType,
    url: "https://feedback.marathondataco.com/dashboard/articles/1833195",
    id: "1833195",
    img: i1833195,
  },
  {
    title:
      "It's not Brand OR Monetization - it's Brand AND Monetization (1 w/o the Other is a Recipe for Disaster)",
    type: LEARNING_ZONE_ARTICLE_TYPES.PRODUCT,
    contentType: "video" as TLearningZoneArtcileContentType,
    url: "https://feedback.marathondataco.com/dashboard/articles/7026422",
    id: "7026422",
    img: i7026422,
  },
  {
    title: "CMO dialogues - I can hit any ROAS you want...",
    type: LEARNING_ZONE_ARTICLE_TYPES.BRAND,
    contentType: "article" as TLearningZoneArtcileContentType,
    url: "https://feedback.marathondataco.com/dashboard/articles/6396899",
    id: "6396899",
  },
  {
    title: "Warren Buffett on Brand",
    type: LEARNING_ZONE_ARTICLE_TYPES.BRAND,
    contentType: "article" as TLearningZoneArtcileContentType,
    url: "https://feedback.marathondataco.com/dashboard/articles/3709725",
    id: "3709725",
  },
]

export default function useLearningZone() {
  const currentCompany = useCurrentCompany()
  const metadata = currentCompany?.metadata ?? {}
  const isHidden = metadata?.homePage?.learningZoneSection?.isHidden ?? false

  return {
    cards: learningZoneArticles,
    isHidden,
  }
}
