// Connectors Logo
export const connectorIcons = {
  amazonAds: "/assets/img/connectors/amazon_ads.svg",
  amazonSellingPartner: "/assets/img/connectors/amazon_selling_partner.svg",
  attentive: "/assets/img/connectors/attentive.png",
  googleKeywordSearch: "/assets/img/connectors/google_keyword_search.png",
  instagram: "/assets/img/connectors/instagram.png",
  instagramInsights: "/assets/img/connectors/instagram_insights.png",
  sephora: "/assets/img/connectors/sephora.png",
  sps: "/assets/img/connectors/sps.svg",
  shopify: "/assets/img/connectors/shopify.png",
  target: "/assets/img/connectors/target.svg",
  yotpo: "/assets/img/connectors/yotpo.png",
  tiktok: "/assets/img/connectors/tiktok.svg",
  facebookAds: "/assets/img/connectors/facebook_ads.png",
  facebookPages: "/assets/img/connectors/facebook_pages.png",
  googleAds: "/assets/img/connectors/google_ads.png",
  googleAnalytics: "/assets/img/connectors/ga.png",
  googleAnalytics4: "/assets/img/connectors/ga.png",
  klavio: "/assets/img/connectors/klavio.png",
  youtube: "/assets/img/connectors/youtube.svg",
  archive: "/assets/img/connectors/archive.ico",
}

export const formImages = {
  yotpoCredentials: "/assets/img/yotpo_credentials.png",
}

export const defaultImages = {
  noImage: "/assets/img/no-image.svg",
}
