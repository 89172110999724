import { useEffect, useMemo } from "react"

import useSources from "~/ui-rtk/hooks/sources"
import {
  Button,
  MultiSelect,
  PeriodPicker,
} from "~/ui-rtk/components/ui/controls"
import {
  AggregationType,
  VISUALIZATION_TYPE,
  VISUALIZATIONS_MAP,
} from "~/ui-rtk/constants/charts"

import {
  type TBrandMediaOptimizationTableId,
  type TBrandMediaOptimizationTable,
} from "~/ui-rtk/pages/BrandMedia/Optimization/connect"

import { Breadcrumbs } from "./components"
import { Chart, TableVisualization } from "~/ui-rtk/components/ui/charts"

import { BRAND_HEALTH_OVERVIEW_SOURCES_TO_BRAND_MEDIA_OPTIMIZATIONS_SOURCES_MAP } from "~/ui-rtk/constants/brand-media"

import type { TMultiSelectOption } from "~/ui-rtk/components/ui/controls/MultiSelect"
import type {
  TableVisualizationColumn,
  TColumnFiltersState,
} from "~/ui-rtk/constants/table-visualization-types"
import { useMinMaxDateRange } from "~/ui-rtk/hooks/date-range"
import useDrawer from "~/ui-rtk/hooks/drawer"
import { DRAWER_TYPE, TDrawerConfig } from "~/ui-rtk/constants/drawer"
import { TCubeFilterOptions } from "~/ui-rtk/shared/types/charts"
import {
  MEDIA_CHANNEL_TO_SOURCE_ID_MAPPING,
  SOURCE_ID_TO_MEDIA_CHANNEL_MAPPING,
  TBrandMediaDashboardCharts,
  URL_PARAM_TO_WIDGET_MAP,
  VISUALIZATION_TABLE,
} from "./constants"
import {
  URL_ADSET_ID_PARAM_KEY,
  URL_CAMPAIGN_ID_PARAM_KEY,
  URL_WIDGET_PARAM_KEY,
} from "~/ui-rtk/constants/url-params"
import BrandMediaOptimizationSortedSection from "./components/SortedSection/SortedSection"
import { Link } from "react-router-dom"
import { SOURCES_MAP } from "~/ui-rtk/constants/sources"
import { usePeriod, DATE_FORMAT } from "~/ui-rtk/hooks/period"
import useDate from "~/ui-rtk/hooks/date"

import { useLastUpdateDate } from "~/ui-rtk/hooks/cube"
import { useURLSearchParams } from "~/ui-rtk/hooks/url"
import { LastUpdatedChip } from "~/ui-rtk/components/ui/common/LastUpdatedChip/LastUpdatedChip"
import useUrlColumnFilters from "~/ui-rtk/hooks/url-column-filters"
import useUrlColumnKeys from "~/ui-rtk/hooks/url-column-keys"
import useUrlSourceKeys from "~/ui-rtk/hooks/url-source-keys"
import useDepVar from "~/ui-rtk/hooks/dep-var"
import { convertToCubeFilters } from "~/ui-rtk/utils/cube"
import AmazonToggle from "~/ui-rtk/components/ui/controls/AmazonToggle/AmazonToggle"

export interface TBrandMediaDashboardProps {
  title: string
  slug: string
  periodPicker?: { visible: boolean }
  items: TBrandMediaDashboardCharts
  parent: {
    slug: string
    title: string
  }
}
const dayjs = useDate()

const maxDateRestriction = dayjs().toDate()

const BEST_WORST_ENABLED = false
const BRAND_MEDIA_ID = "brand_media"

export default function BrandMediaOptimizationDashboard({
  title,
  items,
  slug,
  parent,
}: TBrandMediaDashboardProps) {
  const { params, setParams } = useURLSearchParams()
  const { sources } = useSources()
  const { setPeriod } = usePeriod()
  const { openDrawer, closeDrawer } = useDrawer()

  const bestWorstEnabled = BEST_WORST_ENABLED || params.enable_best_worst

  const { amazonEnabled, toggleAmazon, ...depVarConfig } = useDepVar()

  const visibleWidgets: TBrandMediaDashboardCharts = useMemo(
    (): TBrandMediaDashboardCharts =>
      Object.keys(items)
        .filter(chartId => {
          const { requiredSources } =
            items[chartId as unknown as TBrandMediaOptimizationTableId]
          return (
            !requiredSources?.length ||
            requiredSources?.some((source: string) => sources?.has(source))
          )
        })
        .reduce(
          (
            filteredCharts: Record<
              TBrandMediaOptimizationTableId,
              TBrandMediaOptimizationTable
            >,
            chartId: string,
          ) => {
            filteredCharts[chartId as TBrandMediaOptimizationTableId] =
              items[chartId as unknown as TBrandMediaOptimizationTableId]
            return filteredCharts
          },
          {} as Record<
            TBrandMediaOptimizationTableId,
            TBrandMediaOptimizationTable
          >,
        ),
    [items, sources],
  )

  const defaultVisibleChart = useMemo(
    () =>
      visibleWidgets[
        Object.keys(
          visibleWidgets,
        )[0] as unknown as TBrandMediaOptimizationTableId
      ],
    [visibleWidgets],
  )
  const widgetURLSearchParam =
    params[URL_WIDGET_PARAM_KEY] ?? defaultVisibleChart?.id
  const campaignIdFromUrl = params[URL_CAMPAIGN_ID_PARAM_KEY]
  const adsetIdFromUrl = params[URL_ADSET_ID_PARAM_KEY]

  const activeWidget = useMemo(
    () =>
      widgetURLSearchParam && URL_PARAM_TO_WIDGET_MAP[widgetURLSearchParam]
        ? visibleWidgets[
            widgetURLSearchParam.toUpperCase() as TBrandMediaOptimizationTableId
          ]
        : defaultVisibleChart,
    [widgetURLSearchParam],
  )

  const { dateMetric, filterMetrics } = activeWidget?.lastDateChipProps ?? {}

  const { lastDate, isLoading: isLastUpdateDateLoading } = useLastUpdateDate({
    dateMetric,
    filterMetrics,
  })

  const { dateRange } = useMinMaxDateRange(
    undefined,
    lastDate ? new Date(lastDate) : maxDateRestriction,
    AggregationType.DAILY,
    true,
  )

  const lastDateRestriction = useMemo(
    () => (lastDate ? new Date(lastDate) : maxDateRestriction),
    [lastDate, maxDateRestriction],
  )

  const querySelectedSource = params.selected_sources
  const initialSources = useMemo(() => {
    if (querySelectedSource) {
      const brandMediaSources = querySelectedSource
        .split(",")
        .map(
          (sourceId: string) =>
            BRAND_HEALTH_OVERVIEW_SOURCES_TO_BRAND_MEDIA_OPTIMIZATIONS_SOURCES_MAP[
              sourceId
            ],
        )
      return [...new Set(brandMediaSources)].filter(source =>
        activeWidget?.requiredSources?.includes(source),
      )
    }
    return (activeWidget?.requiredSources || []).map(
      (sourceId: string) =>
        BRAND_HEALTH_OVERVIEW_SOURCES_TO_BRAND_MEDIA_OPTIMIZATIONS_SOURCES_MAP[
          sourceId
        ],
    )
  }, [querySelectedSource, activeWidget])

  const chartProps = useMemo(
    () => VISUALIZATIONS_MAP[activeWidget?.widget]?.props,
    [activeWidget],
  )

  const queryPrefix = useMemo(() => chartProps?.queryPrefix, [chartProps])

  const defaultColumnKeys = useMemo(() => {
    const props = VISUALIZATIONS_MAP[activeWidget?.widget]?.props
    return props?.columnConfig.reduce(
      (
        acc: string[],
        column: TableVisualizationColumn & { disabled: boolean },
      ) => {
        if (!column.disabled) {
          acc.push(column.key)
        }
        return acc
      },
      [],
    )
  }, [activeWidget])

  const querySortBy = params.sort_by
  const sortBy = useMemo(
    () =>
      querySortBy
        ? `${queryPrefix}.${querySortBy}`
        : `${queryPrefix}.${chartProps?.sortBy}`,
    [querySortBy, queryPrefix, chartProps],
  )
  const chartPropsSorting = useMemo(
    () =>
      sortBy
        ? [
            {
              id: sortBy,
              desc: true,
            },
          ]
        : [],
    [chartProps],
  )

  const { sourceKeys, setSourceKeys } = useUrlSourceKeys(
    BRAND_MEDIA_ID,
    initialSources,
  )
  const { columnFilters, setColumnFilters } =
    useUrlColumnFilters(BRAND_MEDIA_ID)
  const { columnKeys, setColumnKeys } = useUrlColumnKeys(
    BRAND_MEDIA_ID,
    defaultColumnKeys,
  )

  const transformedSourceKeys = useMemo(
    () =>
      sourceKeys.map(
        sourceKey =>
          MEDIA_CHANNEL_TO_SOURCE_ID_MAPPING[
            sourceKey.toLowerCase()
          ]?.toUpperCase() ?? sourceKey,
      ),
    [sourceKeys],
  )

  const columnOptions = useMemo(() => {
    const options = chartProps?.columnConfig.map(
      (column: TableVisualizationColumn) =>
        ({
          id: column.key,
          label: column.header,
        }) as TMultiSelectOption,
    )

    return options || []
  }, [chartProps])

  const sourcesOptions = useMemo(() => {
    if (!activeWidget?.requiredSources) {
      return []
    }

    const options = activeWidget.requiredSources
      .filter(sourceId => sources.has(sourceId))
      .map(sourceId => {
        const source = sources.get(sourceId)

        const fakeSource =
          SOURCES_MAP[
            SOURCE_ID_TO_MEDIA_CHANNEL_MAPPING[source?.id ?? ""].toUpperCase()
          ]
        return {
          id: source?.id ?? "",
          label: fakeSource?.name ?? "",
        }
      })

    return options
  }, [activeWidget, sources])

  const cubeFilters = useMemo(() => {
    const filters: Record<string, string | string[] | undefined | null> = {
      campaign_id: [
        VISUALIZATION_TABLE.AD_SET,
        VISUALIZATION_TABLE.AD,
      ].includes(activeWidget?.widget)
        ? params[URL_CAMPAIGN_ID_PARAM_KEY]
        : undefined,
      adset_id:
        activeWidget?.widget === VISUALIZATION_TABLE.AD
          ? params[URL_ADSET_ID_PARAM_KEY]
          : undefined,
      dep_var: depVarConfig.depVar,
      dep_var_scaler: depVarConfig.depVarScaler,
    }

    const baseQuery = convertToCubeFilters(queryPrefix, filters)

    return {
      and: [
        ...baseQuery,
        {
          or: sourceKeys.map((sourceId: string) => ({
            member: `${queryPrefix}.media_channel`,
            operator: "equals",
            values: [SOURCE_ID_TO_MEDIA_CHANNEL_MAPPING[sourceId] ?? sourceId],
          })),
        },
      ],
    } as TCubeFilterOptions
  }, [sourceKeys, queryPrefix, dateRange, params, activeWidget, depVarConfig])

  useEffect(() => {
    if (campaignIdFromUrl) {
      const hasCampaignFilter = columnFilters.find(
        filter =>
          filter.id === URL_CAMPAIGN_ID_PARAM_KEY &&
          filter.operator === "contains" &&
          filter.value === campaignIdFromUrl,
      )
      if (hasCampaignFilter) {
        setParams({
          [URL_CAMPAIGN_ID_PARAM_KEY]: undefined,
        })
        closeDrawer()
      } else {
        setColumnFilters((prevState: TColumnFiltersState | undefined) => {
          const newState = (
            prevState ? [...prevState] : []
          ) as TColumnFiltersState
          const filterIdx = newState.findIndex(
            filter =>
              filter.id === URL_CAMPAIGN_ID_PARAM_KEY &&
              filter.operator === "contains",
          )

          if (filterIdx >= 0) {
            newState[filterIdx].value = campaignIdFromUrl
          } else {
            newState.push({
              id: URL_CAMPAIGN_ID_PARAM_KEY,
              variant: "text",
              operator: "contains",
              value: campaignIdFromUrl,
            })
          }
          return newState
        })
      }
    }
  }, [campaignIdFromUrl, columnFilters, setColumnFilters])

  useEffect(() => {
    if (adsetIdFromUrl) {
      const hasAdsetFilter = columnFilters.find(
        filter =>
          filter.id === URL_ADSET_ID_PARAM_KEY &&
          filter.operator === "contains" &&
          filter.value === adsetIdFromUrl,
      )
      if (hasAdsetFilter) {
        setParams({
          [URL_ADSET_ID_PARAM_KEY]: undefined,
        })
        closeDrawer()
      } else {
        setColumnFilters((prevState: TColumnFiltersState | undefined) => {
          const newState = (
            prevState ? [...prevState] : []
          ) as TColumnFiltersState
          const filterIdx = newState.findIndex(
            filter =>
              filter.id === URL_ADSET_ID_PARAM_KEY &&
              filter.operator === "contains",
          )

          if (filterIdx >= 0) {
            newState[filterIdx].value = adsetIdFromUrl
          } else {
            newState.push({
              id: URL_ADSET_ID_PARAM_KEY,
              variant: "text",
              operator: "contains",
              value: adsetIdFromUrl,
            })
          }
          return newState
        })
      }
    }
  }, [adsetIdFromUrl, columnFilters, setColumnFilters])

  const changePeriod = (dateRange: [Date, Date]) => {
    const [start, end] = dateRange.map(date => dayjs(date))

    void setPeriod(start.format(DATE_FORMAT), end.format(DATE_FORMAT))
  }

  const handleSetWidget = (widgetId: string) => {
    setParams({
      [URL_WIDGET_PARAM_KEY]: widgetId.toLowerCase(),
      [URL_CAMPAIGN_ID_PARAM_KEY]: undefined,
      [URL_ADSET_ID_PARAM_KEY]: undefined,
    })
    closeDrawer()
  }
  const handleOpenDrawer = (config: Partial<TDrawerConfig>) => {
    let drawerType: DRAWER_TYPE | null = null
    let component: string

    if (Object.values(VISUALIZATION_TABLE).includes(activeWidget?.widget)) {
      drawerType = DRAWER_TYPE.CampaingAdSetAdDetails
    }

    if (activeWidget?.widget === VISUALIZATION_TABLE.CAMPAIGN) {
      component = "CampaignDetails"
    } else if (activeWidget?.widget === VISUALIZATION_TABLE.AD_SET) {
      component = "AdSetDetails"
    } else if (activeWidget?.widget === VISUALIZATION_TABLE.AD) {
      component = "AdDetails"
    } else {
      component = ""
    }

    if (drawerType) {
      openDrawer({
        ...config,
        props: {
          ...config.props,
          component,
        },
        type: drawerType,
      } as TDrawerConfig)
    }
  }

  return (
    <>
      <Breadcrumbs slug={slug} title={title} parent={parent} />
      {bestWorstEnabled && (
        <div className="mt-3">
          <BrandMediaOptimizationSortedSection
            visibleWidgets={Object.values(visibleWidgets)}
            dateRange={dateRange}
          />
        </div>
      )}
      <div className="flex justify-between gap-2 mt-3">
        <div className="join max-h-14">
          {Object.values(visibleWidgets).map(tab => (
            <Button
              variant={{ variant: "solid", color: "blue" }}
              key={tab.id}
              title={tab.label}
              className={`join-item border text-3.5 px-4 py-3 rounded-md ${tab.id === activeWidget?.id ? "bg-basic-blue" : "bg-basic-dark-blue"}`}
              onClick={() => handleSetWidget(tab.id)}
            >
              {tab.label}
            </Button>
          ))}
        </div>
        <div className="flex justify-end items-stretch gap-2">
          <LastUpdatedChip
            lastDate={lastDate}
            isLoading={isLastUpdateDateLoading}
          >
            Max date available in Page:
          </LastUpdatedChip>
          <MultiSelect
            label="Columns"
            options={columnOptions as TMultiSelectOption[]}
            value={columnOptions.filter((option: TMultiSelectOption) =>
              (columnKeys as string[]).includes(option.id),
            )}
            onChange={(options: TMultiSelectOption[]) =>
              setColumnKeys(options.map(({ id }) => id))
            }
          />
          <MultiSelect
            className="max-w-50"
            label="All Platforms"
            options={sourcesOptions as TMultiSelectOption[]}
            value={sourcesOptions.filter((option: TMultiSelectOption) =>
              transformedSourceKeys.includes(option.id),
            )}
            onChange={(options: TMultiSelectOption[]) =>
              setSourceKeys(
                options.map(({ id }) =>
                  SOURCE_ID_TO_MEDIA_CHANNEL_MAPPING[id].toUpperCase(),
                ),
              )
            }
          />
          <PeriodPicker
            dateRange={dateRange}
            onChange={changePeriod}
            restrictions={{
              maxDate: lastDateRestriction,
            }}
            comparisonEnabled={false}
          />
        </div>
      </div>
      <AmazonToggle amazonEnabled={amazonEnabled} toggleAmazon={toggleAmazon} />
      <div className="mt-3">
        {activeWidget ? (
          <Chart
            {...chartProps}
            chartId={activeWidget?.widget}
            urlParamsPrefix={BRAND_MEDIA_ID}
            className="min-h-120"
            widgetType={VISUALIZATION_TYPE.TableVisualization}
            component={TableVisualization}
            dateRange={dateRange}
            granularity={AggregationType.DAILY}
            sources={sources}
            requiredSources={activeWidget?.requiredSources}
            hideAggSelector={true}
            cubeFilters={cubeFilters}
            queryPrefix={queryPrefix}
            useOrderQueryPrefix={true}
            defaultSorting={chartPropsSorting}
            defaultColumnKeys={defaultColumnKeys}
            summaryWidget={activeWidget?.summaryWidget}
            useLocalStoragePagination={!activeWidget?.summaryWidget}
            columnConfig={chartProps.columnConfig.filter(
              ({ key }: { key: string }) =>
                (columnKeys as string[])?.includes(key),
            )}
            openDrawer={
              Object.values(VISUALIZATION_TABLE).includes(activeWidget?.widget)
                ? handleOpenDrawer
                : undefined
            }
          />
        ) : (
          <div>
            It seems like you don't have active connection to show anything on
            this page. Please{" "}
            <Link
              className="link"
              to="/setup/connectors"
              title="Connector page"
            >
              review your connections.
            </Link>
          </div>
        )}
      </div>
    </>
  )
}
