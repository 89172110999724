import { useMemo } from "react"
import { useCompanyConnectorControllerGetCompanyConnectorsQuery } from "../api/companyConnectorApi"
import { ConnectionStatus } from "../api/types"
import { POSTHOG_FEATURES } from "../constants/feature-flags"
import { CONNECTORS_IDS } from "../constants/sources"
import usePostHogFeatureFlags from "./posthog-feaures"

export default function useMediaMentionsAccess() {
  const isFeatureFlagEnabled = usePostHogFeatureFlags(
    POSTHOG_FEATURES.BRAND_MEDIA_MENTIONS,
  )
  const { data: companyConnectors } =
    useCompanyConnectorControllerGetCompanyConnectorsQuery()

  const hasArchiveConnector = useMemo(
    () =>
      companyConnectors?.find(
        connector => connector.serviceName === CONNECTORS_IDS.ARCHIVE,
      )?.status === ConnectionStatus.CONNECTED,
    [companyConnectors],
  )

  return isFeatureFlagEnabled && hasArchiveConnector
}
