import { emptySplitApi as api } from "./emptyApi"
import {
  AccountInfoDto,
  CompanyConnectCardDto,
  CompanyConnectorDto,
  CompanyConnectorStatusDto,
  ConnectionLinkDto,
} from "./types"
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    companyConnectorControllerCreateCompanyConnector: build.mutation<
      CompanyConnectorControllerCreateCompanyConnectorApiResponse,
      CompanyConnectorControllerCreateCompanyConnectorApiArg
    >({
      query: queryArg => ({
        url: `/company-connector`,
        method: "POST",
        body: queryArg.createCompanyConnectorDto,
      }),
    }),
    companyConnectorControllerGetCompanyConnectors: build.query<
      CompanyConnectorControllerGetCompanyConnectorsApiResponse,
      CompanyConnectorControllerGetCompanyConnectorsApiArg
    >({
      query: () => ({ url: `/company-connector` }),
      providesTags: ["CompanyConnectors"],
    }),
    companyConnectorControllerAuthenticateCompanyConnector: build.mutation<
      CompanyConnectorControllerAuthenticateCompanyConnectorApiResponse,
      CompanyConnectorControllerAuthenticateCompanyConnectorApiArg
    >({
      query: queryArg => ({
        url: `/company-connector/${queryArg.id}/authenticate`,
        method: "POST",
        body: queryArg.authenticateCompanyConnectorDto,
      }),
      invalidatesTags: ["CompanyConnectors"],
    }),
    companyConnectorControllerCompleteCompanyConnectorOnboarding:
      build.mutation<
        CompanyConnectorControllerCompleteCompanyConnectorOnboardingApiResponse,
        CompanyConnectorControllerCompleteCompanyConnectorOnboardingApiArg
      >({
        query: queryArg => ({
          url: `/company-connector/complete-onboard`,
          method: "PUT",
          body: queryArg.completeOnboardingDto,
        }),
        invalidatesTags: ["CompanyConnectors"],
      }),
    companyConnectorControllerUpdateCompanyConnector: build.mutation<
      CompanyConnectorControllerUpdateCompanyConnectorApiResponse,
      CompanyConnectorControllerUpdateCompanyConnectorApiArg
    >({
      query: queryArg => ({
        url: `/company-connector/${queryArg.id}`,
        method: "PUT",
        body: queryArg.updateCompanyConnectorDto,
      }),
      invalidatesTags: ["CompanyConnectors"],
    }),
    companyConnectorControllerDeleteCompanyConnector: build.mutation<
      CompanyConnectorControllerDeleteCompanyConnectorApiResponse,
      CompanyConnectorControllerDeleteCompanyConnectorApiArg
    >({
      query: queryArg => ({
        url: `/company-connector/${queryArg.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CompanyConnectors"],
    }),
    companyConnectorControllerUploadCsv: build.mutation<
      CompanyConnectorControllerUploadCsvApiResponse,
      CompanyConnectorControllerUploadCsvApiArg
    >({
      query: queryArg => ({
        url: `/company-connector/${queryArg.id}/upload-csv`,
        method: "POST",
        body: queryArg.uploadCsvDto,
      }),
    }),
    companyConnectorControllerGetCompanyConnectorLink: build.query<
      CompanyConnectorControllerGetCompanyConnectorLinkApiResponse,
      CompanyConnectorControllerGetCompanyConnectorLinkApiArg
    >({
      query: queryArg => ({ url: `/company-connector/${queryArg.id}/links` }),
    }),
    companyConnectorControllerGetCompanyConnectorAccountInfo: build.query<
      CompanyConnectorControllerGetCompanyConnectorAccountInfoApiResponse,
      CompanyConnectorControllerGetCompanyConnectorAccountInfoApiArg
    >({
      query: queryArg => ({
        url: `/company-connector/${queryArg.id}/account-info`,
      }),
    }),
    companyConnectorControllerGetCompanyConnectorStatus: build.query<
      CompanyConnectorControllerGetCompanyConnectorStatusApiResponse,
      CompanyConnectorControllerGetCompanyConnectorStatusApiArg
    >({
      query: queryArg => ({ url: `/company-connector/${queryArg.id}/status` }),
    }),
    companyConnectorControllerGetPresignedUrl: build.query<
      CompanyConnectorControllerCreatePresignedUrlApiResponse,
      CompanyConnectorControllerCreatePresignedUrlApiArg
    >({
      query: queryArg => ({
        url: `/company-connector/${queryArg.id}/presigned-url`,
      }),
    }),
    companyConnectorControllerAuthenticateUpload: build.mutation<
      CompanyConnectorControllerAuthenticateUploadApiResponse,
      CompanyConnectorControllerAuthenticateUploadApiArg
    >({
      query: queryArg => ({
        url: `/company-connector/${queryArg.id}/authenticate-upload`,
        method: "POST",
        body: queryArg.authenticateUploadDto,
      }),
      invalidatesTags: ["CompanyConnectors"],
    }),
    guestCompanyConnectorControllerCreateCompanyConnector: build.mutation<
      GuestCompanyConnectorControllerCreateCompanyConnectorApiResponse,
      GuestCompanyConnectorControllerCreateCompanyConnectorApiArg
    >({
      query: queryArg => ({
        url: "/guest/company-connector",
        method: "POST",
        body: {
          token: queryArg.token,
        },
      }),
    }),
    guestCompanyConnectorControllerAuthenticate: build.mutation<
      GuestCompanyConnectorControllerAuthenticateCompanyConnectorApiResponse,
      GuestCompanyConnectorControllerAuthenticateCompanyConnectorApiArg
    >({
      query: queryArg => ({
        url: `/guest/company-connector/${queryArg.id}/authenticate`,
        method: "POST",
        body: {
          token: queryArg.token,
          ...queryArg.authenticateCompanyConnectorDto,
        },
      }),
    }),
  }),
  overrideExisting: false,
})

export type GuestCompanyConnectorControllerCreateCompanyConnectorApiResponse =
  CompanyConnectCardDto
export type GuestCompanyConnectorControllerCreateCompanyConnectorApiArg = {
  token: string
}

export type GuestCompanyConnectorControllerAuthenticateCompanyConnectorApiResponse =
  CompanyConnectorDto
export type GuestCompanyConnectorControllerAuthenticateCompanyConnectorApiArg =
  {
    id: string
    token: string
    authenticateCompanyConnectorDto: AuthenticateCompanyConnectorDto
  }

export { injectedRtkApi as generatedApi }
export type CompanyConnectorControllerCreateCompanyConnectorApiResponse =
  /** status 200  */ CompanyConnectCardDto
export type CompanyConnectorControllerCreateCompanyConnectorApiArg = {
  createCompanyConnectorDto: CreateCompanyConnectorDto
}
export type CompanyConnectorControllerGetCompanyConnectorsApiResponse =
  /** status 200  */ CompanyConnectorDto[]
export type CompanyConnectorControllerGetCompanyConnectorsApiArg = void
export type CompanyConnectorControllerAuthenticateCompanyConnectorApiResponse =
  /** status 200  */ CompanyConnectorDto
export type CompanyConnectorControllerAuthenticateCompanyConnectorApiArg = {
  id: string
  authenticateCompanyConnectorDto: AuthenticateCompanyConnectorDto
}
export type CompanyConnectorControllerCompleteCompanyConnectorOnboardingApiResponse =
  /** status 200  */ CompanyConnectorDto
export type CompanyConnectorControllerCompleteCompanyConnectorOnboardingApiArg =
  {
    completeOnboardingDto: CompleteOnboardingDto
  }
export type CompanyConnectorControllerUpdateCompanyConnectorApiResponse =
  /** status 200  */ CompanyConnectorDto
export type CompanyConnectorControllerUpdateCompanyConnectorApiArg = {
  id: string
  updateCompanyConnectorDto: UpdateCompanyConnectorDto
}
export type CompanyConnectorControllerDeleteCompanyConnectorApiResponse =
  /** status 200  */ CompanyConnectorDto
export type CompanyConnectorControllerDeleteCompanyConnectorApiArg = {
  id: string
}
export type CompanyConnectorControllerUploadCsvApiResponse =
  /** status 200  */ UploadCsvResponseDto
export type CompanyConnectorControllerUploadCsvApiArg = {
  id: string
  uploadCsvDto: UploadCsvDto
}
export type CompanyConnectorControllerGetCompanyConnectorLinkApiResponse =
  /** status 200  */ ConnectionLinkDto
export type CompanyConnectorControllerGetCompanyConnectorLinkApiArg = {
  id: string
}
export type CompanyConnectorControllerGetCompanyConnectorAccountInfoApiResponse =
  AccountInfoDto
export type CompanyConnectorControllerGetCompanyConnectorAccountInfoApiArg = {
  id: string
}
export type CompanyConnectorControllerGetCompanyConnectorStatusApiResponse =
  /** status 200  */ CompanyConnectorStatusDto
export type CompanyConnectorControllerGetCompanyConnectorStatusApiArg = {
  id: string
}
export type CompanyConnectorControllerCreatePresignedUrlApiResponse = {
  key: string
  presignedUrl: string
}
export type CompanyConnectorControllerCreatePresignedUrlApiArg = {
  id: string
}
export type CompanyConnectorControllerAuthenticateUploadApiResponse =
  CompanyConnectorDto
export type CompanyConnectorControllerAuthenticateUploadApiArg = {
  id: string
  authenticateUploadDto: AuthenticateUploadDto
}

export type CreateCompanyConnectorDto = {
  service: string
}
export type CreatePresignedUrlDto = {
  connectorId: string
}
export type AuthenticateCompanyConnectorDto = {
  authCode: string
  extra?: string
  extraMetadata?: Record<string, any>
}
export type CompleteOnboardingDto = {
  fivetranId: string
}
export type UpdateCompanyConnectorDto = {
  serviceName?: string
  fivetranId?: string
  airByteSourceId?: string
  metadata?: object
  confirmedAt?: Date
}
export type UploadCsvResponseDto = {
  status: string
  result: object[]
}
export type UploadCsvDto = {
  files: Blob[][]
}
export type AuthenticateUploadDto = {
  keys: string[]
}
export const {
  useCompanyConnectorControllerCreateCompanyConnectorMutation,
  useCompanyConnectorControllerGetCompanyConnectorsQuery,
  useCompanyConnectorControllerAuthenticateCompanyConnectorMutation,
  useCompanyConnectorControllerCompleteCompanyConnectorOnboardingMutation,
  useCompanyConnectorControllerUpdateCompanyConnectorMutation,
  useCompanyConnectorControllerDeleteCompanyConnectorMutation,
  useLazyCompanyConnectorControllerGetPresignedUrlQuery,
  useCompanyConnectorControllerUploadCsvMutation,
  useCompanyConnectorControllerGetCompanyConnectorLinkQuery,
  useCompanyConnectorControllerGetCompanyConnectorAccountInfoQuery,
  useCompanyConnectorControllerGetCompanyConnectorStatusQuery,
  useCompanyConnectorControllerAuthenticateUploadMutation,
  useGuestCompanyConnectorControllerCreateCompanyConnectorMutation,
  useGuestCompanyConnectorControllerAuthenticateMutation,
} = injectedRtkApi
