import useDate from "~/ui-rtk/hooks/date"

const dayjs = useDate()

const today = dayjs()
const monthAgo = today.subtract(30, "day").toDate()
const quarterAgo = today.subtract(90, "day").toDate()
const halfAYearAgo = today.subtract(180, "day").toDate()
const yearAgo = today.subtract(365, "day").toDate()

export const TABS_OPTIONS = [
  {
    label: "30 Days",
    value: monthAgo,
  },
  {
    label: "90 Days",
    value: quarterAgo,
  },
  {
    label: "180 Days",
    value: halfAYearAgo,
  },
  {
    label: "365 Days",
    value: yearAgo,
  },
]
