import { safeDivide } from "./number-utils"
import tinycolor from "tinycolor2"
import { dark as theme } from "~/ui-rtk/themes/dark.js"

const DEFAULT_COLORS = theme.colors.charts
const DEFAULT_COLORS_VALUES = Object.values(DEFAULT_COLORS)

// SourceIds and ConnectorIds
export const BRAND_COLORS_MAP: Record<string, string> = {
  AMAZON: "#F19E38",
  AMAZON_ADS: "#91887E",
  AMAZON_SELLING_PARTNER: "#F19E38",
  AMAZON_SELLING_PARTNER_VENDOR: "#F19E38",
  AMAZON_VENDOR: "#F19E38",
  ATENTIVE: "#FCEE8D",
  CRISP: "#7dc1ff",
  FACEBOOK: "#0031CA",
  FACEBOOK_PAGES: "#0031CA",
  FACEBOOK_ADS: "#7798FC",
  GOOGLE_ANALYTICS: "#C6A873",
  GOOGLE_ANALYTICS_4: "#916922",
  GOOGLE_ADS: "#58A65E",
  GOOGLE_KEYWORDS: "#1a73e8",
  GOOGLE_KEYWORDS_SEARCH: "#1a73e8",
  GOOGLE_SEARCH_CONSOLE: "#1a73e8",
  GOOGLE_SEARCH: "#1a73e8",
  INSTAGRAM: "#7A32AB",
  INSTAGRAM_INSIGHT: "#7A32AB",
  INSTAGRAM_BUSINESS: "#E4BDFF",
  INSTAGRAM_ADS: "#E4BDFF",
  META: "#0082fb",
  SHOPIFY: "#8ACD2A",
  TIKTOK: "#35BABE",
  TIKTOK_ADS: "#288486",
  YOTPO: "#2D6BCF",
  SPS: "#7ACCFF",
  KLAVIYO: "#FBE3B8",
  YOUTUBE: "#DA3832",
  YOUTUBE_ANALYTICS: "#DA3832",
  SEPHORA: "#efefef",
  TARGET: "#950C00",
  UNKNOWN: "#777777",
}

const colorsPalette = [
  "#ffff0d",
  "#182bff",
  "#d548ff",
  "#4cfa15",
  "#ff515f",
  "#08d5eb",
  "#cc2e3b",
  "#cc5a19",
  "#9f28cb",
  "#e9dbd7",
  "#1a73e8",
  "#9e3218",
  "#369c52",
  "#DA3832",
  "#F19E38",
  "#aab01e",
]

const METRIC_TO_COLOR_MAP: Record<string, string> = {
  "metrics.instagram_business_follows": BRAND_COLORS_MAP.INSTAGRAM_BUSINESS,
  "metrics.instagram_insights_follows": BRAND_COLORS_MAP.INSTAGRAM_INSIGHT,
  "metrics.tiktok_profile_follows": BRAND_COLORS_MAP.TIKTOK,
  "metrics.facebook_pages_follows": BRAND_COLORS_MAP.FACEBOOK_PAGES,
  "metrics.youtube_channel_follows": BRAND_COLORS_MAP.YOUTUBE_ANALYTICS,
  "metrics.attentive_sms_subscribers": BRAND_COLORS_MAP.ATTENTIVE,
  "metrics.klaviyo_email_subscribers": BRAND_COLORS_MAP.KLAVIYO,
  "metrics.google_search_organic_branded_search_impressions":
    BRAND_COLORS_MAP.GOOGLE_SEARCH_CONSOLE,
  "metrics.ga_sessions_from_organic_sessions":
    BRAND_COLORS_MAP.GOOGLE_ANALYTICS_4,
  "metrics.ga_sessions_from_direct_sessions":
    BRAND_COLORS_MAP.GOOGLE_ANALYTICS_4,
  "metrics.ga_engaged_sessions_from_new_visitors":
    BRAND_COLORS_MAP.GOOGLE_ANALYTICS_4,
  "metrics.instagram_insights_shares": BRAND_COLORS_MAP.INSTAGRAM_INSIGHT,
  "metrics.instagram_business_shares": BRAND_COLORS_MAP.INSTAGRAM,
  "metrics.youtube_channel_shares": BRAND_COLORS_MAP.YOUTUBE_ANALYTICS,
  "metrics.youtube_channel_comments": BRAND_COLORS_MAP.YOUTUBE_ANALYTICS,
  "metrics.tiktok_videos_shares": BRAND_COLORS_MAP.TIKTOK,
  "metrics.facebook_pages_shares": BRAND_COLORS_MAP.FACEBOOK_PAGES,
  "metrics.instagram_insights_replies": BRAND_COLORS_MAP.INSTAGRAM_INSIGHT,
  "metrics.instagram_insights_saves": BRAND_COLORS_MAP.INSTAGRAM_INSIGHT,
  "metrics.instagram_ads_shares": BRAND_COLORS_MAP.INSTAGRAM_ADS,
  "metrics.instagram_ads_likes": BRAND_COLORS_MAP.INSTAGRAM_ADS,
  "metrics.instagram_ads_saves": BRAND_COLORS_MAP.INSTAGRAM_ADS,
  "metrics.instagram_business_profile_views": BRAND_COLORS_MAP.INSTAGRAM,
  "metrics.youtube_channel_likes": BRAND_COLORS_MAP.YOUTUBE_ANALYTICS,
  "metrics.facebook_ads_spend": BRAND_COLORS_MAP.FACEBOOK_ADS,
  "metrics.tiktok_videos_likes": BRAND_COLORS_MAP.TIKTOK,
  "metrics.tiktok_ads_follows": BRAND_COLORS_MAP.TIKTOK_ADS,
  "company_revenue_metrics_daily.ga_sessions_from_owned_sessions":
    BRAND_COLORS_MAP.GOOGLE_ANALYTICS_4,
  "company_revenue_metrics_daily.ga_engaged_sessions_from_owned_sessions":
    BRAND_COLORS_MAP.GOOGLE_ANALYTICS_4,
  "company_revenue_metrics_daily.ga_new_sessions_from_owned_sessions":
    BRAND_COLORS_MAP.GOOGLE_ANALYTICS_4,
  "company_revenue_metrics_daily.ga_conversions_from_owned_sessions":
    BRAND_COLORS_MAP.GOOGLE_ANALYTICS_4,
  "company_revenue_metrics_daily.ga_revenue_from_owned_sessions":
    BRAND_COLORS_MAP.GOOGLE_ANALYTICS_4,
  "company_revenue_metrics_daily.owned_conversion_rate":
    BRAND_COLORS_MAP.GOOGLE_ANALYTICS,
  "company_revenue_metrics_daily.owned_revenue_per_session":
    BRAND_COLORS_MAP.GOOGLE_ANALYTICS,
  "metrics.google_keywords_share_of_search":
    BRAND_COLORS_MAP.GOOGLE_SEARCH_CONSOLE,
  "metrics.youtube_channel_saves": BRAND_COLORS_MAP.YOUTUBE_ANALYTICS,
  "metrics.instagram_insights_share_of_comments":
    BRAND_COLORS_MAP.INSTAGRAM_INSIGHT,
  "metrics.instagram_insights_share_of_likes":
    BRAND_COLORS_MAP.INSTAGRAM_INSIGHT,
  "metrics.ga_sessions_from_organic_referral_sessions":
    BRAND_COLORS_MAP.GOOGLE_ANALYTICS_4,
  "company_mind_share_metrics_monthly.share_of_search":
    BRAND_COLORS_MAP.GOOGLE_SEARCH_CONSOLE,
  "metrics.instagram_insights_share_of_followers":
    BRAND_COLORS_MAP.INSTAGRAM_INSIGHT,
  "company_media_metrics_daily.tiktok_ads_spend": BRAND_COLORS_MAP.TIKTOK_ADS,
  "company_media_metrics_daily.tiktok_ads_follows": BRAND_COLORS_MAP.TIKTOK_ADS,
  "company_media_metrics_daily.tiktok_ads_comments":
    BRAND_COLORS_MAP.TIKTOK_ADS,
  "company_media_metrics_daily.tiktok_ads_shares": BRAND_COLORS_MAP.TIKTOK_ADS,
  "company_media_metrics_daily.tiktok_ads_likes": BRAND_COLORS_MAP.TIKTOK_ADS,
  "metrics.yt_followers": BRAND_COLORS_MAP.YOUTUBE_ANALYTICS,
  "metrics.youtube_channel_unfollows": BRAND_COLORS_MAP.YOUTUBE_ANALYTICS,
  "metrics.google_keywords_brand_searches":
    BRAND_COLORS_MAP.GOOGLE_SEARCH_CONSOLE,
  "metrics.google_ads_paid_branded_search_impressions":
    BRAND_COLORS_MAP.GOOGLE_ADS,
  "metrics.google_ads_paid_branded_search_total_spend":
    BRAND_COLORS_MAP.GOOGLE_ADS,
  "metrics.facebook_pages_views": BRAND_COLORS_MAP.FACEBOOK_PAGES,
  "metrics.facebook_ads_likes": BRAND_COLORS_MAP.FACEBOOK_ADS,
  "metrics.facebook_ads_comments": BRAND_COLORS_MAP.FACEBOOK_ADS,
  "metrics.facebook_ads_shares": BRAND_COLORS_MAP.FACEBOOK_ADS,
  "metrics.facebook_ads_follows": BRAND_COLORS_MAP.FACEBOOK_ADS,
  "metrics.ga_total_revenue_from_all_sessions":
    BRAND_COLORS_MAP.GOOGLE_ANALYTICS_4,
  "metrics.ga_revenue_from_owned_sessions": BRAND_COLORS_MAP.GOOGLE_ANALYTICS_4,
  "metrics.ga_revenue_from_paid_sessions": BRAND_COLORS_MAP.GOOGLE_ANALYTICS_4,
  "metrics.ga_total_new_sessions_from_all_sessions":
    BRAND_COLORS_MAP.GOOGLE_ANALYTICS_4,
  "metrics.ga_revenue_paid_and_owned": BRAND_COLORS_MAP.GOOGLE_ANALYTICS_4,
  "company_revenue_metrics_daily.ga_total_organic_sessions_from_all_sessions":
    BRAND_COLORS_MAP.GOOGLE_ANALYTICS_4,
  "company_revenue_metrics_daily.ga_total_organic_engaged_sessions_from_all_sessions":
    BRAND_COLORS_MAP.GOOGLE_ANALYTICS_4,
  "company_revenue_metrics_daily.ga_total_organic_new_sessions_from_all_sessions":
    BRAND_COLORS_MAP.GOOGLE_ANALYTICS_4,
  "company_revenue_metrics_daily.ga_total_organic_conversions_from_all_sessions":
    BRAND_COLORS_MAP.GOOGLE_ANALYTICS_4,
  "company_revenue_metrics_daily.ga_total_organic_revenue_from_all_sessions":
    BRAND_COLORS_MAP.GOOGLE_ANALYTICS_4,
  "metrics.ga_sessions": BRAND_COLORS_MAP.GOOGLE_ANALYTICS_4,
  "metrics.ga_revenue": BRAND_COLORS_MAP.GOOGLE_ANALYTICS_4,
  "metrics.tiktok_videos_comments": BRAND_COLORS_MAP.TIKTOK,
  "metrics.tiktok_videos_fully_watched": BRAND_COLORS_MAP.TIKTOK,
  "metrics.tiktok_profile_views": BRAND_COLORS_MAP.TIKTOK,
  "metrics.like_count": BRAND_COLORS_MAP.INSTAGRAM_INSIGHT,
  "metrics.facebook_pages_likes": BRAND_COLORS_MAP.FACEBOOK_PAGES,
  "metrics.instagram_business_comments": BRAND_COLORS_MAP.INSTAGRAM_BUSINESS,
  "metrics.instagram_insights_comments": BRAND_COLORS_MAP.INSTAGRAM_INSIGHT,
  "metrics.facebook_pages_comments": BRAND_COLORS_MAP.FACEBOOK_PAGES,
  "metrics.instagram_insights_likes": BRAND_COLORS_MAP.INSTAGRAM_INSIGHT,
  "metrics.facebook_ads_reactions": BRAND_COLORS_MAP.FACEBOOK_ADS,
}

export const getLightColor = (color: string) =>
  tinycolor(color).isLight() ? color : tinycolor(color).brighten(20).toString()

export const getColorForMetric = (metric: string, idx = 0) => {
  if (METRIC_TO_COLOR_MAP[metric]) {
    return METRIC_TO_COLOR_MAP[metric]
  }
  if (DEFAULT_COLORS_VALUES[idx]) {
    return DEFAULT_COLORS_VALUES[idx]
  }
  // Get Random Color from Default Colors
  return DEFAULT_COLORS_VALUES[
    Math.floor(Math.random() * DEFAULT_COLORS_VALUES.length)
  ]
}

export function getUniqueColor(n: number) {
  const rgb = [0, 0, 0]
  let no = n
  for (let i = 0; i < 24; i++) {
    rgb[i % 3] <<= 1
    rgb[i % 3] |= no & 0x01
    no >>= 1
  }
  return `#${rgb.reduce((a, c) => (c > 0x0f ? c.toString(16) : `0${c.toString(16)}`) + a, "")}`
}

export const getColorFromPaletteByIdx = (idx: number) =>
  colorsPalette[idx] ?? getUniqueColor(idx)

export const stringToColor = (str: string) => {
  const string = str.toLowerCase()
  let hash = 0
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }
  return getUniqueColor(hash)
}

export function calcDelta(value?: number, compareValue?: number) {
  if (!value) {
    if (!compareValue) {
      return null
    }
    return compareValue < 0 ? 100 : -100
  }
  if (!compareValue) {
    return value > 0 ? 100 : -100
  }

  const absDelta = value - compareValue
  return safeDivide(absDelta, Math.abs(compareValue)) * 100
}
