import React, { type RefObject, type FC, type ReactNode, useMemo } from "react"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  className?: string
  prependNode?: ReactNode
  appendNode?: ReactNode
  width?: number
  fullWidth?: boolean
  label?: string
  isError?: boolean
  classes?: Partial<{
    label: string
    input: string
  }>
  itemRef?: RefObject<HTMLInputElement>
}

export default function Input({
  className,
  readOnly = false,
  type = "text",
  prependNode,
  appendNode,
  width = 200,
  fullWidth = false,
  isError,
  itemRef,
  label,
  classes,
  ...otherProps
}: InputProps): ReturnType<FC<InputProps>> {
  const cs = useMemo(
    () =>
      cn(
        `px-4 py-3.5 rounded-lg border border-basic-blue bg-basic-dark-blue !outline-none flex items-center justify-center gap-2 cursor-text`,
        isError && "border-states-error",
        className,
        classes?.label,
      ),
    [isError, className, classes],
  )

  const labelElement = label ? (
    <div className="label">
      <span className="label-text">{label}</span>
    </div>
  ) : null

  const labelStyles = useMemo(
    () => ({
      width: fullWidth ? "100%" : `${width}px`,
    }),
    [width, fullWidth],
  )

  return (
    <label className={cs} style={labelStyles}>
      {labelElement}
      {prependNode}
      <input
        ref={itemRef}
        type={type}
        readOnly={readOnly}
        className={cn(
          "w-full p-0 leading-4 grow text-3.5 autofill:[14px] focus:ring-0 bg-transparent font-semibold",
          classes?.input,
        )}
        {...otherProps}
      />
      {appendNode}
    </label>
  )
}
