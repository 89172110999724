import React, { useCallback, useState } from "react"
import { ImageSvg } from "../../svg/essentials"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

type TClasses = Partial<{
  image: string
  wrapper: string
}>

export type TImageProps = {
  src: string
  alt: string
  size?: number
  width?: number
  height?: number
  classes?: Partial<TClasses>
  fallback?: React.ReactNode | string
  loadingIndicator?: React.ReactNode
} & Omit<React.ImgHTMLAttributes<HTMLImageElement>, "src" | "alt" | "className">

export const Image: React.FC<TImageProps> = ({
  src,
  alt,
  size,
  width,
  height,
  classes,
  fallback,
  loadingIndicator,
  ...props
}) => {
  const [isBrokenImage, setIsBrokenImage] = useState(!src)
  const [isFallbackBrokenImage, setIsFallbackBrokenImage] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const contentSniffer = (contentUrl: string) => {
    const types = new Map([
      ["jpg", "img"],
      ["gif", "img"],
      ["mp4", "video"],
      ["3gp", "video"],
    ])

    try {
      const url = new URL(contentUrl)
      const extension: string = url.pathname.split(".")[1]

      const type = types.get(extension)
      
      return type || "img"
    } catch (error) {
      return "img"
    }
  }

  const handleLoad = () => {
    setIsLoading(false)
  }

  const handleFallbackError = () => {
    setIsLoading(false)
    setIsFallbackBrokenImage(true)
  }

  const fallbackImageProps: React.ImgHTMLAttributes<HTMLImageElement> = {
    src,
    alt,
    style: {
      width: size || width,
      height,
    },
    onLoad: handleLoad,
    onError: handleFallbackError,
    className: cn(`object-cover`, classes?.image),
    ...props,
  }

  const renderFallback = useCallback(() => {
    if (!isFallbackBrokenImage && fallback) {
      if (typeof fallback === "string") {
        return <img src={fallback} {...fallbackImageProps} />
      }
      return fallback
    }
    return (
      <ImageSvg
        size={size || width || height || 42}
        fill="basic.grey.inactive"
      />
    )
  }, [size, width, height, fallback, fallbackImageProps, isFallbackBrokenImage])

  const handleError = () => {
    setIsBrokenImage(true)
  }

  const mediaProps: React.ImgHTMLAttributes<HTMLImageElement> = {
    src,
    alt,
    style: {
      width: size || width,
      height,
    },
    onLoad: handleLoad,
    onError: handleError,
    className: cn(`object-cover`, classes?.image),
    ...props,
  }

  const renderImageContent = useCallback(() => {
    if (isLoading && loadingIndicator) {
      return loadingIndicator
    } else if (isBrokenImage) {
      return renderFallback()
    }

    return <img {...mediaProps} />
  }, [
    isLoading,
    loadingIndicator,
    isBrokenImage,
    isFallbackBrokenImage,
    mediaProps,
    src,
  ])

  const renderVideoContent = useCallback(() => {
    if (isLoading && loadingIndicator) {
      return loadingIndicator
    } else if (isBrokenImage) {
      return renderFallback()
    }

    return <video controls className="h-full w-full rounded-lg" src={src} />
  }, [
    isLoading,
    loadingIndicator,
    isBrokenImage,
    isFallbackBrokenImage,
    mediaProps,
    src,
  ])

  const type = contentSniffer(src)

  return (
    <span
      className={cn("flex items-center justify-center", classes?.wrapper)}
      onClick={props.onClick}
    >
      {type === "img" && renderImageContent()}
      {type === "video" && renderVideoContent()}
    </span>
  )
}
