import React from "react"
import { Form, Formik, Field, FieldProps } from "formik"
import { toast } from "react-toastify"

import { ConnectorDialog } from "~/ui-rtk/components/features/connectors"
import { FormValues, useConnect } from "./connect"
import { ChipsInput, Button } from "~/ui-rtk/components/ui/controls"

import {
  isInternalServerError,
  isUnauthorizedError,
} from "~/ui-rtk/utils/http-utils"

import { connectorIcons } from "~/ui-rtk/shared/constants/image-paths.contants"

import type { CustomerConnectorDialogFormPropsWithoutUri } from "../types"

type ArchiveFormProps = CustomerConnectorDialogFormPropsWithoutUri

const ArchiveForm: React.FC<ArchiveFormProps> = ({
  companyConnectorId,
  isOpen,
  onClose,
  ...props
}) => {
  const { isLoading, initialValues, validationSchema, authenticate } =
    useConnect()

  const handleConnect = async (values: FormValues) => {
    if (!companyConnectorId) {
      throw new Error("Missing company connector id")
    }

    try {
      await authenticate({
        companyConnectorId,
        authCode: "-",
        archive: {
          igHandles: (values.igHandles || []).filter(
            (handle): handle is string => !!handle,
          ),
          igHashtags: (values.igHashTags || []).filter(
            (hashtag): hashtag is string => !!hashtag,
          ),
          tiktokHandles: (values.tiktokHandles || []).filter(
            (handle): handle is string => !!handle,
          ),
          tiktokHashtags: (values.tiktokHashTags || []).filter(
            (hashtag): hashtag is string => !!hashtag,
          ),
        },
        ...props,
      })
    } catch (err) {
      if (isUnauthorizedError(err)) {
        toast.error("Invalid credentials", {
          toastId: "archive-form-connect-unauthorized-error",
        })
      } else if (isInternalServerError(err)) {
        toast.error("Uh oh! Something didn't go as expected!", {
          toastId: "archive-form-connect-interval-error",
        })
      } else {
        toast.error("Uh oh! The server returned a bad response!", {
          toastId: "archive-form-connect-bad-response-error",
        })
      }
      return
    }

    onClose()
  }

  return (
    <ConnectorDialog
      imgSrc={connectorIcons.archive}
      alt="Archive Logo"
      title="Connection to Archive"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleConnect}
      >
        {({ errors }) => (
          <Form className="flex flex-col w-full gap-4 mt-8">
            <div className="space-y-3">
              <div className="flex flex-col justify-start text-left">
                <label className="ml-1">
                  <b className="text-3 text-platinum">Instagram Handles:</b>
                </label>
                <Field id="igHandles" name="igHandles">
                  {({ field, form: { setFieldValue } }: FieldProps) => (
                    <ChipsInput
                      {...field}
                      onChange={async value => {
                        await setFieldValue(
                          "igHandles",
                          value.map(v => v.replace(/^[@#]/, "")),
                        )
                      }}
                    />
                  )}
                </Field>
                {errors.igHandles && (
                  <div className="input-error-message">
                    {Array.isArray(errors.igHandles)
                      ? errors.igHandles.find(Boolean)
                      : errors.igHandles}
                  </div>
                )}
              </div>
              <div className="flex flex-col justify-start text-left">
                <label className="ml-1">
                  <b className="text-3 text-platinum">Instagram HashTags:</b>
                </label>
                <Field id="igHashTags" name="igHashTags">
                  {({ field, form: { setFieldValue } }: FieldProps) => (
                    <ChipsInput
                      {...field}
                      onChange={async value => {
                        await setFieldValue(
                          "igHashTags",
                          value.map(v => v.replace(/^[@#]/, "")),
                        )
                      }}
                    />
                  )}
                </Field>
                {errors.igHashTags && (
                  <div className="input-error-message">
                    {Array.isArray(errors.igHashTags)
                      ? errors.igHashTags.find(Boolean)
                      : errors.igHashTags}
                  </div>
                )}
              </div>
              <div className="flex flex-col justify-start text-left">
                <label className="ml-1">
                  <b className="text-3 text-platinum">TikTok Handles:</b>
                </label>
                <Field id="tiktokHandles" name="tiktokHandles">
                  {({ field, form: { setFieldValue } }: FieldProps) => (
                    <ChipsInput
                      {...field}
                      onChange={async value => {
                        await setFieldValue(
                          "tiktokHandles",
                          value.map(v => v.replace(/^[@#]/, "")),
                        )
                      }}
                    />
                  )}
                </Field>
                {errors.tiktokHandles && (
                  <div className="input-error-message">
                    {Array.isArray(errors.tiktokHandles)
                      ? errors.tiktokHandles.find(Boolean)
                      : errors.tiktokHandles}
                  </div>
                )}
              </div>
              <div className="flex flex-col justify-start text-left">
                <label className="ml-1">
                  <b className="text-3 text-platinum">TikTok HashTags:</b>
                </label>
                <Field id="tiktokHashTags" name="tiktokHashTags">
                  {({ field, form: { setFieldValue } }: FieldProps) => (
                    <ChipsInput
                      {...field}
                      onChange={async value => {
                        await setFieldValue(
                          "tiktokHashTags",
                          value.map(v => v.replace(/^[@#]/, "")),
                        )
                      }}
                    />
                  )}
                </Field>
                {errors.tiktokHashTags && (
                  <div className="input-error-message">
                    {Array.isArray(errors.tiktokHashTags)
                      ? errors.tiktokHashTags.find(Boolean)
                      : errors.tiktokHashTags}
                  </div>
                )}
              </div>
            </div>

            <Button
              variant={{ variant: "solid", color: "white" }}
              type="submit"
              isLoading={isLoading}
            >
              Connect
            </Button>
          </Form>
        )}
      </Formik>
    </ConnectorDialog>
  )
}

export default ArchiveForm
