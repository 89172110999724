import React, {
  createContext,
  useContext,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react"
import useDate, { Dayjs } from "~/ui-rtk/hooks/date"

const dayjs = useDate()

/**
 * Type definition for the cache entry.
 * Each cache entry contains:
 * - `createdAt`: Timestamp of when the cache was created.
 * - `data`: A `Set<string> | null` containing cached capabilities.
 * - `fillerId` (optional): ID of the process that is actively trying to fulfill this cache.
 */
type CapabilitiesCacheType = Record<
  string,
  { createdAt: Dayjs; data: Set<string> | null; fillerId?: number }
>

/**
 * Interface for the Capabilities Cache Context.
 * - `cache`: Stores cached capability data.
 * - `setCache`: Updates the cache with new data.
 * - `acquireIntent`: acquires intent to fulfill cache entry.
 * - `releaseIntent`: release intent to fulfill cache entry.
 */
interface CapabilitiesCacheContextType {
  cache: CapabilitiesCacheType
  setCache: (key: string, data: Set<string>) => void
  acquireIntent: (key: string, fillerId: number) => void
  releaseIntent: (key: string, fillerId: number) => void
}

const CapabilitiesCacheContext = createContext<
  CapabilitiesCacheContextType | undefined
>(undefined)

// Context Provider
export const CapabilitiesCacheProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [cache, setState] = useState<CapabilitiesCacheType>({})

  const setCache = useCallback(
    (key: string, data: Set<string>) => {
      setState(prevState => ({
        ...prevState,
        [key]: {
          createdAt: dayjs(),
          data,
        },
      }))
    },
    [setState],
  )

  const acquireIntent = useCallback(
    (key: string, fillerId: number) => {
      setState(prevState => {
        if (prevState[key]?.fillerId != null) return prevState
        return {
          ...prevState,
          [key]: {
            createdAt: dayjs(),
            data: null,
            fillerId,
          },
        }
      })
    },
    [cache, setState],
  )

  const releaseIntent = useCallback(
    (key: string, fillerId: number) => {
      setState(prevState => {
        if (prevState[key]?.fillerId !== fillerId) return prevState
        return {
          ...prevState,
          [key]: {
            ...prevState[key],
            fillerId: undefined,
          },
        }
      })
    },
    [cache, setState],
  )

  return (
    <CapabilitiesCacheContext.Provider
      value={{ cache, setCache, acquireIntent, releaseIntent }}
    >
      {children}
    </CapabilitiesCacheContext.Provider>
  )
}

export const useCapabilitiesCache = (key: string, expiryMinutes = 10) => {
  // kind of unique ID for the filler
  const fillerId = useRef(Math.floor(Math.random() * 1_000_000))
  const context = useContext(CapabilitiesCacheContext)

  if (!context) {
    throw new Error(
      "useCapabilitiesCache must be used within an CapabilitiesCacheProvider",
    )
  }

  useEffect(() => {
    if (
      context.cache[key]?.data == null &&
      context.cache[key]?.fillerId == null
    ) {
      context.acquireIntent(key, fillerId.current)
    }
    return () => {
      context.releaseIntent(key, fillerId.current)
    }
  }, [])

  const setCache = useCallback(
    (data: Set<string>) => {
      context.setCache(key, data)
    },
    [context.setCache, key],
  )

  return {
    data: context.cache[key]?.data,
    setCache,
    allowToFulfill:
      context.cache[key]?.fillerId === fillerId.current &&
      (context.cache[key]?.data != null ||
        dayjs(context.cache[key].createdAt).isAfter(
          dayjs().subtract(expiryMinutes, "minutes"),
        )),
  }
}
