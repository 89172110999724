import { CONNECTORS_IDS } from "../constants/sources"
import { useCheckServiceWasConnected } from "./connected-services"

export default function useShopifyAccess() {
  const isConnectorConnected = useCheckServiceWasConnected(
    CONNECTORS_IDS.SHOPIFY,
  )

  return isConnectorConnected
}
