export const dark = {
  screens: {
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    "2xl": "1536px",
  },
  colors: {
    brand: "#EB89B5",
    "brand-dark": "#4f0c2a",
    secondary: "#121826",
    background: {
      dark: "#212936",
      "dark-shade": "#121826", // secondary
      "dark-jungle": "#191e24",
      light: "#F8FAFC",
      "eerie-black": "#1B1C1F",
    },
    "pink-orange-gradient": {
      start: "#EB89B5",
      end: "#F4D148",
    },
    "marketing-warroom-tw": {
      neutral: "#677489", // neutral
      light: "#F8FAFC",
      dark: "#212936",
    },
    channel: {
      "pink-light": "#E7D5FF",
      "gray-light": "#E1E1E1",
      "blue-light": "#C9E1FF",
      "red-light": "#F2C1C3",
    },
    "raisin-black": "#212121",
    neutral: "#677489",
    "green-bright": "#00FFA3",
    infographics: {
      blue: {
        DEFAULT: "#5C8BD6",
        secondary: "#42639F",
      },
      purple: "#A57CFB",
    },
    charts: {
      purple: "#A57CFB",
      blue: "#5C8BD6",
      yellow: "#F4D148",
      cyan: "#66DECD",
      orange: "#EE8434",
      magenta: "#DB32B2",
      white: "#FFFFFF",
      gray: "#E1E1E1",
    },
    "verse-green": "#218A00",
    red: "#FF005C",
    error: "#E2323E",
    cyan: "#5DF5FF",
    "cadet-blue": "#A6ADBB",
    white: "#FFFFFF",
    black: "#000000",
    blue: "#1fb6ff",
    "blue-ultra": "#0866FF",
    purple: "#BBA3FF",
    pink: "#DF437B",
    "pink-ultra": "#FA00FF",
    orange: "#FF8A00",
    green: "#13ce66",
    yellow: "#ffc82c",
    "yellow-info": "#F4D148",
    "gray-dark": "#273444",
    gray: "#8492a6",
    "gray-light": "#D9D9D9",
    "gray-light-2": "#C2C6CB",
    "vibrant-blue": "#4F86C6",
    "slate-blue": "#5E69D1",
    "slate-blue-hover": "#5D64FB7A",
    "japanese-laurel": "#318936",
    "blue-cola": "#0288D1",
    "silver-sand": "#BFC1C2",
    "american-green": "#25A544",
    platinum: "#E4E5E9",
    onyx: "#393b41",

    // figma colors
    basic: {
      black: "#05051E",
      white: "#FFFFFF",
      light: "#F8FAFC",
      neutral: "#677489",
      grey: {
        inactive: "#677489",
      },
      pink: {
        DEFAULT: "#eb89b5",
        hover: "#FE8CBA",
      },
      blue: "#222446",
      "dark-blue": "#101028",
    },

    states: {
      error: "#E2323E",
      success: "#4CAF50",
      info: "#5C8BD6",
      warn: "#F4D148",
    },
  },
  fontFamily: {
    popper: ["Popper", "sans-serif"],
    inter: ["inter"],
    sans: ["sans-serif"],
  },
  extend: {
    spacing: {
      4.5: "1.125rem", // 18px
      5.5: "1.375rem", // 22px
      6.5: "1.625rem", // 26px
      7.5: "1.875rem", // 30px
      8.5: "2.125rem", // 34px
      9.5: "2.375rem", // 38px
      10.5: "2.625rem", // 42px
      11.5: "2.875rem", // 46px
      12.5: "3.125rem", // 50px
      13: "3.25rem", // 52px
      14: "3.5", // 56px
      15: "3.75rem", // 60px
      18: "4.5rem", // 72px
      19: "4.75rem", //  76px
      21: "5.25rem", // 84px
      25: "6.25rem", // 100px
      27.5: "6.875rem", // 110px
      30: "7.5rem", //  120px
      33: "8.25rem", // 132px
      35: "8.75rem", // 140px
      35.5: "8.875rem", // 142px
      45: "11.25rem", //  180px
      61: "15.25rem", //  244px
      50: "12.5rem", // 200px
      70: "17.5rem", //  280px
      75: "18.75rem", // 300px
      89: "22.25rem", //  356px
      95: "23.75rem", //  380px
      98: "24.5rem", //  392px
      100: "25rem", // 400px
      120: "30rem", // 480px
      125: "31.25rem", // 500px
      128: "32rem", // 512px
      132.5: "33.125rem", // 530px
      144: "36rem", // 576px
      145: "36.25rem", // 580px
      150: "37.5rem", //  600px
      155: "38.75rem", //  620px
      160: "40rem", // 640px
      180: "45rem", // 720px
      190: "47.5rem", //  760px
      200: "50rem", // 800px
      300: "75rem", // 1200px
      312: "78rem", // 1248px
      320: "80rem", // 1280px
      350: "87.5rem", // 1400px
    },
    borderRadius: {
      "4xl": "2rem",
    },
    borderColor: {
      gray: {
        500: "#868686",
      },
    },
    backgroundImage: {
      "custom-radial-purple":
        "radial-gradient(41.9% 50% at 50% 50%, rgba(165, 124, 251, 0.3) 0%, rgba(165, 124, 251, 0) 100%)",
      "custom-radial-yellow":
        "radial-gradient(41.9% 50% at 50% 50%, rgba(244, 209, 72, 0.25) 0%, rgba(244, 209, 72, 0) 100%)",
      "custom-gradient": "linear-gradient(90deg, #A57CFB 0%, #F4D148 100%)",
      "free-trial": "url(/img/free-trial-bg.svg)",
    },
    backgroundPosition: {
      "top-50": "center top 3.125rem",
    },
    boxShadow: {
      custom: "0 8px 24px rgba(69, 69, 80, 0.1)",
      "drop-shadow-main": "0px 4px 4px 0px #00000040",
      deep: "rgba(0, 0, 0, 0.15) 0px 4px 40px, rgba(0, 0, 0, 0.184) 0px 3px 20px, rgba(0, 0, 0, 0.184) 0px 3px 12px, rgba(0, 0, 0, 0.184) 0px 2px 8px, rgba(0, 0, 0, 0.184) 0px 1px 1px",
    },
    keyframes: {
      spin: {
        "0%": { transform: "rotate(0deg)" },
        "100%": { transform: "rotate(360deg)" },
      },
      fadeIn: {
        "0%": { opacity: "0" },
        "100%": { opacity: "1" },
      },
      shimmer: {
        "0%": { "background-position-y": "0" },
        "100%": { "background-position-y": "450px" },
      },
    },
    animation: {
      spin: "spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite",
      fadeIn: "fadeIn 0.3s ease-out",
      shimmer: "shimmer 5s linear infinite",
    },
    lineHeight: {
      20: "5.375rem",
    },
    zIndex: {
      60: "60",
      70: "70",
      80: "80",
      90: "90",
      100: "100",
    },
  },
  fontSize: {
    xs: "0.6rem",
    sm: "0.8rem",
    md: "0.9rem",
    base: "1rem",
    xl: "1.25rem",
    "2xl": "1.563rem",
    "3xl": "1.953rem",
    "4xl": "2.441rem",
    "5xl": "3.052rem",
    "6xl": "3.25rem",
    "7xl": "3.625rem",
    "10xl": "5.375rem",
  },
}
