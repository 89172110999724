import { useMemo } from "react"
import Breadcrumbs from "~/ui-rtk/components/layout/Breadcrumbs"
import { H1 } from "~/ui-rtk/components/ui/typography"
import { HOMEPAGE_SECTIONS } from "~/ui-rtk/pages/Home/constants"
import SetupGuideSection from "./components/SetupGuide/SetupGuideSection"
import LearningZoneSection from "./components/LearningZone/LearningZoneSection"
import Top10HeroAdsSection from "./components/RecommendedActions/Top10HeroAds"
import Top10BrandAdsSection from "./components/RecommendedActions/Top10BrandAds"
import BrandValueIncreaseAdsetBudgetSection from "./components/RecommendedActions/BrandValueIncreaseAdSetBudget"

export interface THomeDashboardProps {
  title: string
  slug: string
  dateRangeRestrictions?: Partial<{ minDate: Date; maxDate: Date }>
  items: Record<string, Record<string, any>>
}

export default function HomeDashboard({
  title,
  slug,
  items,
}: THomeDashboardProps) {
  const canSeeSetupGuideSection = useMemo(
    () => items[HOMEPAGE_SECTIONS.SETUP_GUIDE].isEnabled,
    [items],
  )
  const canSeeLearningZoneSection = useMemo(
    () => items[HOMEPAGE_SECTIONS.LEARNING_ZONE].isEnabled,
    [items],
  )
  const canSeeRecommendedActionsSection = useMemo(
    () => items[HOMEPAGE_SECTIONS.RECOMMENDED_ACTIONS].isEnabled,
    [items],
  )
  return (
    <div className="home-dashboard">
      <div className="text-start">
        <H1 className="flex">
          <Breadcrumbs
            items={[
              {
                name: "Welcome",
              },
              {
                slug,
                name: title,
              },
            ]}
          />
        </H1>
      </div>
      <div className="mt-8">
        {canSeeSetupGuideSection ? (
          <SetupGuideSection className="mt-4" />
        ) : null}
      </div>
      <div className="mt-8">
        {canSeeRecommendedActionsSection ? (
          <Top10HeroAdsSection className="mt-4" />
        ) : null}
      </div>
      <div className="mt-8">
        {canSeeRecommendedActionsSection ? (
          <Top10BrandAdsSection className="mt-4" />
        ) : null}
      </div>
      <div className="mt-8">
        {canSeeRecommendedActionsSection ? (
          <BrandValueIncreaseAdsetBudgetSection className="mt-4" />
        ) : null}
      </div>
      <div className="mt-8">
        {canSeeLearningZoneSection ? (
          <LearningZoneSection className="mt-4" />
        ) : null}
      </div>
    </div>
  )
}
