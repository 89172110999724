import { H2, H3 } from "~/ui-rtk/components/ui/typography"
import {
  ElementType,
  createElement,
  createRef,
  useCallback,
  useMemo,
} from "react"
import { type DashboardVisualizationDto } from "~/ui-rtk/api/defaultApi"
import useSources from "~/ui-rtk/hooks/sources"
import {
  AreaChart,
  CohortVisualization,
  ComposedChart,
  DropDownChart,
  TableVisualization,
} from "../../../ui/charts"
import SimpleTable from "~/ui-rtk/components/ui/charts/SimpleTable/SimpleTable"
import SortedItems from "~/ui-rtk/components/ui/charts/SortedItems/SortedItems"
import MultiChart from "../../../ui/charts/MultiChart"
import ChartLoader from "../../../ui/charts/ChartLoader"
import {
  AGG_TYPES,
  AggregationType,
  VISUALIZATION_TYPE,
} from "~/ui-rtk/constants/charts"
import {
  TDashboardItem,
  TDashboardItemWidget,
  TDashboardItemsSet,
  WIDGET_TYPE,
} from "~/ui-rtk/constants/dashboard"
import Breadcrumbs from "~/ui-rtk/components/layout/Breadcrumbs"
import { ExclamationSvg } from "~/ui-rtk/components/ui/svg/essentials"
import { Button, PeriodPicker } from "~/ui-rtk/components/ui/controls"
import { flatContentWidgets } from "~/ui-rtk/utils/content"
import { useMinMaxDateRange } from "~/ui-rtk/hooks/date-range"
import { ComposedTable } from "../../../ui/charts/ComposedTable/ComposedTable"
import Card from "../../../ui/common/Card"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import { DATE_FORMAT, usePeriod } from "~/ui-rtk/hooks/period"
import useDate from "~/ui-rtk/hooks/date"
import {
  type TLastUpdateDateProps,
  useLastUpdateDate,
} from "~/ui-rtk/hooks/cube"
import { LastUpdatedChip } from "~/ui-rtk/components/ui/common/LastUpdatedChip/LastUpdatedChip"
import { useDateRanges } from "~/ui-rtk/utils/date-utils"
import AmazonToggle from "~/ui-rtk/components/ui/controls/AmazonToggle/AmazonToggle"
const dayjs = useDate()

export interface TDashboardProps {
  title: string
  slug: string
  periodPicker?: { visible: boolean }
  items: TDashboardItemsSet
  excludeDataKeyFromTotal?: Array<string>
  parent: {
    slug: string
    title: string
  }
  visualizations: {
    [k: string]: DashboardVisualizationDto
  }
  dateRangeRestrictions?: Partial<{ minDate: Date; maxDate: Date }>
  cubeQueryParams?: Record<string, string | string[]>
  lastDateChipProps?: TLastUpdateDateProps
  props?: Record<string, any>
  toggleAmazon?: () => void
  amazonEnabled?: boolean
}

const VISUALIZATIONS: Record<VISUALIZATION_TYPE, ElementType> = {
  TableVisualization,
  SimpleTable,
  AreaChart,
  ComposedChart,
  DropDownChart,
  CohortVisualization,
  MultiChart,
  ComposedTable,
  SortedItems,
}

const WIDGETS: Record<WIDGET_TYPE, ElementType> = {
  H3,
  SPACER: () => null,
}

const colsClassNameMap: Record<number, string> = {
  1: "grid-cols-1",
  2: "grid-cols-2",
  3: "grid-cols-3",
  4: "grid-cols-4",
}

const alignMap: Record<string, string> = {
  end: "items-end",
  center: "items-center",
  start: "items-start",
}

const justifyMap: Record<string, string> = {
  end: "justify-end",
  center: "justify-center",
  start: "justify-start",
}

export default function Dashboard({
  title,
  items,
  periodPicker,
  slug,
  parent,
  visualizations,
  excludeDataKeyFromTotal,
  dateRangeRestrictions,
  cubeQueryParams = {},
  lastDateChipProps,
  toggleAmazon,
  amazonEnabled,
  props: contentProps = {},
}: TDashboardProps) {
  const { sources } = useSources()
  const { minAggType, maxAggType } = useDateRanges()
  const isPeriodPickerVisible = periodPicker?.visible ?? false
  let lastDate: string | undefined
  let isLastUpdateDateLoading = false
  if (lastDateChipProps) {
    const { dateMetric, filterMetrics } = lastDateChipProps
    const { lastDate: lastDateRaw, isLoading: isLastUpdateDateLoadingRaw } =
      useLastUpdateDate({
        dateMetric,
        filterMetrics,
      })
    lastDate = lastDateRaw
    isLastUpdateDateLoading = isLastUpdateDateLoadingRaw
  }
  const { dateRange, compareRange } = useMinMaxDateRange(
    dateRangeRestrictions?.minDate,
    dateRangeRestrictions?.maxDate ?? new Date(lastDate ?? ""),
  )
  const { granularity, setPeriod, setComparePeriod, setGranularity } =
    usePeriod()

  const { commonGranularityPicker } = contentProps

  const plainItems: TDashboardItemWidget[] = flatContentWidgets(items)
  const visibleWidgets: (VISUALIZATION_TYPE | WIDGET_TYPE)[] = useMemo(
    () =>
      plainItems
        .filter(
          ({ widget }) =>
            !visualizations[widget]?.requiredSources?.length ||
            visualizations[widget]?.requiredSources?.some(source =>
              sources?.has(source),
            ),
        )
        .map(({ widget }) => widget),
    [items],
  )
  const refs = useMemo(
    () => Object.fromEntries(visibleWidgets.map(id => [id, createRef<any>()])),
    [visibleWidgets],
  )
  const showComparisonInPicker = plainItems.some(
    ({ widget }) => visualizations[widget]?.props?.comparisonEnabled,
  )
  const dataAggTypeOnly = null

  const aggregationGroups = useMemo(
    () =>
      AGG_TYPES.filter(({ type }, index) => {
        // if data agg type is set in config, hide all other options
        if (dataAggTypeOnly) {
          return dataAggTypeOnly === type
        }
        // if min agg type is weekly, hide daily
        if (minAggType === AggregationType.WEEKLY && index === 0) {
          return false
        }
        // if max agg type is daily, hide weekly and monthly
        if (maxAggType === AggregationType.DAILY && index > 0) {
          return false
        }
        // if max agg type is weekly, hide monthly
        if (maxAggType === AggregationType.WEEKLY && index === 2) {
          return false
        }
        return true
      }),
    [minAggType, maxAggType, dataAggTypeOnly],
  )

  const handleChangePeriods = (
    dateRange: [Date, Date],
    compareDateRange: [Date, Date] | null,
  ) => {
    setPeriod(
      dayjs(dateRange[0]).format(DATE_FORMAT),
      dayjs(dateRange[1]).format(DATE_FORMAT),
    )

    if (compareDateRange && compareDateRange.length > 0) {
      setComparePeriod(
        dayjs(compareDateRange[0]).format(DATE_FORMAT),
        dayjs(compareDateRange[1]).format(DATE_FORMAT),
      )
    } else {
      setComparePeriod()
    }
  }

  const isSourceConnected = (widget: string) =>
    !visualizations[widget] ||
    (visualizations[widget]?.requiredSources?.length ?? 0) === 0 ||
    visualizations[widget]?.requiredSources?.some(source => sources.has(source))

  const handleSetDataAggType = (aggType: AggregationType) => {
    setGranularity(aggType)
  }

  const renderChartLoader = useCallback(
    (item: TDashboardItemWidget, itemsSet: TDashboardItem) => {
      const widgetType = visualizations[item.widget].type as VISUALIZATION_TYPE
      const tableTypes = [
        VISUALIZATION_TYPE.TableVisualization,
        VISUALIZATION_TYPE.ComposedTable,
        VISUALIZATION_TYPE.SimpleTable,
      ]

      const chartLoader = (
        <ChartLoader
          chartId={item.widget}
          className={cn("border-0 rounded-lg", itemsSet.className)}
          widgetType={widgetType}
          component={VISUALIZATIONS[widgetType]}
          dateRange={dateRange}
          compareRange={compareRange ?? undefined}
          excludeDataKeyFromTotal={excludeDataKeyFromTotal}
          key={item.widget}
          granularity={granularity}
          sources={sources}
          hideAggSelector={commonGranularityPicker}
          requiredSources={visualizations[item.widget]?.requiredSources}
          setDataAggType={handleSetDataAggType}
          cubeQueryParams={{
            [item.widget]: {
              ...cubeQueryParams,
              granularity,
            },
          }}
          description={visualizations[item.widget]?.description}
          compareMode={
            visualizations[item.widget].props.comparisonEnabled &&
            (compareRange?.length ?? 0) > 0
          }
          // TEMP SOLUTION
          {...visualizations[item.widget].props}
        />
      )

      if (tableTypes.includes(widgetType)) {
        return chartLoader
      }

      return (
        <Card rounded className="bg-basic-dark-blue">
          {chartLoader}
        </Card>
      )
    },
    [
      visualizations,
      cubeQueryParams,
      amazonEnabled,
      sources,
      granularity,
      dateRange,
      compareRange,
      excludeDataKeyFromTotal,
    ],
  )

  const info = useMemo(() => {
    const renderInfoWidget = visibleWidgets.find(
      (widget: string) => !!visualizations[widget]?.renderInfo,
    )
    if (renderInfoWidget) {
      const renderInfo = visualizations[renderInfoWidget]?.renderInfo
      return (renderInfo && renderInfo()) ?? undefined
    }
  }, [visibleWidgets, visualizations])

  return (
    <div>
      <div>
        <div className="text-start">
          <H2>
            <Breadcrumbs
              items={[
                {
                  slug: parent.slug,
                  name: parent.title,
                },
                {
                  slug,
                  name: title,
                },
              ]}
            />
          </H2>
        </div>
        <div
          className={cn(
            "flex sticky top-19 z-50 my-1 px-1 gap-2 align-center",
            info ? "justify-between" : "justify-end",
          )}
        >
          {info && <div className="2xl:col-span-2">{info}</div>}
          <div
            className={`flex gap-2 ${info ? "flex-col justify-center items-end" : "items-center"}`}
          >
            {lastDate ? (
              <div className="flex align-center">
                <LastUpdatedChip
                  lastDate={lastDate}
                  isLoading={isLastUpdateDateLoading}
                >
                  Max date available in Page:
                </LastUpdatedChip>
              </div>
            ) : null}
            {commonGranularityPicker ? (
              <div className="join">
                {aggregationGroups.length > 1
                  ? aggregationGroups.map(({ type, long }) => (
                      <Button
                        variant={{ variant: "solid", color: "blue" }}
                        key={type}
                        title={long}
                        className={`join-item border text-3.5 px-4 py-3 rounded-md ${type === granularity ? "bg-basic-blue" : "bg-basic-dark-blue"}`}
                        onClick={() => handleSetDataAggType(type)}
                      >
                        {long}
                      </Button>
                    ))
                  : null}
              </div>
            ) : null}
            <PeriodPicker
              dateRange={dateRange}
              compareRange={compareRange}
              opacity={isPeriodPickerVisible ? 1 : 0.5}
              onChange={handleChangePeriods}
              restrictions={dateRangeRestrictions}
              comparisonEnabled={showComparisonInPicker}
            />
          </div>
        </div>
      </div>
      {toggleAmazon && (
        <AmazonToggle
          amazonEnabled={amazonEnabled}
          toggleAmazon={toggleAmazon}
        />
      )}
      <div className="grid-stack">
        {items.length > 0 ? (
          items.map((itemsSet: TDashboardItem, idx: number) => {
            const filteredWidgets = itemsSet.items.filter(
              (item: TDashboardItemWidget) =>
                visibleWidgets.includes(item.widget),
            )

            return filteredWidgets.length > 0 ? (
              <div
                className={`grid grid-stack-item ${colsClassNameMap[itemsSet.grid]} gap-4 my-4`}
                key={`grid-stack-item-${idx}`}
              >
                {filteredWidgets.map((item, idy) => (
                  <div
                    ref={refs[item.widget]}
                    key={`${item.widget}-${idx}-${idy}`}
                    className={`grid-stack-item-content ${["SPACER", "H3"].includes(item.widget) ? "overflow-hidden" : ""}`}
                  >
                    <div className="relative">
                      {!isSourceConnected(item.widget) ? (
                        <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center gap-1 align-center z-1">
                          <ExclamationSvg size={20} />
                        </div>
                      ) : null}
                      {visualizations[item.widget] &&
                      VISUALIZATIONS[
                        visualizations[item.widget].type as VISUALIZATION_TYPE
                      ]
                        ? renderChartLoader(item, itemsSet)
                        : null}
                      {WIDGETS[item.widget as WIDGET_TYPE] ? (
                        <div
                          className={`w-100 h-full flex ${item.verticalAlign && alignMap[item.verticalAlign]} ${item.horizontalAlign && justifyMap[item.horizontalAlign]}`}
                        >
                          {createElement(WIDGETS[item.widget as WIDGET_TYPE], {
                            children: item.text,
                          })}
                        </div>
                      ) : null}
                      {!visualizations[item.widget] &&
                      !WIDGETS[item.widget as WIDGET_TYPE] ? (
                        <div className="flex items-center justify-center h-full bg-background-dark w-100">
                          <ExclamationSvg size={32} fill="error" />
                          <span className="font-bold text-error">
                            Invalid visualization "{item.widget}"
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            ) : null
          })
        ) : (
          <div>This page is under construction. Please come again later</div>
        )}
      </div>
    </div>
  )
}
