import * as yup from "yup"
import { useCallback } from "react"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { toast } from "react-toastify"
import { DataSource as PrismaDataSource } from "@db/maindb/prisma/client"
import {
  useAuthControllerLogoutMutation,
  useAuthControllerCurrentUserQuery,
  useAuthControllerUpdateProfileMutation,
} from "~/ui-rtk/api/authApi"
import { useLazySubscriptionControllerCreateCustomerPortalSessionQuery as useCreateCustomerPortalSession } from "~/ui-rtk/api/subscriptionApi"
import { ACL } from "~/ui-rtk/utils/ACL"

export type UpdateProfileFormValues = {
  name: string
  email: string
  dataSource?: PrismaDataSource
}

export enum DataSourceEnum {
  SNAPSHOT = "SNAPSHOT",
  SNAPSHOT_ECS = "SNAPSHOT_ECS",
  LATEST = "LATEST",
  SNAPSHOT_ISOLATED = "SNAPSHOT_ISOLATED",
}

export const validationSchema = yup.object().shape({
  email: yup.string().email().required("Email is required!"),
  name: yup.string().required("Name is required!"),
  dataSource: yup
    .mixed<PrismaDataSource>()
    .oneOf(Object.values(DataSourceEnum))
    .optional(),
})

export default function useConnect() {
  const { data: user } = useAuthControllerCurrentUserQuery()

  const [updateProfile] = useAuthControllerUpdateProfileMutation()
  const [logout] = useAuthControllerLogoutMutation()
  const navigate = useStableNavigate()

  const isSubscriptionValid = user?.currentCompany?.subscription
    ? ACL.isSubscriptionValid(user?.currentCompany?.subscription)
    : false

  const [createPortalSession, { isLoading: isPortalSessionLoading }] =
    useCreateCustomerPortalSession()

  const allowDataSource = !!user?.user.isSuperAdmin

  const initialValues = {
    email: user?.user?.email ?? "",
    name: user?.user?.name ?? "",
    dataSource: user?.user?.dataSource ?? "SNAPSHOT",
  }

  const handleCreateCustomerPortal = async () => {
    try {
      const portalUrl = await createPortalSession().unwrap()
      window.location.href = portalUrl
    } catch (error) {
      toast.error("Failed to create customer portal")
    }
  }

  const handleUpdateProfile = useCallback(
    async (values: UpdateProfileFormValues) => {
      try {
        await updateProfile({
          updateProfileDto: {
            name: values.name,
            email: values.email.toLowerCase(),
            ...(allowDataSource && values.dataSource
              ? { dataSource: values.dataSource }
              : {}),
          },
        }).unwrap()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        toast.success("Settings saved successfully")
      } catch (error: any) {
        console.error(error)
        if (error.status === 401) {
          await logout()
          navigate("/")
          return
        }
        toast.error("Could not update profile")
      }
    },
    [],
  )

  return {
    isPortalSessionLoading,
    isSubscriptionValid,
    createPortalSession,
    initialValues,
    validationSchema,
    createCustomerPortal: handleCreateCustomerPortal,
    updateProfile: handleUpdateProfile,
    showDataSourceInfo: allowDataSource,
  }
}
