import { useMemo } from "react"
import {
  CompanyConnectorDto,
  ConnectionStatus,
  ConnectorDto,
} from "~/ui-rtk/api/types"
import { useDeepCompareMemo } from "~/ui-rtk/hooks/deep-compare-memo"
import { isConnectorExcluded } from "~/ui-rtk/utils/connector-utils"
import { checkForMissingKeywords } from "./utils"
import useCurrentCompany from "~/ui-rtk/hooks/current-company"
import { useConnect } from "~/ui-rtk/components/features/connectors/ConnectorsTable/connect"

export interface TConnectorSetupDetails {
  totalConnectors: number
  totalConnected: number
  hasMissingKeywords: boolean
  requiresReconnect: CompanyConnectorDto[]
  essentialUnconnectedConnectors: ConnectorDto[]
}

export const useSetupDetails = (
  connectors: ConnectorDto[] | undefined,
  companyConnectors: CompanyConnectorDto[] | undefined,
): TConnectorSetupDetails => {
  const currentCompany = useCurrentCompany()
  const { totalVisibleConnectorsAmount, totalConnectedConnectorsAmount } =
    useConnect()

  const memoizedConnectors = useDeepCompareMemo(connectors)
  const memoizedCompanyConnectors = useDeepCompareMemo(companyConnectors)

  const connectorsSetupDetails = useMemo<
    Omit<TConnectorSetupDetails, "hasMissingKeywords">
  >(() => {
    if (!memoizedConnectors || !memoizedCompanyConnectors) {
      return {
        totalConnectors: 0,
        totalConnected: 0,
        requiresReconnect: [],
        essentialUnconnectedConnectors: [],
      }
    }

    const requiresReconnect: CompanyConnectorDto[] = []

    memoizedCompanyConnectors.forEach(service => {
      if (
        !isConnectorExcluded(service.serviceName) &&
        service.status === ConnectionStatus.PENDING_RECONNECT
      ) {
        requiresReconnect.push(service)
      }
    })

    const essentialUnconnectedConnectors = memoizedConnectors.filter(c => {
      const isEssential = c.category.includes("Essential")
      if (!isEssential) {
        return false
      }

      const isAlreadyConnected = memoizedCompanyConnectors.find(
        service => service.serviceName === c.id,
      )
      return !isAlreadyConnected
    })

    return {
      essentialUnconnectedConnectors,
      requiresReconnect,
      totalConnected: totalConnectedConnectorsAmount,
      totalConnectors: totalVisibleConnectorsAmount,
    }
  }, [
    memoizedConnectors,
    memoizedCompanyConnectors,
    totalConnectedConnectorsAmount,
    totalVisibleConnectorsAmount,
  ])

  const hasMissingKeywords = useMemo(() => {
    const keywords = currentCompany?.metadata?.keywords
    if (!keywords) {
      return true
    }

    return checkForMissingKeywords(keywords)
  }, [currentCompany])

  return {
    ...connectorsSetupDetails,
    hasMissingKeywords,
  }
}
