import React, { ReactNode, useMemo } from "react"
import ReactDatePicker, { type ReactDatePickerProps } from "react-datepicker"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

import { Input } from "~/ui-rtk/components/ui/controls"
import { Popover } from "~/ui-rtk/components/ui/common"

import { MONTH_AGO_RESTRICTION } from "~/ui-rtk/constants/restrictions"

import { getMonthLabel } from "./utils"
import { isDateMoreRecentThanMonthsAgo } from "~/ui-rtk/utils/date-utils"

import "react-datepicker/dist/react-datepicker.css"
import "./index.scss"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import PeriodPickerDropDownIcons from "../../common/PeriodPickerDropDownIcons/PeriodPickerDropDownIcons"

type TMonthPickerClasses = {
  input: string
}

type TMonthPickerProps = {
  startOfMonth: Date
  classes?: Partial<TMonthPickerClasses>
  onChange: (date: Date) => void
  isPartialPeriod?: boolean
  icon?: ReactNode
} & Omit<ReactDatePickerProps, "onChange">

const MonthPicker: React.FC<TMonthPickerProps> = ({
  startOfMonth,
  minDate,
  onChange,
  classes,
  icon: iconFromProps,
  isPartialPeriod = false,
  ...props
}) => {
  const $minDate = useMemo(() => {
    if (
      !minDate ||
      isDateMoreRecentThanMonthsAgo(minDate, MONTH_AGO_RESTRICTION)
    ) {
      return dayjs().subtract(MONTH_AGO_RESTRICTION, "month").toDate()
    }
    return minDate
  }, [minDate])

  const handlePickDate = (callback: () => void) => (date: Date) => {
    void onChange(date)
    void callback()
  }

  const icon = iconFromProps ?? (
    <PeriodPickerDropDownIcons isPartialPeriod={isPartialPeriod} />
  )

  return (
    <Popover
      relative
      handler={() => (
        <Input
          readOnly
          value={getMonthLabel(startOfMonth)}
          appendNode={icon}
          classes={{
            input: classes?.input,
          }}
          className={cn("min-w-72 max-h-12.5 rounded-md ", classes?.input)}
        />
      )}
    >
      {closePopup => (
        <ReactDatePicker
          calendarClassName="ui-rtk-week-picker mt-2"
          selected={startOfMonth}
          showMonthYearPicker
          inline
          minDate={$minDate}
          onChange={handlePickDate(closePopup)}
          calendarStartDay={1}
          {...props}
        />
      )}
    </Popover>
  )
}

export default MonthPicker
