import { ChangeEvent, useCallback, useRef } from "react"

import { Divider, Loader } from "~/ui-rtk/components/ui/common"
import { H1 } from "~/ui-rtk/components/ui/typography"
import { Note } from "./components"

import { TRevenueSourceFormValues, useConnect } from "./connect"
import { Button, Checkbox } from "~/ui-rtk/components/ui/controls"
import { Form, Formik, FormikProps } from "formik"
import RadioToggle from "~/ui-rtk/components/ui/controls/RadioToggle/RadioToggle"
import useAccess from "~/ui-rtk/hooks/access"

const RevenueSourcePage = () => {
  const {
    sources,
    initialValues,
    isLoading,
    handleSaveSelectedSource,
    isSubmitting,
  } = useConnect()
  const { isSuperAdmin } = useAccess()
  const formRef = useRef<FormikProps<TRevenueSourceFormValues> | null>(null)

  const handleSubmit = () => {
    if (formRef.current?.isValid) {
      handleSaveSelectedSource(formRef.current?.values).catch(() => null)
    }
  }

  const renderWithLoader = useCallback(() => {
    if (isLoading || isSubmitting) {
      return (
        <div className="w-full h-50 flex justify-center align-center">
          <Loader size={150} />
        </div>
      )
    }

    return (
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize
          innerRef={formRef}
        >
          {({ values, setFieldValue }) => (
            <Form className="py-6">
              <div className="space-y-4">
                <div className="bg-basic-dark-blue px-4 py-6.5 rounded-lg w-full space-y-6.5">
                  <h3 className="text-2xl text-white font-semibold">
                    Direct-to-consumer 1st party Ecommerce
                  </h3>
                  <Divider className="w-full bg-basic-blue" />
                  <section className="flex flex-col gap-4">
                    <RadioToggle
                      options={sources}
                      disabled={!isSuperAdmin}
                      name="depVarSource"
                      value={values.depVarSource}
                      labelClassName="flex"
                      onChange={(value: string) =>
                        setFieldValue("depVarSource", value)
                      }
                    />
                  </section>
                  {isSuperAdmin && (
                    <section className="flex flex-col gap-4 form-control">
                      <label className="label cursor-pointer">
                        <span className="label-text">Alternative version</span>
                        <Checkbox
                          className="checkbox w-10 h-10 [--chkbg:theme(colors.american-green)] [--chkfg:white]"
                          name="depVarSourceAlt"
                          checked={!!values.depVarSourceAlt}
                          onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("depVarSourceAlt", ev.target.checked)
                          }
                        />
                      </label>
                    </section>
                  )}
                </div>
                <Note text="Note: only one source can currently be selected for DTC 1st party e-commerce" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  }, [isLoading, isSubmitting, initialValues, handleSubmit])

  return (
    <div className="md:px-6 md:pt-6 space-y-9">
      <H1>Revenue Source</H1>
      <div className="text-start grid grid-cols-2 align-center">
        <h3 className="font-semibold text-2xl text-white leading-8 mb-4">
          Select the revenue source you'd like to use to power your model
        </h3>
        <div className="flex aling-center justify-end gap-4">
          <Button
            variant={{ variant: "solid", color: "pink" }}
            size="sm"
            disabled={!isSuperAdmin}
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            Update
          </Button>
        </div>
      </div>
      {renderWithLoader()}
    </div>
  )
}

export default RevenueSourcePage
